import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { DateObject } from "react-multi-date-picker";
import { HotelDetails, HotelDetailsForm, StandardData, StandardDetails, Tailored } from "./types";
import React from "react";


// Customizable Area Start
export interface Tabs {
    id: number,
    tabName: string,
    tabkey: string
}
export interface Questions {
    conditions: string
    question1: string;
    question2: string
    question3: string
}

interface TailoredErrors {
    freeofcharge?: string,
    reducetheAmount?: string,
    prepayment?: string,
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    id: string;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    policyDetails: any
    standardDetails: StandardData[],
    previousSelectedPolicy: string,
    policyQuestions: Questions,
    cancellationPolicyEditStatus: boolean,
    cancellationPolicydropDownStatus: boolean,
    percentageDropDownStatus: boolean,
    daysCount: any[],
    tailoredData: Tailored,
    daysDropDownStatus: boolean,
    refundDaysDropDownStatus: boolean,
    prePaymentDropDownStatus: boolean,
    cancellationLoader: boolean,
    priceError: boolean;
    tailoredDataErrors: TailoredErrors


    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class CancellationPolicyDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiCallIdgetCancellationPolicyDetails: string = "";
    apiCallIdStandardDetails: string = "";
    apiCallIdUpdateCancellationPolicy: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            policyDetails: {},
            standardDetails: [],
            previousSelectedPolicy: "",
            policyQuestions: {
                conditions: "",
                question1: "",
                question2: "",
                question3: ""
            },
            cancellationPolicyEditStatus: false,
            cancellationPolicydropDownStatus: true,
            percentageDropDownStatus: false,
            daysCount: [],
            tailoredData: {
                freeofcharge: "",
                reducetheAmount: "",
                prepayment: "",
            },
            daysDropDownStatus: false,
            refundDaysDropDownStatus: false,
            prePaymentDropDownStatus: false,
            cancellationLoader: false,
            priceError: true,
            tailoredDataErrors: {
                freeofcharge: "",
                reducetheAmount: "",
                prepayment: "",
            }

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start
    async componentDidMount() {
        this.sandardPolicyDetails()
        this.getCancellationPolicyDetails()
        for (let i = 1; i <= 31; i++) {
            this.state.daysCount.push(i);
        }
    }

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.apiCallIdgetCancellationPolicyDetails) {
            if (responseJson && !responseJson.errors) {

                this.cancellationDetailsApiResponce(responseJson)


            }
        }
        if (apiRequestCallId === this.apiCallIdStandardDetails) {
            if (responseJson && !responseJson.errors) {
                this.setState({ standardDetails: responseJson.data, cancellationLoader: false })

            }
        }
        if (apiRequestCallId === this.apiCallIdUpdateCancellationPolicy) {
            if (responseJson && !responseJson.errors) {
                this.cancellationDetailsApiResponce(responseJson)
                this.setState({ cancellationPolicyEditStatus: false })
            }

        }
        // Customizable Area End
    }

    // Customizable Area Start

    cancellationDetailsApiResponce = (responseJson: any) => {
        let policyQuestions = {
            conditions: responseJson?.data?.attributes?.additional_condition || "",
            question1: responseJson?.data?.attributes?.additional_question_one || "",
            question2: responseJson?.data?.attributes?.additional_question_two || "",
            question3: responseJson?.data?.attributes?.additional_question_three || ""
        }
        let tailored = {
            freeofcharge: responseJson?.data?.attributes?.free_cancellation_days || "",
            reducetheAmount: responseJson?.data?.attributes?.free_refund_days || "",
            option: responseJson?.data?.attributes?.charge_option || "deposit",
            confirmAmount: responseJson?.data?.attributes?.confirm_deposit_amount || "",
            prepayment: responseJson?.data?.attributes?.pre_payment_percent ? `${responseJson?.data?.attributes?.pre_payment_percent}%` : "",
            prePaymentHold: responseJson?.data?.attributes?.cancellation_hold_percent ? `${responseJson?.data?.attributes?.cancellation_hold_percent}%` : ""
        }
        this.setState({
            policyDetails: responseJson?.data,
            policyQuestions: policyQuestions,
            previousSelectedPolicy: responseJson?.data?.attributes?.cancellation_type,
            tailoredData: tailored,
            cancellationLoader: false
        })
    }
    handleCheckBoxChange = (type: string) => {
        this.setState({ previousSelectedPolicy: type })
    }
    handleEditPolicy = () => {
        this.setState({ cancellationPolicyEditStatus: true })
    }
    handleDropDown = () => {
        this.setState({ cancellationPolicydropDownStatus: !this.state.cancellationPolicydropDownStatus })
    }

    handleQuestionsEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.state.cancellationPolicyEditStatus) {
            this.setState({ policyQuestions: { ...this.state.policyQuestions, [event.target.name]: event.target.value } })
        }

    }

    handleOpenPercentageDropDown = (select: string) => {
        if (this.state.cancellationPolicyEditStatus) {
            if (select === 'prePayment') {
                this.setState({ percentageDropDownStatus: true })
            } else if (select === 'percentageHold') {
                this.setState({ prePaymentDropDownStatus: true })
            }
        }
    }

    handleClosePercentageDropDown = (select: string) => {
        if (select === 'prePayment') {
            this.setState({ percentageDropDownStatus: false })
        } else if (select === 'percentageHold') {
            this.setState({ prePaymentDropDownStatus: false })
        }
    }

    handleOpenDaysDropDown = (selectedType: string) => {
        if (selectedType == 'days') {
            this.setState({ daysDropDownStatus: true })

        } else if (selectedType == 'refundDays') {
            this.setState({ refundDaysDropDownStatus: true })
        }

    }
    handleCloseDaysDropDown = (selectedType: string) => {
        if (selectedType == 'days') {
            this.setState({ daysDropDownStatus: false })

        } else if (selectedType == 'refundDays') {
            this.setState({ refundDaysDropDownStatus: false })
        }
    }

    selectDays = (days: string, key: string) => {
        if (key === 'days') {
            this.setState({
                tailoredData: { ...this.state.tailoredData, freeofcharge: days, },
                tailoredDataErrors: { ...this.state.tailoredDataErrors, freeofcharge: "", }

            }, () => {
                this.handleCloseDaysDropDown(key)
            })
        } else if (key === 'refundDays') {
            this.setState({
                tailoredData: { ...this.state.tailoredData, reducetheAmount: days },
                tailoredDataErrors: { ...this.state.tailoredDataErrors, reducetheAmount: "", }
            }, () => {
                this.handleCloseDaysDropDown(key)

            })

        }
    }

    selectPecentage = (percentage: string, key: string) => {
        if (key === 'prePayment') {
            this.setState({ tailoredData: { ...this.state.tailoredData, prepayment: percentage } }, () => {
                this.handleClosePercentageDropDown(key)
            })
        }
    }

    getCancellationPolicyDetails = async () => {
        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };
        this.setState({ cancellationLoader: true })
        const hotelID = await getStorageData('BoHotelId')

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCancelationPolicyEndPoint}${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdgetCancellationPolicyDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    sandardPolicyDetails = async () => {
        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };

        this.setState({ cancellationLoader: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.standardPolicyEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdStandardDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    validateCancellationPolicy = () => {
        let errors = { ...this.state.tailoredDataErrors }
        if(this.state.previousSelectedPolicy === "standard"){
            this.updateCancelatationPolicyDetails();
        } else {
            this.validateFreeOffCharge(this.state.tailoredData.freeofcharge, errors)
            this.ValidatePrePyamnetPercent(this.state.tailoredData.prepayment, errors)
        

        this.setState({ tailoredDataErrors: errors }, () => {
            const hasErrors = Object.values(errors).some((error) => error);
            if (!hasErrors) {
                this.updateCancelatationPolicyDetails();
            }
        })
        }
    };
    validateFreeOffCharge = (value: string, errors: TailoredErrors) => {
        if (!value) {
            errors['freeofcharge'] = "Please select the days pet owner cancel their reservation free of charge"
        } else {
            delete errors['freeofcharge']
        }

    }
    validateReduceOfAmount = (value: string, errors: TailoredErrors) => {
        if (!value) {
            errors['reducetheAmount'] = "Please select the days pet owner amend (extend the amount of days or reduce the amount of days) their reservation free of charge."
        } else {
            delete errors['reducetheAmount']
        }
    }
    ValidatePrePyamnetPercent = (value: string, errors: TailoredErrors) => {
        if (!value) {
            errors['prepayment'] = "Please select the percentage for the pre payment amount"
        } else {
            delete errors['prepayment']
        }

    }
    updateCancelatationPolicyDetails = async () => {
        let tokenValue = await getStorageData("authToken");
        const headers = {
            token: tokenValue,
        };
        const updatebody = new FormData()

        updatebody.append('cancellation_policy[cancellation_type]', this.state.previousSelectedPolicy)
        if (this.state.previousSelectedPolicy == 'standard') {
            updatebody.append('cancellation_policy[free_cancellation_days]', "")
            updatebody.append('cancellation_policy[free_refund_days]', "")
            updatebody.append('cancellation_policy[pre_payment_percent]', "")
        } else {
            updatebody.append('cancellation_policy[free_cancellation_days]', this.state.tailoredData.freeofcharge)
            updatebody.append('cancellation_policy[free_refund_days]', this.state.tailoredData.reducetheAmount)
            updatebody.append('cancellation_policy[pre_payment_percent]', this.state.tailoredData.prepayment)

        }
        updatebody.append('cancellation_policy_id', this.state.policyDetails?.attributes?.id)
        updatebody.append('cancellation_policy[additional_condition]', this.state.policyQuestions.conditions)
        updatebody.append('cancellation_policy[additional_question_one]', this.state.policyQuestions.question1)
        updatebody.append('cancellation_policy[additional_question_two]', this.state.policyQuestions.question2)
        updatebody.append('cancellation_policy[additional_question_three]', this.state.policyQuestions.question3)
        this.setState({ cancellationLoader: true })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateCancellationEndPoint}/${this.state.policyDetails?.attributes?.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), updatebody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateApiMethodType
        );
        this.apiCallIdUpdateCancellationPolicy = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}
