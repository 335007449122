import React from "react";
// Customizable Area Start
import {
    Grid, Typography, Box, Paper, MenuItem
} from "@material-ui/core";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import CategoriesPOBOController, { Props, RatingValues } from "./CategoriesPOBOController.web";
import { CustomizedSlider, StyledCheckbox, CustomizedSwitch } from "../../../components/src/CustomizedComponents.web";
import clsx from 'clsx';
import { boxShadows } from "../../../components/src/theme";
import { mapImage } from "./assets";
import SortingPO from "../../sorting/src/SortingPO.web";
// Customizable Area End

// Customizable Area Start
const priceValues = [
    {
        value: 0,
        label: "",
    },
    {
        value: 10,
        label: '£10',
    },
    {
        value: 50,
        label: '£50',
    },
    {
        value: 100,
        label: '£100',
    },
    {
        value: 150,
        label: '£150',
    },
    {
        value: 200,
        label: "£200"
    }
];

// Customizable Area End

export class CategoriessubcategoriesPO extends CategoriesPOBOController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    checkBoxData = (classes: any, item: any, testName: string, action: any) => {
        return (
            <>
                <Box display={'flex'} alignItems={'center'} key={item?.id} >
                    <StyledCheckbox
                        checked={item.hidden}
                        onChange={() => action(item.id)}
                        data-test-id={testName}
                        checkedIcon={<span className={clsx(classes.checkedBoxIcon, classes.tickIcon)} />}
                        icon={<span className={classes.checkedBoxIcon} />}
                        inputProps={{ 'aria-label': 'decorative checkbox' }}

                    />
                    <Typography variant="body1"

                        className={classes.filtercheckBoxText}
                    >
                        {item.title || item?.attributes?.service_name}

                    </Typography>
                </Box>
            </>

        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        const filteredServices = this.state.otherOtionFiltersData.filter(
            (service) =>!service.attributes.service_name.toLowerCase().includes("specify")
        );
        return (
            <>
                <Grid item xs={12} className={classes.paperFilter} >
                    <Grid container direction="row" justifyContent="center" spacing={2} className={classes.filterPadding}>
                        <Grid item xs={12}>
                            <SortingPO
                                handlesortFilterClose={this.handlesortFilterClose}
                                sortByFilters={this.state.sortByFilters}
                                handleSelectSortFilter={this.handleSelectSortFilter}
                                selectedSortType={this.state.selectedSortType}
                                filterModalOpen={this.filterModalOpen}
                                sortFilterStatus={this.state.sortFilterStatus}
                                classes={this.props.classes} />
                        </Grid>
                        <Grid item xs={12} className={classes.padding20}>
                            <Box>
                                <Box className={classes.mapLayout}  >
                                    <Box className={classes.switchLayout}>
                                        <div className={classes.flex_spaceBetween}>
                                            <Typography variant="body1" className={classes.priceText} style={{ marginLeft: "15px" }}> Map view</Typography>
                                            <div>
                                                <CustomizedSwitch 
                                                
                                                    data-test-id="mapSwitch"
                                                    checked={this.state.mapCheckStatus}
                                                    onChange={this.handleMapChange}
                                                />
                                            </div>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container direction="row" justifyContent="space-between" className={classes.headingLayout}>
                                <Grid item>
                                    <Typography className={classes.priceText} variant="body1">Filter by:</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" color="secondary" className={classes.filterClearText} data-test-id='clear_filters' onClick={this.clearFilters}>Clear all</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid className={classes.radiusPriceFilterLayout} container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                <Box p={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12} >
                                                <Typography className={classes.sortTextWeight} variant="subtitle2" color="textPrimary">
                                                    Your Budget (per day)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box p={2} style={{ width: '100%' }}>
                                                    <CustomizedSlider
                                                    onChangeCommitted={this.handlebudjectSlider}
                                                        data-test-id="buject_slider"
                                                        onChange={this.handlebudjectSlider}
                                                        value={this.state.budjectSliderRange}
                                                        marks={priceValues}
                                                        min={0}
                                                        max={200}
                                                    // step={5}
                                                    />
                                                </Box>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid className={classes.ratingLayout} container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Box p={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12} >
                                                <Typography className={classes.sortTextWeight} variant="subtitle2" color="textPrimary">
                                                    Property Rating
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="space-evenly" spacing={1}>
                                                    {this.state.ratingData.map((rating: RatingValues) => {
                                                        const ratingImage = rating.activeStatus ? rating.selectedIcon : rating?.icon;
                                                        return (
                                                            <>
                                                                <Grid item xs={2} container justifyContent={'center'}
                                                                    alignItems={'center'}
                                                                    data-test-id="select_rating"
                                                                    className={rating?.activeStatus ? classes.selectedStarLayout : classes.starLayout}
                                                                    onClick={() => this.handleRating(rating)}
                                                            
                                                                >
                                                                    <img src={ratingImage} alt="star" width={24} height={24} />
                                                                    <Typography className={rating?.activeStatus ? classes.selectedStartText : classes.starText} component={'div'}>{rating?.value}</Typography>

                                                                </Grid>
                                                            </>
                                                        )

                                                    })}
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>


                                </Box>

                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} className={classes.filterLayout}>
                                <Box p={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.sortTextWeight} variant="subtitle2" color="textPrimary">
                                                    Popular Filters
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" justifyContent="flex-start" spacing={2} >
                                                    {this.state.popularFiltersData.map((item: any) => {
                                                        return (<>

                                                            {this.checkBoxData(classes, item, "popular_options", this.handlePopularFilters)}
                                                        </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} className={classes.filterLayout}>
                                <Box p={2}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.sortTextWeight} variant="subtitle2" color="textPrimary">
                                                    Other Options
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" justifyContent="flex-start" spacing={2} >
                                                    {filteredServices.map((item: any) => {
                                                        return (<>
                                                            {this.checkBoxData(classes, item, "other_options", this.handleOtherOptionFilters)}
                                                        </>
                                                        )
                                                    })}
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>

        );
        // Customizable Area End

    }
}
// Customizable Area Start
export const CategoriessubcategoriesPOStyles = (theme: Theme) =>
    createStyles({
        padding20: {
            marginTop: "8px !important"
        },
        filterPadding: {
            padding: '0.7rem'
        },
        paperFilter: {
            backgroundColor: theme.palette.info.main,
            borderRadius: 8,
            maxWidth: '325px',
            width: '100%',
            [theme.breakpoints.down('xs')]:{
                maxWidth:'100%'
            }
        },
        filterClearText: {
            fontWeight: theme.typography.fontWeightBold,
            textDecoration: 'underline',
            cursor: "pointer",
            '&:hover': {
                cursor: 'pointer',
                color: theme.palette.common.black,
            }

        },
        radiusPriceFilterLayout: {
            background: theme.palette.common.white,
            maxWidth: "305px",
            width: "100%",
            height: "100%",
            borderRadius: 4,
            margin: 'auto',
            maxHeight: "134px",
            [theme.breakpoints.down('xs')]:{
            maxWidth: "100%",

            }
        },
        ratingLayout: {
            background: theme.palette.common.white,
            maxWidth: "305px",
            width: "100%",
            height: "100%",
            borderRadius: 4,
            margin: 'auto',
            maxHeight: "115px",
            [theme.breakpoints.down('xs')]:{
                maxWidth: "100%",
    
                }
        },
        filterLayout: {
            background: theme.palette.common.white,
            maxWidth: "305px",
            width: "100%",
            height: "100%",
            borderRadius: 4,
            margin: 'auto',
            [theme.breakpoints.down('xs')]:{
                maxWidth: "100%",
    
                }
        },
        filtercheckBoxText: {
            color: "#64748B",
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
        },
        checkedBoxIcon: {
            border: '1px solid #326478',
            borderRadius: 5,
            width: 20,
            height: 20,
            backgroundColor: theme.palette.common.white,
            '$root.Mui-focusVisible &': {
                outline: '1px auto #326478',
                outlineOffset: 2,
            },
        },
        tickIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 20,
                height: 20,
                backgroundImage:
                    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                    " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                    "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
        starLayout: {
            background: theme.palette.primary.main,
            height: 32, 
            borderRadius: 8, 
            padding: "0px !important"

        },
        selectedStarLayout: {
            background: theme.palette.info.dark,
            height: 32, 
            borderRadius: 8, 
            padding: "0px !important"
        },
        starText: {
            color: "#98DBF6",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 700,
            marginRight: 5
        },
        priceText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        },
        position: {
            position: "relative",
            boxShadow: boxShadows.blueShadow
        },
        switchLayout: {
            position: "absolute",
            top: 10,
            left: 20,
            background: theme.palette.common.white,
            borderRadius: 30,
            maxWidth: "154px",
            width: "100%"
        },
        mapLayout: {
            backgroundImage: `url(${mapImage})`,
            position: "relative",
            backgroundSize: "100% 100%",
            height: "110px",
            borderRadius: 8,
            border: `3px solid ${theme.palette.common.white}`
        },
        flex_spaceBetween: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        sortTextWeight: {
            fontWeight: theme.typography.fontWeightRegular
        },
        headingLayout: {
            padding: "0 1rem 0 1rem"
        },
        sortPosition: {
            width: "100%",
            maxWidth: "309px",
            backgroundColor: "#ffffff",
            margin: 'auto',
            borderRadius: 8,
            position: 'relative',
            // height:'100%',
            [theme.breakpoints.down('xs')]:{
                maxWidth: "100%",
    
                }

        },
        sortPopUpLayout: {
            left: 0,
            position: "absolute",
            zIndex: 9,
            width: "100%",
            maxWidth: "305px",
            minHeight: "194px",
            borderRadius: "5px",
            backgroundColor: theme.palette.common.white,
            boxShadow: boxShadows.blueShadow,
            [theme.breakpoints.down('xs')]:{
                maxWidth: "100%",
    
                }
        },
        sortHeading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        },
        selectedStartText: {
            color: theme.palette.common.white,
            marginRight: '5px'
        },
    })
export default withStyles(CategoriessubcategoriesPOStyles)(CategoriessubcategoriesPO);

// Customizable Area End