import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles } from "@material-ui/core";
import React from "react";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab";
import { handleNavigation } from "../../../components/src/CommonFunctions";

// Customizable Area Start
export interface StripeForm {
  bussinessType: string;
  salutation: string
  firstName: string;
  surname: string;
  email: string;
  PhoneNumberCountryCode: string
  phoneNumber: string;
  day: string
  month: string;
  monthValue: number
  year: string
  addressLine1: string;
  city: string;
  state: string;
  postCode: string;
  addressCountry: string
  merchantCode: string;
  merchantSalutation: string;
  merchantName: string;
  url: string;
  bussinessCountry: string
  externalAccountType: string,
  externalCountry: string
  currency: string
  externalSatulatation: string
  accountHolderName: string;
  swiftCode: string;
  accountNumber: string;
  document: any;

  phoneCountryCode: string;
  companyName: string;
  companyAddress: string;
  companyCity: string;
  companyPostalCode: string;
  companyTax: string;
  companyPhone: string;
  companyEmail: string;
  businessName: string;
  companyPhoneNumberCountryCode: string;
  companyPhoneCode: string;

}
interface LinkObject {
  url: string;
  title: string;
}
export interface Bussiness {
  bussiness_id: number;
  bussiness_type: string
}
export interface Identity {
  identity_id: number,
  identity_name: string;
  selected: boolean
}
export interface MonthList {
  month_id: number,
  monthName: string
}
export interface MccCodeData {
  mcc_code: {
    data: [
      {
        id: number;
        type: string;
        attributes: {
          mcc_code: number;
        }
      }
    ]
  }
}
export interface Month {
  id: number;
  monthName: string
}
interface ApiPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}


export interface VerfiedAccounts {
  data: VerfiedAccountsData[]
}

export interface VerfiedAccountsData {
  id: string
  type: string
  attributes: VerifiedAccountHotelAttributes
}

export interface VerifiedAccountHotelAttributes {
  id: number
  stripe_connected_account_id: string
  account_id: number
  identity_document_status: string
  external_account_status: string
  business_type: string
  hotel: VerifiedAccountHotel
}

export interface VerifiedAccountHotel {
  id: number
  name: string
  category: any
  check_in: any
  check_out: any
  account_id: number
  created_at: string
  updated_at: string
  address: any
  room_type: any
  phone_number: string
  service_type: string
  latitude: number
  longitude: number
  description: string
  property_name: any
  license_number: string
  country_region: any
  street_address: any
  flat_suite: any
  city_town: any
  postcode: any
  email: string
  daycare_price: any
  additional_services: any[]
  title: string
  budget_per_day: number
  rating: number
  pet_type: string
  active: boolean
  check_in_start: string
  check_in_end: string
  check_out_start: string
  check_out_end: string
  minimum_stay: any
  maximum_stay: any
  advance_notice: any
  blocked_days: any[]
  radius: any
  location: any
  establishment_type: any
  price: any
  daycare_price_for_dog: number
  daycare_price_for_cat: number
  hotel_type: any[]
  phone_number_country_code: string
  representive_country_code: any
  legal_business_name: string
  vat_number: string
  hotel_address_line_1: string
  hotel_address_line_2: string
  hotel_city: string
  hotel_country: string
  hotel_postcode: string
  hotel_country_region: string
  first_name: any
  surname: any
  reprsentive_phone_number: any
  address_line_1: any
  address_line_2: any
  country: any
  is_active: boolean
  cover_image_id: number
  status: string
  reason_for_reject: any
  representative_contact_title: any
  is_featured: boolean
  more_than_30_days: boolean
  images_order: number[]
  check_in_check_out_times_present: boolean
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addressDropDownStatus: boolean;
  bussinessprofileDropDownStatus: boolean;
  externalAccountDropDownStatus: boolean;
  StripeForm: StripeForm;
  bussinessDropDownStatus: boolean;
  salutationDropDownStatus: boolean;
  phoneCountryCodeDropDownStatus: boolean;
  addressCountryStatus: boolean;
  merchantSalutationStatus: boolean;
  merchantCountryStatus: boolean;
  externalCountryStatus: boolean;
  externalSalutationStatus: boolean
  bussinessList: Bussiness[];
  stripeFormErrors: any;
  dropdownKey: string;
  identityDocuments: Identity[],
  identityDocumentsdropDownStatus: boolean;
  stripeAccountCeateStatus: boolean;
  yearsList: any;
  monthList: Month[],
  daysList: any
  openYearStatus: boolean,
  openMonthStatus: boolean,
  openDayStatus: boolean,
  PdfFile: File | null,
  selectMonthIndexs: any;
  pdfError: string;
  documentUploadStatus: boolean
  fileSize: number | string
  Bussinescount: number,
  bussinessStep: number,
  modelOpen: boolean
  toasterStatus: boolean,
  toasterText: string
  toasterType: Color
  mccCode: number
  socialLinksData: { icons: string, navigationUrl: string }[];
  poBOLinksData: LinkObject[];
  stripePageLoader: boolean;
  companyCodeStatus: boolean;
  companyDocument: File | null;
  companyDocumentUploadStatus: boolean;
  companyDocumentError: string;
  previousSelection: boolean;
  connectedAccountDropdown: boolean;
  connectedAccountList: VerfiedAccountsData[]
  previousSelectedHotel: number | null
  selectedHotel: string;
  previousAccountError: string
  companyFileSize: number | string





  // Customizable Area End
}

interface SS {
  id: any;
}

export default class StripeAccountDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef = React.createRef() as any;
  apiCallIdStripeAccountSetup: string = ""
  mccCodeCallId: string = "";
  apicallIdGetConnectedAccountList: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      addressDropDownStatus: true,
      bussinessprofileDropDownStatus: true,
      externalAccountDropDownStatus: true,
      StripeForm: {
        bussinessType: "Individual",
        salutation: "Mr.",
        firstName: "",
        surname: "",
        email: "",
        PhoneNumberCountryCode: "United Kingdom (+44)",
        companyPhoneNumberCountryCode: "United Kingdom (+44)",
        phoneNumber: "",
        day: "",
        month: '',
        monthValue: 0,
        year: "",
        addressLine1: "",
        city: "",
        state: "",
        postCode: "",
        addressCountry: "United Kingdom",
        merchantCode: "",
        merchantSalutation: "Mr.",
        merchantName: "",
        url: "https://",
        bussinessCountry: "United Kingdom",
        externalAccountType: "Bank Account",
        externalCountry: "United Kingdom",
        currency: "GBP",
        externalSatulatation: "Mr.",
        accountHolderName: "",
        swiftCode: "",
        accountNumber: "",
        document: "",
        phoneCountryCode: "+44",
        companyName: "",
        companyAddress: "",
        companyCity: "",
        companyPostalCode: "",
        companyTax: "",
        companyPhone: "",
        companyEmail: "",
        businessName: "",
        companyPhoneCode: "+44",

      },
      bussinessDropDownStatus: false,
      salutationDropDownStatus: false,
      phoneCountryCodeDropDownStatus: false,
      addressCountryStatus: false,
      merchantSalutationStatus: false,
      merchantCountryStatus: false,
      externalCountryStatus: false,
      externalSalutationStatus: false,
      companyCodeStatus: false,
      bussinessList: [
        {
          bussiness_id: 1,
          bussiness_type: 'Individual'
        },
        {
          bussiness_id: 2,
          bussiness_type: 'Company'
        }
      ],
      stripeFormErrors: {},
      dropdownKey: "",
      identityDocuments: [
        {
          identity_id: 1,
          identity_name: 'Passport',
          selected: false
        },
        {
          identity_id: 2,
          identity_name: 'Driver License',
          selected: false

        }, {
          identity_id: 3,
          identity_name: 'Resident Permit',
          selected: false

        },
        {
          identity_id: 4,
          identity_name: 'Citizen Card',
          selected: false

        }, {
          identity_id: 5,
          identity_name: 'Electoral ID',
          selected: false

        },
        {
          identity_id: 6,
          identity_name: 'Other',
          selected: false

        },

      ],
      identityDocumentsdropDownStatus: false,
      stripeAccountCeateStatus: false,
      yearsList: [],

      monthList: [
        { id: 1, monthName: 'January' },
        { id: 2, monthName: 'Feburary' },
        { id: 3, monthName: 'March' },
        { id: 4, monthName: 'April' },
        { id: 5, monthName: 'May' },
        { id: 6, monthName: 'June', },
        { id: 7, monthName: 'July' },
        { id: 8, monthName: 'August' },
        { id: 9, monthName: 'September' },
        { id: 10, monthName: 'October' },
        { id: 11, monthName: 'November', },
        { id: 12, monthName: 'December' },
      ],
      daysList: Array.from({ length: 31 }, (_, i) => i + 1),
      openYearStatus: false,
      openMonthStatus: false,
      openDayStatus: false,
      PdfFile: null,
      selectMonthIndexs: new Date().getMonth(),
      pdfError: "",
      documentUploadStatus: false,
      fileSize: "",
      Bussinescount: 1,
      bussinessStep: 1,
      modelOpen: false,
      toasterStatus: false,
      toasterText: "",
      toasterType: "success",
      mccCode: 0,
      socialLinksData: [],
      poBOLinksData: [],
      stripePageLoader: false,
      companyDocument: null,
      companyDocumentUploadStatus: false,
      companyDocumentError: "",
      previousSelection: false,
      connectedAccountDropdown: false,
      connectedAccountList: [],
      previousSelectedHotel: null,
      selectedHotel: "",
      previousAccountError: "",
      companyFileSize:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getYearsList()
    this.getConnectedAccountList()

    const Bussinescount = Number(await getStorageData("BussinessCount"))
    this.setState({ Bussinescount: Bussinescount })
    this.getMccCode();

    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({ socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks", true)
    this.setState({ poBOLinksData: allLinksData })
  }
  // Customizable Area End

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.apiCallIdStripeAccountSetup) {
      if (responseJson && !responseJson.errors && responseJson.data||responseJson?.connected_account) {
        this.setState({ toasterStatus: true, toasterText: 'Stripe Account Created Successfully', toasterType: 'success', stripePageLoader: false })
        setTimeout(() => {
          this.getRoute()
        }, 1000)
      } else if (responseJson && responseJson.errors) {
        {
          responseJson.errors.map((error: any, index: number) => {
            const errorKey = Object.keys(error)[0]; // Get the first key of the error object
            const errorMessage = error[errorKey]; // Get the corresponding error message
            this.setState({ toasterStatus: true, toasterText: errorMessage, toasterType: 'error', stripePageLoader: false })
          })
        }
      }
      if (responseJson.status == 400) {
        this.setState({ toasterStatus: true, toasterText: 'Plese Re-Enter The Correct Details ', toasterType: 'error', stripePageLoader: false })
      }
    } else if (apiRequestCallId === this.mccCodeCallId) {
      this.mccCodeSuccessCallBack(responseJson);
    } else if (apiRequestCallId === this.apicallIdGetConnectedAccountList) {
      if (responseJson && !responseJson.errors) {
        this.setState({ connectedAccountList: responseJson?.verfied_accounts?.data })
      }
    }
    // Customizable Area End
  };
  // Customizable Area Start

  getRoute = () => {
    handleNavigation("SubscriptionDetails", {}, this.send, this.props)
  }


  getYearsList = () => {


    let years = []
    const currentYear = (new Date()).getFullYear();
    years = Array.from({ length: (currentYear - 1900) / 1 + 1 }, (_, i) => 1900 + (i * 1));
    this.setState({ yearsList: years.reverse() });

  }
  handleOpenPopupData = (key: string) => {
    this.setState({ dropdownKey: key })
    if (key == 'bussiness') {
      this.setState({ bussinessDropDownStatus: true })
    } else if (key == 'salutation') {
      this.setState({ salutationDropDownStatus: true })
    } else if (key == 'phoneCountryCode') {
      this.setState({ phoneCountryCodeDropDownStatus: true })
    } else if (key == "addressCountry") {
      this.setState({ addressCountryStatus: true })
    } else if (key == "merchantSalutation") {
      this.setState({ merchantSalutationStatus: true })
    } else if (key == "bussinessCountry") {
      this.setState({ merchantCountryStatus: true })
    } else if (key == "externalSalutation") {
      this.setState({ externalSalutationStatus: true })
    } else if (key == 'externalCountry') {
      this.setState({ externalCountryStatus: true })
    } else if (key == 'identity') {
      this.setState({ identityDocumentsdropDownStatus: true })
    } else if (key == 'openYear') {
      this.setState({ openYearStatus: true })
    } else if (key == 'openMonth') {
      this.setState({ openMonthStatus: true })
    } else if (key == 'openDay') {
      this.setState({ openDayStatus: true })
    } else if (key == 'companyPhone') {
      this.setState({ companyCodeStatus: true })
    }

  }
  handleClosePopupData = (key: string) => {
    if (key == 'bussiness') {
      this.setState({ bussinessDropDownStatus: false })
    } else if (key == 'salutation') {
      this.setState({ salutationDropDownStatus: false })
    } else if (key == 'phoneCountryCode') {
      this.setState({ phoneCountryCodeDropDownStatus: false })
    } else if (key == "addressCountry") {
      this.setState({ addressCountryStatus: false })
    } else if (key == "merchantSalutation") {
      this.setState({ merchantSalutationStatus: false })
    } else if (key == "bussinessCountry") {
      this.setState({ merchantCountryStatus: false })
    } else if (key == "externalSalutation") {
      this.setState({ externalSalutationStatus: false })
    } else if (key == 'externalCountry') {
      this.setState({ externalCountryStatus: false })
    } else if (key == 'identity') {
      this.setState({ identityDocumentsdropDownStatus: false })
    } else if (key == 'openYear') {
      this.setState({ openYearStatus: false })
    } else if (key == 'openMonth') {
      this.setState({ openMonthStatus: false })
    } else if (key == 'openDay') {
      this.setState({ openDayStatus: false })
    } else if (key == 'companyPhone') {
      this.setState({ companyCodeStatus: false })
    } else if (key == 'verifiedAccounts') {
      this.setState({ connectedAccountDropdown: false })

    }

  }


  handleStripeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    let errors = { ...this.state.stripeFormErrors }
    const { name, value } = event.target
    { name == "firstName" && this.firstNameValidate(value, name, errors, "First name") }
    { name == "surname" && this.firstNameValidate(value, name, errors, "Surname") }
    { name == "email" && this.emailValidate(value, name, errors) }
    { name == 'phoneNumber' && this.validateNumber(value, name, errors, "Phone number") }
    { name == 'addressLine1' && this.validateAlphaNumeric(value, name, errors, "Address Line 1") }
    { name == 'city' && this.firstNameValidate(value, name, errors, "City") }
    { name == 'state' && this.firstNameValidate(value, name, errors, "County") }
    { name == 'postCode' && this.validateAlphaNumeric(value, name, errors, "Post code") }
    { name == "merchantName" && this.firstNameValidate(value, name, errors, "Name") }
    { name == 'accountHolderName' && this.firstNameValidate(value, name, errors, "Account holder name") }
    { name == 'swiftCode' && this.validateNumber(value, name, errors, "Routing Number / Swift Code") }
    { name == 'accountNumber' && this.validateNumber(value, name, errors, "Account number") }
    { name == 'day' && this.validateDateOfBirth(value, name, errors, "Date of Birth") }
    { name == 'month' && this.validateDateOfBirth(value, name, errors, "Date of Birth") }
    { name == 'year' && this.validateDateOfBirth(value, name, errors, "Date of Birth") }
    this.companyForm(name, value, errors)
    this.inputValues(name, value, errors)

    this.setState({
      stripeFormErrors: errors
    })
  }
  inputValues = (name: string, value: string, errors: StripeForm) => {
    if (name === 'url') {
      this.validateUrl(value, name, errors, "Business URL");
      // Ensure the URL always starts with "https://"
      if (value.startsWith('https://')) {
        this.setState({
          StripeForm: { ...this.state.StripeForm, url: value },
        });
      } else {
        this.setState({
          StripeForm: { ...this.state.StripeForm, url: 'https://' },
        });
      }
    } else {
      // Handle other input fields
      this.setState({
        StripeForm: { ...this.state.StripeForm, [name]: value },
      });
    }

  }
  companyForm = (name: string, value: string, errors: StripeForm) => {
    { name == 'companyName' && this.firstNameValidate(value, name, errors, "Company Name") }
    { name == 'companyAddress' && this.validateAlphaNumeric(value, name, errors, "Company Address") }
    { name == 'companyCity' && this.firstNameValidate(value, name, errors, "Company City") }
    { name == 'companyPostalCode' && this.validateAlphaNumeric(value, name, errors, "Company Postcode") }
    { name == 'companyTax' && this.validateAlphaNumeric(value, name, errors, "Company Tax number") }
    { name == "companyEmail" && this.emailValidate(value, name, errors) }
    { name == "businessName" && this.firstNameValidate(value, name, errors, "Business Name") }
    { name == 'companyPhone' && this.validateNumber(value, name, errors, "Company Phone Number") }
  }
  validateDateOfBirth = (value: any, name: any, errors: any, key: any) => {
    errors[name] = `${key} is required`
  }
  firstNameValidate = (value: string, name: string, errors: any, key: string) => {
    const firstNameRegex = configJSON.nameRegex

    if (!value) {
      errors[name] = `${key} is required`
    } else if (!firstNameRegex.test(value)) {
      errors[name] = "Please enter alphabetical characters";
    } else {
      delete errors[name]; // No error
    }

  }
  emailValidate = (value: string, name: string, errors: any) => {
    const regexEmail = configJSON.emailRegex
    if (!value) {
      errors[name] = "Email Address is required";
    } else if (!regexEmail.test(value)) {
      errors[name] = "Please enter the valid email address";
    } else {
      delete errors[name];
    }
  }
  validateNumber = (value: string, name: string, errors: any, type: string) => {
    const regexNumbers = configJSON.numerRegex;
    if (!value) {
      errors[name] = `${type} is required`;
    } else if (!regexNumbers.test(value)) {
      errors[name] = name === 'phoneNumber' ? `Please enter a valid phone number` : `${type} in numerical characters`;
    } else {
      delete errors[name];
    }
  }

  validateAlphaNumeric = (value: string, name: string, errors: any, type: string) => {
    const postcodeRegex = /^[a-zA-Z0-9\s]*$/

    if (!value) {
      errors[name] = `${type} is required`
    } else if (!postcodeRegex.test(value)) {
      errors[name] = `Please enter valid ${type}`;
    } else {
      delete errors[name];
    }
  }


  openAccodition = (type: string) => {
    if (type == "address") {
      this.setState({ addressDropDownStatus: !this.state.addressDropDownStatus })
    } else if (type == "bussiness") {
      this.setState({ bussinessprofileDropDownStatus: !this.state.bussinessprofileDropDownStatus })
    } else if (type == "external_account") {
      this.setState({ externalAccountDropDownStatus: !this.state.externalAccountDropDownStatus })
    }

  }

  handleSelectBussiness = (bussines: string) => {
    this.setState({ StripeForm: { ...this.state.StripeForm, bussinessType: bussines } }, () => {
      this.handleClosePopupData('bussiness')
    })

  }
  handleSelectSalutation = (salutation: string) => {
    if (this.state.dropdownKey == "salutation") {
      this.setState({ StripeForm: { ...this.state.StripeForm, salutation: salutation } }, () => {
        this.handleClosePopupData('salutation')
      })

    } else if (this.state.dropdownKey == "externalSalutation") {
      this.setState({ StripeForm: { ...this.state.StripeForm, externalSatulatation: salutation } }, () => {
        this.handleClosePopupData('externalSalutation')
      })

    } else if (this.state.dropdownKey == "merchantSalutation") {
      this.setState({ StripeForm: { ...this.state.StripeForm, merchantSalutation: salutation } }, () => {
        this.handleClosePopupData('merchantSalutation')
      })
    }
  }

  handleSelectCountry = (countryCode: string) => {

    if (this.state.dropdownKey == "addressCountry") {
      this.setState({ StripeForm: { ...this.state.StripeForm, addressCountry: countryCode } }, () => {
        this.handleClosePopupData('addressCountry')
      })

    } else if (this.state.dropdownKey == "bussinessCountry") {
      this.setState({ StripeForm: { ...this.state.StripeForm, bussinessCountry: countryCode } }, () => {
        this.handleClosePopupData('bussinessCountry')
      })

    } else if (this.state.dropdownKey == "externalCountry") {
      this.setState({ StripeForm: { ...this.state.StripeForm, externalCountry: countryCode } }, () => {
        this.handleClosePopupData('externalCountry')
      })
    }
  }
  selectPhoneNumber = (inputType: string, countryName: string, countryCode: string) => {
    if (inputType === 'companyPhone') {
      this.setState({ StripeForm: { ...this.state.StripeForm, companyPhoneCode: countryCode, companyPhoneNumberCountryCode: `${countryName}${countryCode}` } }, () => {
        this.handleClosePopupData('companyPhone')
      })
    } else {
      this.setState({ StripeForm: { ...this.state.StripeForm, phoneCountryCode: countryCode, PhoneNumberCountryCode: `${countryName}${countryCode}` } })
    }
  }




  validatePdf = (pdfName: File | null): string => {

    const imageExtensions = /\.(jpg|jpeg|png)$/i;
    const pdfExtension = /\.pdf$/i;
    let error = '';

    // Check if pdfFile is empty or null
    if (!pdfName) {
      error = 'Please upload an identity document';
    } else if (!imageExtensions.test(pdfName.name) && !pdfExtension.test(pdfName.name)) {
      error = 'Please select only JPEG/PNG or PDF document.';
    }

    return error;
  };

  handleCompanyDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const companyFile = event.target.files && event.target.files[0];

    let companyDocumentError = this.validatePdf(companyFile);
    this.validateCompanyDocumentFileSize(companyFile);
    this.setState({
      companyDocument: companyFile,
      pdfError: companyDocumentError,
      companyDocumentUploadStatus: !companyDocumentError && companyFile !== null,
      StripeForm: { ...this.state.StripeForm }
    });

  }

  handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    let pdfError = this.validatePdf(file);
    this.validateFileSize(file);
    this.setState({
      PdfFile: file,
      pdfError: pdfError,
      documentUploadStatus: !pdfError && file !== null,
      StripeForm: { ...this.state.StripeForm }
    });
  }

  validateFileSize = (file: any) => {
    let size = 1024
    let calculateSize = file?.size / size;
    this.setState({ fileSize: calculateSize.toFixed(2) + " KB" })

  }

  validateCompanyDocumentFileSize = (file: any) => {
    let size = 1024
    let calculateCompanySize = file?.size / size;
    this.setState({ companyFileSize: calculateCompanySize.toFixed(2) + " KB" })

  }


  deletePdfFile = (type: string) => {
    if (type === "company_document") {
      this.setState({
        StripeForm: { ...this.state.StripeForm, },
        stripeFormErrors: { ...this.state.stripeFormErrors },
        fileSize: "",
        companyDocument: null,
        companyDocumentUploadStatus: false

      })
    } else {
      this.setState({
        StripeForm: { ...this.state.StripeForm, },
        stripeFormErrors: { ...this.state.stripeFormErrors },
        fileSize: "",
        PdfFile: null,
        documentUploadStatus: false

      })
    }
  }
  selectLegalDocument = (slectedId: number, selectecType: string) => {

    const newSelectedDocument = this.state.identityDocuments.map((item: Identity) => {
      return { ...item, selected: item.identity_id === slectedId };
    });
    const selectedDocument = newSelectedDocument.find(item => item.identity_id === slectedId && item.selected);

    this.setState({
      identityDocuments: newSelectedDocument,
      StripeForm: { ...this.state.StripeForm, document: selectedDocument ? selectedDocument.identity_name : "" },
      stripeFormErrors: { ...this.state.stripeFormErrors, document: "" }

    }, () => {
      this.handleClosePopupData('identity')
    })
  }

  handleSelectYear = (year: string) => {
    delete this.state.stripeFormErrors['year']
    this.validateMonths(year, this.state.selectMonthIndexs)
    this.setState({ StripeForm: { ...this.state.StripeForm, year: year } }, () => {
      this.handleClosePopupData('openYear')
    })

  }

  handleSelectMonth = (month: Month) => {
    delete this.state.stripeFormErrors['month']
    let MonthIndex = this.state.monthList.findIndex(month => month === month);
    this.validateMonths(this.state.StripeForm.year, MonthIndex)
    this.setState({ selectMonthIndexs: MonthIndex });
    this.setState({ StripeForm: { ...this.state.StripeForm, month: month.monthName, monthValue: month.id } }, () => {
      this.handleClosePopupData('openMonth')
    })
  }

  validateMonths = (year: any, monthIndex: any) => {
    if ([0, 2, 4, 6, 7, 9, 11].includes(monthIndex)) {
      this.setState({ daysList: Array.from({ length: 31 }, (_, i) => i + 1) })
    } else if ([3, 5, 8, 10].includes(monthIndex)) {
      this.setState({ daysList: Array.from({ length: 30 }, (_, i) => i + 1) })

    } else if (((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) && ([1].includes(monthIndex))) {
      this.setState({ daysList: Array.from({ length: 29 }, (_, i) => i + 1) })

    } else if (1 === monthIndex) {
      this.setState({ daysList: Array.from({ length: 28 }, (_, i) => i + 1) })
    }
  }

  handleSelectDay = (day: string) => {
    delete this.state.stripeFormErrors['day']
    this.setState({ StripeForm: { ...this.state.StripeForm, day: day } }, () => {
      this.handleClosePopupData('openDay')
    })

  }


  createAccount = () => {
    let companyDocumentError = ""
    const errors = { ...this.state.stripeFormErrors };
    const { bussinessType, companyName, companyEmail, companyPhone, companyCity, companyAddress, companyPostalCode, companyTax, businessName } = this.state.StripeForm;

    this.firstNameValidate(this.state.StripeForm.firstName, "firstName", errors, "First name")
    this.firstNameValidate(this.state.StripeForm.surname, "surname", errors, "Surname")
    this.emailValidate(this.state.StripeForm.email, 'email', errors)
    this.validateNumber(this.state.StripeForm.phoneNumber, "phoneNumber", errors, "Phone number")
    this.validateAlphaNumeric(this.state.StripeForm.addressLine1, "addressLine1", errors, "Address Line 1")
    this.firstNameValidate(this.state.StripeForm.city, "city", errors, "City")
    this.firstNameValidate(this.state.StripeForm.state, "state", errors, "County")
    this.validateAlphaNumeric(this.state.StripeForm.postCode, "postCode", errors, "Post code")
    this.firstNameValidate(this.state.StripeForm.accountHolderName, "accountHolderName", errors, "Account holder name")
    this.validateNumber(this.state.StripeForm.swiftCode, "swiftCode", errors, "Routing Number / Swift Code")
    this.validateNumber(this.state.StripeForm.accountNumber, "accountNumber", errors, "Account Number")
    this.validateDateOfBirth(this.state.StripeForm.day, "day", errors, "Date of Birth")
    this.validateDateOfBirth(this.state.StripeForm.month, "month", errors, "Date of Birth")
    this.validateDateOfBirth(this.state.StripeForm.year, "year", errors, "Date of Birth")
    this.validateIdentityDocument(this.state.StripeForm.document, errors, "Identity document")
    if (bussinessType === "Company") {
      this.firstNameValidate(companyName, "companyName", errors, "Company Name")
      this.validateAlphaNumeric(companyAddress, "companyAddress", errors, "Date of Birth")
      this.firstNameValidate(companyCity, "companyCity", errors, "Company City")
      this.validateAlphaNumeric(companyPostalCode, "companyPostalCode", errors, "Company Postcode")
      this.validateAlphaNumeric(companyTax, "companyTax", errors, "Company Tax number")
      this.emailValidate(companyEmail, "companyEmail", errors)
      this.firstNameValidate(businessName, "businessName", errors, "Business Name")
      this.validateNumber(companyPhone, "companyPhone", errors, "Company Phone number")
      companyDocumentError = this.validatePdf(this.state.companyDocument)

    } else {
      this.firstNameValidate(this.state.StripeForm.merchantName, "merchantName", errors, "Name")

    }



    // company Type


    if (this.state.StripeForm.day) {
      delete errors['day'];
    }
    if (this.state.StripeForm.month) {
      delete errors['month'];
    }
    if (this.state.StripeForm.year) {
      delete errors['year'];
    }
    const pdfError = this.validatePdf(this.state.PdfFile);
    this.validateUrl(this.state.StripeForm.url, 'url', errors, "Business URL")
    this.setState({ stripeFormErrors: errors, modelOpen: false, pdfError, companyDocumentError },

      () => {
        if (this.state.previousSelection) {
          if (this.state.selectedHotel === "") {
            this.setState({ previousAccountError: "Please select the Hotel" })
          } else {
            this.setState({ previousAccountError: "" }, () => {

              this.createPreviousStripeAccount()
            })
          }
        } else {
          if (Object.keys(this.state.stripeFormErrors).length === 0 && !this.state.pdfError && !this.state.companyDocumentError) {
            this.stripeeAccountSetup();
          }
        }
      }
    );

  }
  validateIdentityDocument = (value: string, errors: StripeForm, key: string) => {
    if (!value) {
      errors['document'] = `Please select the  ${key} `;

    } else {
      delete errors['document'];

    }


  }

  stripeeAccountSetup = async () => {
    let tokenValue = await getStorageData("authToken");
    const hotel_id = await getStorageData('hotel_id')
    const headers = {
      token: tokenValue,
    };
    let formdata = new FormData();
    const { companyName, companyEmail, companyPhone, companyCity, companyAddress, companyPostalCode, companyPhoneNumberCountryCode, companyTax, businessName, companyPhoneCode } = this.state.StripeForm;
    this.setState({ stripePageLoader: true })
    formdata.append("connected_account[business_type]", this.state.StripeForm.bussinessType.toLowerCase());
    formdata.append("connected_account[first_name]", this.state.StripeForm.firstName);
    formdata.append("connected_account[surname]", this.state.StripeForm.surname);
    formdata.append("connected_account[email]", this.state.StripeForm.email);
    formdata.append("connected_account[full_phone_number]", `${this.state.StripeForm.phoneCountryCode}${this.state.StripeForm.phoneNumber}`);
    formdata.append("connected_account[dob_month]", this.state.StripeForm.monthValue.toString());
    formdata.append("connected_account[dob_year]", this.state.StripeForm.year);
    formdata.append("connected_account[dob_day]", this.state.StripeForm.day);
    formdata.append("connected_account[address_line]", this.state.StripeForm.addressLine1);
    formdata.append("connected_account[city]", this.state.StripeForm.city);
    formdata.append("connected_account[state]", this.state.StripeForm.state);
    formdata.append("connected_account[postcode]", this.state.StripeForm.postCode);
    formdata.append("connected_account[business_url]", this.state.StripeForm.url);
    formdata.append("connected_account[account_type]", "bank_account");
    formdata.append("connected_account[account_country]", 'GB');
    formdata.append("connected_account[currency]", this.state.StripeForm.currency);
    formdata.append("connected_account[account_holder_name]", this.state.StripeForm.accountHolderName);
    formdata.append("connected_account[routing_number]", this.state.StripeForm.swiftCode);
    formdata.append("connected_account[account_number]", this.state.StripeForm.accountNumber);
    if (this.state.PdfFile) {

      formdata.append("connected_account[identity_document]", this.state.PdfFile);
    }
    formdata.append("connected_account[first_name_salutation]", this.state.StripeForm.salutation);
    formdata.append("connected_account[phone_country_code]", this.state.StripeForm.PhoneNumberCountryCode);
    formdata.append("connected_account[business_profile_salutation]", this.state.StripeForm.merchantSalutation);
    formdata.append("connected_account[external_salutation]", this.state.StripeForm.externalSatulatation);
    formdata.append("connected_account[address_country]", 'GB');
    formdata.append("connected_account[proof_of_identity_document]", this.state.StripeForm.document);
    formdata.append("connected_account[hotel_id]", hotel_id);
    if (this.state.StripeForm.bussinessType === 'Company') {
      formdata.append("connected_account[company][name]", companyName);
      formdata.append("connected_account[company][email]", companyEmail);
      formdata.append("connected_account[company][phone]", `${companyPhoneCode}${companyPhone}`);
      formdata.append("connected_account[company_address_line1]", companyAddress);
      formdata.append("connected_account[company_city]", companyCity);
      formdata.append("connected_account[company_postal_code]", companyPostalCode);
      formdata.append("connected_account[company][tax_id]", companyTax);
      formdata.append("connected_account[business_name]", businessName);
      formdata.append("connected_account[company_phone_salutation]", `${companyPhoneNumberCountryCode}`);
      if (this.state.companyDocument) {

        formdata.append("connected_account[company][verification_document]", this.state.companyDocument);
      }
    } else {
      formdata.append("connected_account[business_mcc_code]", this.state.mccCode + "");
      formdata.append("connected_account[business_name]", this.state.StripeForm.merchantName);
      formdata.append("connected_account[business_profile_country]", 'GB');

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.stripeAccoutnApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.callTypeApiValidateMobileNo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), formdata
    );
    this.apiCallIdStripeAccountSetup = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createPreviousStripeAccount = async () => {
    let tokenValue = await getStorageData("authToken");
    const hotel_id = await getStorageData('hotel_id');
    const headers = {
      token: tokenValue,
      "Content-Type": "application/json",
    };
    let body = {
      select: this.state.previousSelection,
      connected_account: {
        hotel_id: hotel_id,
        existed_hotel_id: this.state.previousSelectedHotel
      }
    }
    this.setState({ stripePageLoader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.stripeAccoutnApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.callTypeApiValidateMobileNo
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );
    this.apiCallIdStripeAccountSetup = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goBack = async () => {
    const hotel_id = await getStorageData('hotel_id')
    handleNavigation("CancellationPolicyFirst", { activeStep: 2, hotelId: hotel_id }, this.send, this.props)
  }

  handleModelClose = () => {
    this.setState({
      modelOpen: false
    })
  }

  handlemodalOpen = () => {
    this.setState({
      modelOpen: true
    })
  }
  closeToasterStatus = () => {
    this.setState({ toasterStatus: false, toasterText: "" })
  }
  validateUrl = (value: string, name: string, errors: any, type: string) => {

    if (!value) {
      errors[name] = `${type} is required`
    } else if (!configJSON.urlRegex.test(value)) {

      errors[name] = `Please enter valid ${type}`;
    } else {
      delete errors[name];
    }
  }

  mccCodeSuccessCallBack = (responseJson: MccCodeData) => {
    const mccCodeData = responseJson.mcc_code.data[0].attributes.mcc_code;
    this.setState({ mccCode: mccCodeData });
  };

  apiCall = async (apiData: ApiPayloadType) => {
    const { method, endPoint, body, type } = apiData;

    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getMccCode = async () => {
    this.mccCodeCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.mccApiEndPoint
    });
  };
  getConnectedAccountList = async () => {
    this.apicallIdGetConnectedAccountList = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: 'bx_block_stripe_integration/stripe_connected_accounts/verified'
    });
  };

  handleYesSelection = () => {
    this.setState({ previousSelection: true })
  }

  handleNoSelection = () => {
    this.setState({ previousSelection: false })
  }

  handle_connected_account_dropdown = () => {
    this.setState({ connectedAccountDropdown: true })
  }

  selectVerifiedHotel = (previousHotel: number, hotelName: string) => {
    this.setState({
      previousSelectedHotel: previousHotel,
      selectedHotel: hotelName
    }, () => {
      this.handleClosePopupData('verifiedAccounts')
    })

  }
  // Customizable Area End
}