import React, { Component } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Avatar,
  withStyles,
  Theme,
  Grid,
  createStyles,
  WithStyles,
  ClickAwayListener,
  Box,
  Divider
} from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { getStorageData } from "../../framework/src/Utilities";
import { crossIcon, menuIcon, profileIcon } from "../../blocks/landingpage/src/assets";
import Footer from "./Footer.web";
import { boxShadows } from "./theme";
import { handleNavigation } from "./CommonFunctions";
export const petLogo = require('./MainLogo.svg')
export const petHotel = require('./PetHotel.svg')
export const baseURL = require("../../framework/src/config.js").baseURL;

interface Props extends WithStyles {
  navType?: string,
  activeLink?: number
  authContent?: string
  profilePhoto?: any
  pageName?: string
  send?:any
}
interface s {
  accountDetails: any,
  navbar: boolean,
  drawerStatus: boolean,
  iconType: string,
  profilePicture: string
}
class Navbar extends Component<Props, s> {

  constructor(props: Props) {
    super(props);
    this.state = {
      accountDetails: {
      },
      navbar: false,
      drawerStatus: false,
      iconType: '',
      profilePicture: ""

    };
  }
  async componentDidMount() {
    const profilePicture = await getStorageData('accountDetails', true) || {}
    this.setState({ profilePicture: profilePicture?.photo });
    this.navBarResize()
  }
  handleMenuOpen = (iconType: string) => {
    this.setState({ drawerStatus: true }, () => {
      this.setState({ iconType: iconType })
    });
  };
  closePopUp = () => {
    this.setState({ drawerStatus: false })
  }

  navBarResize = () => {
    return window.innerWidth <= 967 ?
      this.setState((prevState) => ({ ...prevState, navbar: true }))
      : this.setState((prevState) => ({ ...prevState, navbar: false }))

  }
  renderMobileMenuIcon = (iconType: string) => {
    const { classes } = this.props;
    const loggedin = Boolean(localStorage.getItem('authToken'));


    return (
      <>
        <ClickAwayListener onClickAway={this.closePopUp}>
          <Typography>
            <Box className={classes.statusModalContainerRequest}>
              <Box style={NavBars.statusModalRequest}>
                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                  <img src={petHotel} alt="PET LOGO" className={classes.companyLogo} />
                  <IconButton
                    edge="start"
                    onClick={this.closePopUp}
                    className={classes.iconCursor}
                  >
                    <img src={crossIcon} width={44} height={44} />
                  </IconButton>
                </Grid>
                {iconType === 'navbar' ?
                  <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="center" spacing={4} >
                      <Grid item xs={12}>
                        <NavLink to="/LandingPagePO" className={this.props.activeLink === 1 ? classes.selectedNavLink : classes.findHotels}>Find Hotels</NavLink>
                        <Divider className={classes.divider} />
                      </Grid>
                      <Grid item xs={12}>
                        <NavLink to="/AboutUs" className={this.props.activeLink === 2 ? classes.selectedNavLink : classes.findHotels}>About Us</NavLink>
                        <Divider className={classes.divider} />

                      </Grid>

                      <Grid item xs={12}>
                        <NavLink to={loggedin ? "/ReservationHistory" : ""} className={this.props.activeLink === 6 ? classes.selectedNavLink : classes.findHotels}>{loggedin ? "My Reservations" : null}</NavLink>
                        <Divider className={classes.divider} />

                      </Grid>
                      <Grid item xs={12}>
                        <NavLink to={loggedin ? "/Chat" : "/WhyRegister"} className={this.props.activeLink === 3 ? classes.selectedNavLink : classes.findHotels}>{loggedin ? "Inbox" : "Why Register"}</NavLink>
                        <Divider className={classes.divider} />

                      </Grid>
                      <Grid item xs={12}>
                        <NavLink to="/Contactus" className={this.props.activeLink === 4 ? classes.selectedNavLink : classes.findHotels}>Help</NavLink>
                        <Divider className={classes.divider} />

                      </Grid>

                      <Grid item xs={12}>
                        <NavLink to="/Faqs" className={this.props.activeLink === 5 ? classes.selectedNavLink : classes.findHotels}>FAQs</NavLink>
                        <Divider className={classes.divider} />

                      </Grid>
                      <Grid item xs={12}>
                        <Link
                          to="LogInBusinessOwner"
                          style={{ textDecoration: "none" }}
                        >
                          <Typography color="secondary" variant="subtitle2"
                            className={classes.listProperty}
                          >
                            Business Login
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item xs={12}>
                        <Footer drawer={this.state.drawerStatus} />
                      </Grid>

                    </Grid>

                  </Grid> :
                  <Grid item xs={12}>
                    <Grid container justifyContent='center' alignItems='center' spacing={4}>
                      <Grid item xs={12} container justifyContent="center">
                       
                      </Grid>
                      <Grid item xs={12}>
                        <NavLink to="/LogInPetOwner">
                          <Button fullWidth variant="outlined" className={classes.signInButton}> Sign In</Button>
                        </NavLink>
                      </Grid>
                      <Grid item xs={12}>
                        <Link to="EmailAccountRegistrationWeb">
                          <Button fullWidth className={classes.registerBtn}>Register</Button>
                        </Link>

                        <Link
                          to="LogInBusinessOwner"
                          style={{ textDecoration: "none" }}
                        >
                          <Button 
                            style={{
                              backgroundColor: "#EA0C78",
                              color: "#FFFFFF",
                              fontWeight: 700,
                              marginLeft: "10px",
                              textTransform: "none",
                            }}
                          >
                            Business Login
                          </Button>
                        </Link>

                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Box>
            </Box>
          </Typography>
        </ClickAwayListener>
      </>
    )
  }

  getProfilePicture = () => {
    if (this.props.profilePhoto) {
      return `${this.props.profilePhoto}`
    } else {
      return `${this.state.profilePicture}`
    }
  }
handleDaycare=()=>{
  handleNavigation("HotelsCatalogue", { filterType: "day_care" }, this.props.send, this.props)

}

  render() {
    const { classes } = this.props
    window.addEventListener('resize', this.navBarResize);
    const loggedin = Boolean(localStorage.getItem('authToken'));
    return (
      <>
        <AppBar position="fixed" className={this.props.activeLink === 1 ? classes.root : classes.rootshadow}>
          <Toolbar className={classes.toolBar}>
            {this.state.navbar ? (
              <>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} container alignItems="center">
                      <IconButton
                        edge="start"
                        onClick={() => this.handleMenuOpen('navbar')}
                      >
                        <img src={menuIcon} width={44} height={44} />
                      </IconButton>
                      <Link to="/LandingPagePO">
                        <img src={petHotel} alt="PET LOGO" className={classes.companyLogo} />
                      </Link>
                    </Grid>
                    <Grid item xs={6} >
                      <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                        {!loggedin ?
                          <>
                            <Grid item >
                              <Link to="LogInPetOwner">

                                <Button variant="outlined" className={classes.signInButton}> Sign In</Button>
                              </Link>
                            </Grid>
                            <Grid item >
                              <Link to="EmailAccountRegistrationWeb">
                                <Button
                                  variant="contained"
                                  className={classes.registerBtn}
                                >
                                  Register
                                </Button>
                              </Link>
                            </Grid>



                          </>

                          :

                          <IconButton
                            edge="start"
                            className={classes.iconCursor}
                          >
                            {this.props.pageName !== 'profile' && <NavLink to='/Settings2'>

                              <Avatar className={classes.mobileAvatar}
                                src={this.getProfilePicture()} />
                            </NavLink>}

                          </IconButton>

                        }
                      </Grid>
                    </Grid>

                  </Grid>

                </Grid>
                {this.state.drawerStatus && this.renderMobileMenuIcon(this.state.iconType)}


              </>
            ) : (
              <>
                {this.props.navType === 'fullNavbar' ? (
                  <>
                    <div className={classes.routingLayout}>
                      <Link to="/LandingPagePO">
                        <img src={petHotel} alt="PET LOGO" style={{ marginRight: "30px" }} />
                      </Link>
                      <NavLink to="/LandingPagePO" className={this.props.activeLink === 1 ? classes.selectedNavLink : classes.findHotels}>Find Hotels</NavLink>
                      <NavLink onClick={this.handleDaycare} to="/HotelsCatalogue?dayCareService=true" className={this.props.activeLink === 7 ? classes.selectedNavLink : classes.findHotels}>Doggy Daycare</NavLink>
                      <NavLink to="/AboutUs" className={this.props.activeLink === 2 ? classes.selectedNavLink : classes.findHotels}>About Us</NavLink>
                      {loggedin ? <NavLink to="/ReservationHistory" className={this.props.activeLink === 6 ? classes.selectedNavLink : classes.findHotels}>My Reservations</NavLink> : null}
                      <NavLink to={loggedin ? "/Chat" : "/WhyRegister"} className={this.props.activeLink === 3 ? classes.selectedNavLink : classes.findHotels}>{loggedin ? "Inbox" : "Why Register"}</NavLink>
                      <NavLink to="/Contactus" className={this.props.activeLink === 4 ? classes.selectedNavLink : classes.findHotels}>Help</NavLink>
                      <NavLink to="/Faqs" className={this.props.activeLink === 5 ? classes.selectedNavLink : classes.findHotels}>FAQs</NavLink>
                    </div>
                    {loggedin ?
                      <>
                        {this.props.pageName != "profile" &&
                          <NavLink to='/Settings2'>
                            <Avatar className={classes.avatar}
                              src={this.getProfilePicture()} />
                          </NavLink>
                        }
                      </>
                      :
                      <>
                        <Grid container justifyContent='center' alignItems='center' className={classes.signInLayout} >
                          
                          <Link to="LogInPetOwner">
                            <Button variant="outlined" className={classes.signInButton}> Sign In</Button>
                          </Link>
                          <Link to="EmailAccountRegistrationWeb">
                            <Button
                              style={{
                                backgroundColor: "#EA0C78",
                                color: "#FFFFFF",
                                fontWeight: 700,
                                marginLeft: "10px",
                                textTransform: "none",
                              }}
                            >
                              Register
                            </Button>
                          </Link>
                          <Link
                            to="LogInBusinessOwner"
                            style={{ textDecoration: "none" }}
                          >
                            <Button 
                              style={{
                                backgroundColor: "#EA0C78",
                                color: "#FFFFFF",fontWeight: 700,
                                marginLeft: "10px",textTransform: "none",
                              }}
                            >
                              Business Login
                            </Button>
                          </Link>
                        </Grid>
                      </>

                    }
                  </>
                ) : (
                  <>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Link to="/LandingPagePO">
                        <img
                          src={petHotel}
                          alt="PET LOGO"
                          style={{ marginRight: "30px" }}
                        />
                      </Link>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.props.authContent === 'Bo' ? (
                        <>
                          <Typography variant="subtitle2" className={classes.accountTypeText}>
                            Looking for Pet Hotels
                          </Typography>
                          <Link
                            to="/LoginPetOwner"
                            style={{
                              color: "#0F172A",
                              fontWeight: 700,
                              fontFamily: "Inter",
                              borderBottom: "1px solid #0F172A",
                              textDecoration: "none",
                            }}
                          >
                            Find Hotels
                          </Link>
                        </>
                      ) : (
                        <>
                          <Typography variant="subtitle2" className={classes.accountTypeText}>Do you own a hotel?</Typography>
                          <Link
                            to="/EmailAccountRegistrationBusinessOwner"
                            style={{
                              color: "#0F172A",
                              fontWeight: 700,
                              textDecoration: "none",
                              borderBottom: "1px solid #0F172A",
                            }}
                          >
                            Business Account
                          </Link>
                        </>
                      )}
                    </div>{" "}
                  </>
                )}
              </>
            )}
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

const NavbarStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      fontFamily: "inter",
      boxShadow: "none !important",

      padding: "10px 0px",
      [theme.breakpoints.down('xs')]: {
        padding: "0px !important"
      }
    }, rootshadow: {
      backgroundColor: '#ffffff',
      fontFamily: "inter",
      boxShadow: "0px 4px 8px 0px rgba(67, 134, 161, 0.03), 0px 8px 32px 0px rgba(67, 134, 161, 0.06)",

      padding: "10px 0px",
      [theme.breakpoints.down('xs')]: {
        padding: "0px !important"
      }
    },
    companyLogo: {
      width: " 262px",
      height: "88px",

      [theme.breakpoints.down('xs')]: {
        width: "114.31px",
        height: "39.11px",

      }
    },
    signInLayout: {
      width: 'auto !important'
    },
    routingLayout: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 5,
    },
    mobileAvatar: {
      width: 44,
      height: 44
    },
    avatar: {
      height: 68,
      width: 68,
      backgroundColor: "#64C9F2",
      border: "1.5px solid #54A7C9"
    },
    listProperty: {
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      textDecoration: "none",
      width: 'max-content',
      marginRight: 10

    },
    selectedNavLink: {
      color: theme.palette.common.black,
      marginRight: 15,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      textDecoration: "underline",
    },
    findHotels: {
      color: theme.palette.common.black,
      textDecoration: "none",
      fontSize: 16,
      marginRight: 15,
      '&:focus': {
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: "underline",

      }
    },
    signInButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      border: `1px solid ${theme.palette.secondary.main}`,
      textTransform: "none",
      [theme.breakpoints.down('xs')]: {
        fontSize: "10.25px",
        height: "Fixed (28.19px)",
        borderRadius: "5.13px",
        gap: "5.13px"

      }
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between"
    },
    accountTypeText: {
      color: theme.palette.common.black,
      textAlign: "center",
      marginRight: "10px",
    },
    IconColor: {
      color: theme.palette.common.black
    },
    drawerPaper: {
      width: "230px",
      opacity: '0.87',
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(1),
      justifyContent: 'space-between',
      height: 'fit-content'
    },
    drawerCell: {
      padding: 40
    },
    iconCursor: {
      cursor: 'pointer'
    },
    registerBtn: {
      backgroundColor: "#EA0C78",
      color: "#FFFFFF",
      fontWeight: 700,
      textTransform: "none",
      [theme.breakpoints.down('xs')]: {
        fontSize: "10.25px",
        width: "Hug (63.5px)",
        height: "Fixed (28.19px)",
        padding: "6.41px, 10.25px, 6.41px, 10.25px",
        borderRadius: "5.13px",
        gap: "5.13px"

      }
    },
    statusModalContainerRequest: {
      position: "absolute",
      zIndex: 9,
      width: "100%",
      borderRadius: "0 0 30px 30px",
      backgroundColor: "#F0FAFE",
      top: 0,
      right: 0,
    },
    divider: {
      backgroundColor: theme.palette.primary.main,
      margin: "15px 0 15px 0"
    }
  })

export default withStyles(NavbarStyles)(Navbar);

const NavBars = {
  btns: {
    fontSize: "14px",
    fontWeight: 700,
  },
  statusModalContainerRequest: {
    position: "absolute" as const,
    zIndex: 9,
    width: "100%",
    minHeight: "532px",
    borderRadius: "0 0 30px 30px",
    backgroundColor: "#F0FAFE",
    top: 0,
    right: 0,
    height: '100%'
  },
  statusModalRequest: {
    padding: "2rem"
  },
};