import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "framework/src/Utilities";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";

interface LogInResponseForBO {
  meta: {
    token: string;
  };
  data: {
    attributes: { type: string ,is_hotel_created:boolean};
    id: string;
  };
  errors?:
  | {
    failed_login?: string;
  }[]
  | { password_confirmation?: string[]; failed_login?: string };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation: any;
}

export interface S {
  // Customizable Area Start

  password: string;
  email: string;
  ratingValue: number;
  showPassword: boolean;
  isCheckboxChecked: boolean;
  showYourPassword: boolean;
  apiErrorLogInForBO: string;
  flashBo: boolean;
  flashSlack: boolean;
  flashEmailForBusiness:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EmailAccountRegistrationPetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginCallId: string = "";
  profileId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      password: "",
      email: "",
      ratingValue: 4,
      showPassword: false,
      isCheckboxChecked: false,
      showYourPassword: false,
      apiErrorLogInForBO: "",
      flashBo: false,
      flashSlack: false,
      flashEmailForBusiness:false,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {

      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      
        
      if (sessionData) {

        
        this.setState({ flashEmailForBusiness: sessionData?.flashEmailForBusiness }, () => {
        })
      }

      return;
    }
    // Customizable Area End
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      // Customizable Area Start
      
    // Customizable Area End
      return;
    }
    // Customizable Area Start

   
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.loginCallId) {
      this.handleResForSignUp(responseJson);
    }

    // Customizable Area End
  }
  // Customizable Area Start
  
  handleResForSignUp = (responseJson: LogInResponseForBO) => {
    if (responseJson.meta) {
      if(!this.state.isCheckboxChecked) {
        sessionStorage.setItem("randomBusiessKey", "myBusinessToken")
      }
      if (responseJson.data.attributes.type === "business_owner") {
        this.callApiForOldData(responseJson.meta.token)
        localStorage.setItem("authToken", responseJson.meta.token);
        localStorage.setItem("profileIdBo", (responseJson.data.id))
        localStorage.setItem('userType', "BO");
       setStorageData('accountDetails',JSON.stringify(responseJson.data.attributes))
        setTimeout(() => {
          if(responseJson.data.attributes.is_hotel_created){
            this.props.navigation.navigate("PropertyDetails")

          }else{
            this.props.navigation.navigate("ContentManagementBo")

          }

        },1000
        )

        this.setState({ apiErrorLogInForBO: "" });
        this.setState({ email: "", password: "", flashSlack: true });

      } else {
        this.setState({
          apiErrorLogInForBO: "Email is invalid",
        });
      }
    } else if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].failed_login) {
        this.setState({
          apiErrorLogInForBO: responseJson.errors[0].failed_login,
        });
      }
    }
  };

  encryptData = (credentialsData: string) => {
    const encryptedData = window.btoa(credentialsData);
    return encryptedData
  };

  decryptData = (credentialsData: string) => {
    const decryptedData = window.atob(credentialsData);
    return decryptedData
  };

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const flashValueBO = urlParams.get('flash');
    if (flashValueBO) {
      this.setState({ flashBo: true })
    }

    const userCred = await getStorageData("userBusinessCred")
    const userCredDecrypt = this.decryptData(userCred)
    if(userCredDecrypt) {
      this.setState({
        email: JSON.parse(userCredDecrypt).email,
        password: JSON.parse(userCredDecrypt).password,
        isCheckboxChecked:true
      })
    }
  }

  handlePasswordToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  handleCloseBo = () => {
    this.setState({ flashBo: false, flashSlack: false,flashEmailForBusiness:false })
  }
  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };
  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      isCheckboxChecked: !prevState.isCheckboxChecked,
    }));
  };

  getEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
    });
  };

  callApiForBoLogIn =  async(event: any) => {
    event.preventDefault();
    if(this.state.isCheckboxChecked) {
      await setStorageData("userBusinessCred", this.encryptData(JSON.stringify({email: this.state.email, password: this.state.password})))
    }
    else {
      removeStorageData('userBusinessCred')
    }
    const loginHeader = {
      "Content-Type": "application/json",
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginCallId = loginRequestMessage.messageId;
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_login/logins`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            email: this.state.email,
            password: this.state.password,
          },
          type: "business_owner"
        },
      })
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };

  callApiForOldData = (tokenValue: string) => {
    const headers = {
      token: tokenValue,
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileId = loginRequestMessage.messageId;
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_details`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };
  // Customizable Area End
}
