import React from "react";
// Customizable Area Start
import { Container, Grid, Theme, Typography, createStyles, withStyles } from "@material-ui/core";
import {
  backIcon,
  PhotoLibrery
} from "./assets";
import { colors } from "../../../components/src/theme";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
// Customizable Area End
import AllPhotosController, { Images, Props } from "./AllPhotosController.web";

export class AllPhotos extends AllPhotosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { classes } = this.props
    const coverPhotoMain = this.state.allPhotos.find(image => image.is_cover_image);
  const remainingPhotos = this.state.allPhotos.filter(image => image !== coverPhotoMain);
    return (
      //Merge Engine DefaultContainer
      <>
        <PetOwnerNavigationMenu  navType="fullNavbar"  navigation={this.props.navigation} id=""/>
        <Container maxWidth={false} className={classes.photosLayout}>
          <Grid item xs={12} className={classes.layout} >
            <Grid container direction="row" spacing={3} className={classes.layoutColor}>
              <Grid item xs={12} style={{ marginTop: '30px' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <img
                          src={backIcon}
                          alt="back"
                          onClick={this.backNavigation}
                          className={classes.backIcon}
                          data-test-id="backIcon"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" className={classes.lengthtext} >
                          Back
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <img
                          src={PhotoLibrery}
                          alt="PhotoLibrery"
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" className={classes.lengthtext}>
                          {this.state.allPhotos && this.state.allPhotos.length}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} style={{ gap: 10 }} >
                  <Grid item xs={12} className={classes.bigImage}>
                  <img src={coverPhotoMain?.url} className={classes.imageGridSize} />

                  </Grid>
                  {remainingPhotos.map((image: Images, index: number) => {
                    return (
                      <>
                        <Grid item xs={12} sm={ 6}
                          key={index} className={classes.smallImages}>
                          <img src={image?.url} className={classes.imageGridSize} />

                        </Grid>
                      </>
                    )
                  })}
                </Grid>

              </Grid>


            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.footerLayout}>
            <Footer />
          </Grid>
        </Container>

      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

export const AllPhotosStyles = (theme: Theme) =>
  createStyles({
    photosLayout: {
      backgroundColor: theme.palette.primary.main,
      marginTop: "112px",
      [theme.breakpoints.down('xs')]: {
        marginTop: "90px"
      }
    },
    layout: {
      padding: "0 60px"
    },
    layoutColor: {
      backgroundColor: "#ffffff",
      padding:8
    },
    lengthtext: {
      color: colors.darkBlack,
      fontWeight: theme.typography.fontWeightBold
    },
    gridFirstImages: {
      width: "100%",
      second: "650px",
      borderRadius: "15px 15px 0px 0px"
    },
    gridSecondImages: {
      width: "100%",
      height: "448px",
      objectFit: "cover"
    },
    footerLayout: {
      marginTop: "120px"
    },
    backIcon: {
      cursor: "pointer"
    },
    bigImage: {
      position: 'relative',
      height: "450px",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: theme.palette.primary.main,

    },
    smallImages: {
      position: 'relative',
      height: "450px",
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      width: '49.5%',
      maxWidth: "49.5% !important"

    },

    imageGridSize: {

      maxWidth: '100%',
      maxHeight: '450px',
      borderRadius: 8,
    },
  })
export default withStyles(AllPhotosStyles)(AllPhotos);

// Customizable Area End
