import React from "react";
// Customizable Area Start
import {
    Grid, Typography, Container, Box, Divider
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { boxShadows } from "../../../components/src/theme";
import CategoriessubcategoriesPO from "../../categoriessubcategories/src/CategoriesPO.web";
import HotelsCatalogueController, { Props } from "./HotelsCatalogueController.web";
import Loader from "../../../components/src/Loader.web";
import { Pagination } from "@material-ui/lab";
import LandingPageSearch from "../../landingpage/src/LandingPageSearch.web";
import { dummyImage, markerIconPng, locationSymbol } from "./assets";
import Footer from '../../../components/src/Footer.web'
import CustomRating, { MyMapBox } from "../../../components/src/CustomizedComponents.web";
import { errorImage } from "../../../components/src/CommonFunctions";
import { HotelDetailsData } from "./types";
import MapMarker from "../../../components/src/MapMarker";
import { MapContainer, TileLayer } from "react-leaflet";
export const configJSON = require("./config");
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
// Customizable Area End


// Customizable Area Start
// Customizable Area End



export class HotelsCatalogue extends HotelsCatalogueController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    catalogueHotelsList = () => {
        const { classes } = this.props



        return (
            <>
                <Grid item xs={12}>
                    {this.state.hotelsList && this.state.hotelsList.map((hotel: HotelDetailsData) => {
                        let coverPhoto = hotel.attributes.images.find((image: { is_cover_image: boolean; }) => image.is_cover_image);

                        // If there is no cover photo, use the first photo as cover
                        if (!coverPhoto && hotel.attributes.images.length > 0) {
                            coverPhoto = hotel.attributes.images[0];
                        }
                        return (
                            <Grid container direction="row" spacing={2} justifyContent="flex-start" key={hotel.id} className={classes.petlistLayout}>
                                <Grid item xs={12} sm={this.state.mapCheckStatus ? 12 : 4} xl={3} className={classes.imageBg}>
                                    <img src={coverPhoto?.url || dummyImage} data-test-id='errorImage' onError={errorImage} alt="pet" className={classes.petImage} />
                                </Grid>
                                <Grid item xs={12} sm={this.state.mapCheckStatus ? 12 : 8} xl={9}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} >
                                            <Grid container justifyContent="space-between">
                                                <Grid item xs={12} sm={6} md={8}>
                                                    <Box>
                                                        <Typography variant="h6" className={classes.priceText}>{hotel.attributes.name}</Typography>
                                                        <Grid container alignItems="center">
                                                            <LocationOnOutlinedIcon />
                                                            <Typography display="inline" variant="body2" color="textSecondary" className={classes.sortText}>{hotel.attributes.hotel_city},&nbsp;{hotel.attributes.hotel_country_region}</Typography>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} container justifyContent="flex-end">
                                                    <Box>
                                                        <CustomRating rating={hotel.attributes?.rating} />
                                                        <Grid container alignItems="center" justifyContent="space-between" className={classes.ratingReviewLayout}>
                                                            <Grid item>
                                                                <Typography display="inline" variant="body2" color="textSecondary" className={classes.sortText}>{hotel.attributes.rating || 0}</Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography display="inline" variant="body2" color="textSecondary" className={classes.sortText}>{hotel?.attributes?.reviews_count}&nbsp;{'reviews'}</Typography>

                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="textSecondary" className={classes.descriptionlayout}>
                                                {hotel.attributes.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider className={classes.dividerColor} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-between">
                                                <Grid item xs={6}>
                                                    <Box>
                                                        <Typography variant="h6" className={classes.priceText}>£{this.getPrice(hotel) || 0}</Typography>
                                                        <Typography variant="body2" color="textSecondary" className={classes.dayText}>Starting from Per Day</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} container justifyContent="flex-end">
                                                    <Box className={classes.availabilityBtn} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <Typography variant="body1" color="secondary" data-test-id="test1" className={classes.availabilityBtnText} onClick={() => this.seeHotelDetails(hotel.id)}>See Availability</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>)
                    })}

                    {this.state.hotelsList.length === 0 &&

                        <Grid item xs={12} container justifyContent="center" alignItems="center">
                            <Typography variant="h3" className={classes.noHotelFoudText}>No hotels found</Typography>
                        </Grid>

                    }
                </Grid>
            </>
        )
    }

    renderMap = (classes: ClassNameMap<string>) => {
        return (<MyMapBox data-test-id="mapBtnTestID">
            {this.state.hotelsList.length>0 ? <MapContainer
                zoomControl={false}
                center={this.state.coords}
                zoom={25}
                className="mapContainer"
                data-test-id="mapBtnsTestID"
            >
                {
                    this.state.hotelsList.map((hotel: HotelDetailsData) => (
                        <>
                            <TileLayer
                                url={configJSON.url}
                            />
                            {!!this.getPosition(hotel) &&
                                <MapMarker
                                    markerIconPng={markerIconPng}
                                    coords={[hotel.attributes.latitude, hotel.attributes.longitude]}
                                    locationIcon={locationSymbol}
                                    classes={classes}
                                    hotelData={hotel}
                                />
                            }
                        </>
                    ))
                }

            </MapContainer>: 
                <MapContainer
                    zoomControl={false}
                    center={this.state.coords}
                    zoom={25}
                    className="mapContainer"
                    data-test-id="mapBtnsTestID"
                >
                    <TileLayer url={configJSON.url}/>
                </MapContainer>
            }
        </MyMapBox>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <>
                <PetOwnerNavigationMenu navType="fullNavbar" activeLink={this.state.isDayCareService?7:0} navigation={this.props.navigation} id="" />
                <Container maxWidth={false} className={classes.hotelLayout}>
                    <Grid item xs={12} >
                        <Grid container direction="row" justifyContent="flex-start" spacing={2} >
                            <Grid item xs={12} className={classes.landingPageLayout}>
                                <Container maxWidth={false} className={classes.searchContainer}>

                                    <LandingPageSearch
                                        data-test-id="landingpage"
                                        navigation={this.props.navigation}
                                        id={""}
                                        searchType={'catalogue'}
                                        hotelsList={this.hotelsList}
                                        searchFilterPage={this.searchFilterPage}
                                    />
                                </Container>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} style={{ padding: "20 !important" }}>
                                <CategoriessubcategoriesPO
                                    getApiFiltersEndPoint={this.getApiFiltersEndPoint}
                                    navigation={undefined}
                                    id={""} classes={{}}
                                />
                            </Grid>
                            <Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
                                <Grid container direction="row" justifyContent="flex-start" spacing={3}>
                                    <Grid item xs={12} >
                                        <Grid container direction="row" spacing={2}>
                                            {!this.state.setMap ?
                                                <>
                                                    <Grid item xs={12} sm={this.state.mapCheckStatus ? 4 : 12}>
                                                        {this.catalogueHotelsList()}

                                                    </Grid>
                                                    {this.state.mapCheckStatus &&
                                                        <Grid item xs={12} sm={this.state.mapCheckStatus && 8} >
                                                        </Grid>
                                                    }
                                                    {
                                                        !!this.getHotelData() &&
                                                        <Grid item xs={12} container justifyContent="center" className={classes.paginationRoot}>
                                                            <Pagination data-test-id='change_page' count={this.state.totalPageCount} page={this.state.page} onChange={this.handlePagination}/>
                                                        </Grid>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <Grid item xs={12}>
                                                        {this.renderMap(classes)}
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.footerLayout}>
                            <Footer color={'#E0F4FC'} footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData}/>
                        </Grid>
                    </Grid>
                    <Loader loading={this.state.hotelListLoader} />
                </Container>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export const HotelsCatalogueStyles = (theme: Theme) =>
    createStyles({
        landingPageLayout: {
            [theme.breakpoints.down('xs')]: {
                padding: "20px !important"

            }
        },
        textDecoration: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.black,
            textDecoration: 'underline'
        },
        petlistLayout: {
            boxShadow: boxShadows.whiteShadow,
            marginBottom: 30,
            backgroundColor: theme.palette.common.white,
            borderRadius: 8

        },
        availabilityBtn: {
            width: "145px",
            height: "50px",
            borderRadius: 8,
            backgroundColor: "#E0F4FC",
            cursor: "pointer",
            [theme.breakpoints.down('sm')]: {
                width: "100%"
            }

        },
        availabilityBtnText: {
            textDecoration: 'underline',
            fontWeight: theme.typography.fontWeightBold,
            ' &:hover': {
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.common.black,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 12
            }
        },
        priceText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        },
        position: {
            position: "relative",
            boxShadow: boxShadows.blueShadow
        },
        sortText: {
            fontWeight: theme.typography.fontWeightRegular,
        },
        ratingText: {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            textAlign: "left",
            color: "rgba(71, 85, 105, 1)"
        },
        dayText: {
            fontWeight: theme.typography.fontWeightRegular,
            [theme.breakpoints.down('sm')]: {
                fontSize: 10
            }
        },
        hotelLayout: {
            backgroundColor: theme.palette.primary.main,
            marginTop: 170,
            position: 'relative',

        },
        dividerColor: {
            color: theme.palette.primary.main
        },
        footerLayout: {
            marginTop: '150px'
        },
        paginationRoot: {
            marginBottom: '100px',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        petImage: {
            maxWidth: '100%',
            maxHeight: "216px",
            borderRadius: 4,
            [theme.breakpoints.up('sm')]: {
                maxWidth: "100%"
            },
            [theme.breakpoints.down('sm')]: {
                height: "100%",
                maxWidth: '100%',

            }

        },
        ratingReviewLayout: {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem'
        },
        descriptionlayout: {
            fontWeight: theme.typography.fontWeightRegular,
            display: '-webkit-box',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            minHeight: 46,
        },
        noHotelFoudText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightMedium
        },
        searchContainer: {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            position: "relative",
            top: "-50%",
            [theme.breakpoints.down('xs')]: {
                top: 0,

            }

        },
        imageBg: {
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.primary.main,
            borderRadius: 8,
            justifyContent: 'center',
            height: '216px'

        }

    })
export default withStyles(HotelsCatalogueStyles)(HotelsCatalogue);
// Customizable Area End