import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { DateObject } from "react-multi-date-picker";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { HotelDetailsData } from "./types";

export interface RatingValues {
  id: number,
  value: number,
  icon: string,
  activeStatus: boolean
}
export interface Markers {
  id: number,
  position: [number, number],
  popupText: string
};

export interface Hotel {
  id: string,
  type: string,
  attributes: {
    latitude: number,
    longitude: number
  }
};
interface LinkObject {
  url: string;
  title: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  petMenuOpen: boolean;
  anchorElPet: HTMLButtonElement | null;
  addRoomCat: number;
  addRoomDog: number;
  checkInOut: any,
  inputRange: string
  inOutAnchorElPet: HTMLButtonElement | null;
  selectedRange: DateObject[];
  anchorEl: HTMLButtonElement | null;
  menuOpen: boolean
  locationStatus: boolean;
  locationName: string;
  locationList: any;
  locationPopupStatus: boolean;
  mapCheckStatus: boolean;
  hotelsList: any
  hotelsLoading: boolean;
  selectedSortedTypeFilter: string;
  filterParams: string;
  hotelListLoader: boolean
  page: number,
  totalPageCount: number,
  setMap: boolean;
  coords: [number, number];
  markers: Array<Markers>;
  openModal: boolean;
  selectedHotel: HotelDetailsData;
  socialLinksData: { icons: string, navigationUrl: string }[];
  poBOLinksData: LinkObject[];
  isDayCareService: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HotelsCatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIHotelsList: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SelectMap),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      petMenuOpen: false,
      anchorElPet: null,
      addRoomCat: 0,
      addRoomDog: 0,
      checkInOut: false,
      inOutAnchorElPet: null,
      inputRange: "",
      selectedRange: [],
      anchorEl: null,
      menuOpen: false,
      locationStatus: false,
      locationName: "",
      locationList: [],
      locationPopupStatus: false,
      hotelsList: [],
      hotelsLoading: true,
      selectedSortedTypeFilter: "",
      mapCheckStatus: false,
      filterParams: "",
      hotelListLoader: false,
      page: 1,
      totalPageCount: 0,
      setMap: false,
      coords: [51.5074, 0.1272],
      markers: [
        { id: 1, position: [51.505, -0.09], popupText: "Marker 1" },
        { id: 2, position: [51.51, -0.1], popupText: "Marker 2" },
        { id: 3, position: [51.515, -0.12], popupText: "Marker 3" }
      ],
      openModal: false,
      selectedHotel: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          account_id: 0,
          name: "",
          latitude: 0,
          longitude: 0,
          phone_number: "",
          email: "",
          city_town: "",
          postcode: "",
          country_region: "",
          budget_per_day: 0,
          pet_type: "",
          description: "",
          daycare_price_for_dog: 0,
          daycare_price_for_cat: 0,
          property_name: "",
          flat_suite: 0,
          is_active: false,
          cover_image_id: "",
          hotel_additional_services: [],
          "Create your own services": [],
          "Pet type": { dogs: 0, cats: 0 },
          images: [],
          documents: [],
          reviews_count: 0,
          "Types of rooms": [],
          Status: false,
          blocked_off_days: [],
          "How many rooms are at the properties?": { dog: 0 },
          day_care_services: {
            data: []
          }
        }
      },
      socialLinksData: [],
      poBOLinksData: [],
      isDayCareService: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.hotelsList()
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({ socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks", true)
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const tapPaymentCargeId = searchParams.get("dayCareService");
    if (tapPaymentCargeId) {
      this.setState({ isDayCareService: true })
    }
    
    this.setState({ poBOLinksData: allLinksData })


  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.apiCallIHotelsList) {
      if (responseJson && !responseJson.errors) {
        this.setState({
          hotelsList: responseJson?.hotels?.data || [],
          hotelListLoader: false,
          totalPageCount: responseJson?.total_page,
        }, () => {
          const marker: { id: number; position: [number, number]; popupText: string; }[] = [];
          this.state.hotelsList?.forEach((hotel: Hotel, hotelIndex: number) => {
            marker.push({
              id: hotelIndex,
              position: [hotel.attributes.latitude, hotel.attributes.longitude],
              popupText: `Hotel${hotelIndex}`
            })
            this.setState({
              markers: marker
            })
          })
        });
      }
    }
    if (getName(MessageEnum.SelectMap) === message.id) {
      const selectedMap = message.getData(getName(MessageEnum.SelectedMapData));
      if (selectedMap) {
        this.setState({ setMap: true },()=>{
          this.hotelsList();
        });
      } else {
        this.setState({ setMap: false },
         ()=>{this.hotelsList();}
        );
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const selectedDetails = message.getData(getName(MessageEnum.HotelDetails));
     if (selectedDetails.filterType === 'day_care') {
      this.setState({ isDayCareService: true })
  
    }
  }
    
    // Customizable Area End
  }
 

  // Customizable Area Start

  getLocationDetails = (locationDetails: string) => {
    // this.setState({ locationDetails: locationDetails })

  }


  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value }, () => {
      this.hotelsList()
    })

  }
  navigateToScreen = (screenName: string, data: any) => {
    const navigationMessage: Message = new Message(getName(MessageEnum.HotelDetails));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), data);
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage)
  }
  seeHotelDetails = (hotelId: string | number) => {
    setStorageData('selectedHotelId', hotelId)
    setStorageData('pagecomingFrom', 'hotelList')
    this.navigateToScreen("PetDetailsPage", {
      hotelId: hotelId,
      selectedRange: this.state.inputRange,
      cats: this.state.addRoomCat,
      dogs: this.state.addRoomDog
    })
  }

  getApiFiltersEndPoint = (endPoint: string) => {
    this.setState({ filterParams: endPoint, page: 1 }, () => {
      this.hotelsList()
    })
  }

  hotelsList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const searchdata = await getStorageData('serchBarData', true) || {}
    let urlParams = [];

    if (searchdata.locationName) {
      urlParams.push(`address=${searchdata.locationName}`)
    }

    if (searchdata.checkIn && searchdata.checkOut) {
      urlParams.push(`check_in_date=${moment(searchdata.checkIn).format("YYYY-MM-DD")}`);
      urlParams.push(`check_out_date=${moment(searchdata.checkOut).format("YYYY-MM-DD")}`);
    }
    if (searchdata.addRoomCat || searchdata.addRoomDog) {
      if (searchdata.addRoomCat) {
        urlParams.push(`pet_data[cat]=${searchdata.addRoomCat}`);
      }
      if (searchdata.addRoomDog) {
        urlParams.push(`pet_data[dog]=${searchdata.addRoomDog}`);
      }
    }

    if (this.state.filterParams) {
      urlParams.push(this.state.filterParams);
    }

    urlParams.push(`map_view=${this.state.setMap}`)

    // Add the page parameter to the URL
    if(!this.state.setMap){
      urlParams.push(`page=${this.state.page}`);
    }

    const urlParamsString = urlParams.join('&');

    // Construct the final URL
    let hotelsUrl = `${configJSON.hotesListApiEndPoint}?${urlParamsString}`;
    this.setState({
      hotelListLoader: true,
    });
    this.apiCallIHotelsList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      hotelsUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchFilterPage = () => {
    this.setState({ page: 1 });
  }
  getHotelData = () => {
    return this.state.hotelsList && this.state.hotelsList.length > 0;
  };

  getPosition = (hotel: HotelDetailsData) => {
    return hotel.attributes.latitude && hotel.attributes.longitude;
  };

  getPrice = (hotel: HotelDetailsData) => {
    if (hotel.attributes.pet_type === "daycare_services") {
      return hotel.attributes.day_care_services.data[0].attributes.price_for_one_day
    }
    return hotel.attributes.budget_per_day;
  };
  // Customizable Area End
}
