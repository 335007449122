import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { ChangeEvent } from "react";
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { handleNavigation } from "../../../components/src/CommonFunctions";

// Customizable Area Start
interface LinkObject {
  url: string;
  title: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area StartactiveStep: number;
  modelOpen: boolean;
  yourCountry: string;
  activeStep: number;
  standard: string;
  tailored: string;
  standarddata: any;
  hotelID: number;
  prePaymentMethod: string;
  countArry: any[];
  untilCancelReservation: string;
  untilPetOwnerAmend: string;
  holdOnPercentage: string;
  percentageOfPrePayment: string;
  amount: string;
  additionalConditions: string;
  additionalQuestion1: string;
  additionalQuestion2: string;
  additionalQuestion3: string;
  totelTextArea: any[];
  saveDraftWarn: boolean;
  saveDraftWarnSec: boolean;
  amountError: boolean;
  cancellationPolicy: boolean;
  untilCancelReservationErr:string;
  untilPetOwnerAmendErr:string;
  holdOnPercentageErr:string;
  percentageOfPrePaymentErr:string;
  policyId:any
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CancellationPolicySecond extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  cancellationPolicyId: string = "";
  accountDataGetId: string = "";
  profileId: string = "";
  standarddataId: string = "";
  updatePolicyId:string="";
  getPolicyApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      modelOpen: false,
      yourCountry: "",
      activeStep: 1,
      standard: "",
      tailored: "",
      standarddata: "",
      prePaymentMethod: "deposit",
      hotelID: 0,
      countArry: [],
      untilCancelReservation: "",
      untilPetOwnerAmend: "",
      holdOnPercentage: "",
      percentageOfPrePayment: "",
      amount: "",
      additionalConditions: "",
      additionalQuestion1: "",
      additionalQuestion2: "",
      additionalQuestion3: "",
      totelTextArea: ["1", "2", "3"],
      saveDraftWarn: false,
      saveDraftWarnSec: false,
      amountError: false,
      cancellationPolicy: false,
      untilCancelReservationErr:"",
      untilPetOwnerAmendErr:"",
      holdOnPercentageErr:"",
      percentageOfPrePaymentErr:"",
      policyId:"",
      socialLinksData: [],
      poBOLinksData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.HotelDetails));
      if (sessionData) {
        this.setState({ activeStep:sessionData.activeStep,hotelID:sessionData.hotelId}, () => {
          this.getCancellation()
          setStorageData("hotelId", sessionData.hotelId)
        })
      }

    }

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.standarddataId) {
      if (responseJson.data) {
        this.setState({
          standarddata: responseJson.data[0].attributes.description,
        });
      }
    }
    if(apiRequestCallId===this.cancellationPolicyId){
      if (responseJson&&!responseJson.errors) {
        this.setState({policyId:responseJson?.data?.id})
        if(this.state.saveDraftWarn){
          this.props.navigation.navigate("PropertyDetails")
        }
      }
    }

    if(apiRequestCallId===this.updatePolicyId){
     this.handleUpdateResponse(responseJson)
    }

    if (apiRequestCallId === this.getPolicyApiCallId) {
      this.getCancelApiResponce(responseJson);
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getCancelApiResponce=(responseJson:any)=>{
       if(responseJson){
        const cancellationPolicyFirst = responseJson?.data?.attributes
        cancellationPolicyFirst?.cancellation_type==="standard"?
        this.setState({
          standard:cancellationPolicyFirst?.cancellation_type ||"",
          additionalConditions:cancellationPolicyFirst?.additional_condition,
          additionalQuestion1:cancellationPolicyFirst?.additional_question_one,
          additionalQuestion2:cancellationPolicyFirst?.additional_question_two,
          additionalQuestion3:cancellationPolicyFirst?.additional_question_three,
         }):
         this.setState({
        tailored:cancellationPolicyFirst?.cancellation_type || "",
        prePaymentMethod: cancellationPolicyFirst?.charge_option,
        untilCancelReservation: cancellationPolicyFirst?.free_cancellation_days,
        untilPetOwnerAmend: cancellationPolicyFirst?.free_refund_days,

        holdOnPercentage: cancellationPolicyFirst?.cancellation_hold_percent,
        percentageOfPrePayment: cancellationPolicyFirst?.pre_payment_percent,
        amount: cancellationPolicyFirst?.confirm_deposite_amount,

        additionalConditions:cancellationPolicyFirst?.additional_condition,
        additionalQuestion1:cancellationPolicyFirst?.additional_question_one,
        additionalQuestion2:cancellationPolicyFirst?.additional_question_two,
        additionalQuestion3:cancellationPolicyFirst?.additional_question_three,
         })
      }
  }


  handleUpdateResponse = (responseJson:any) => {
    if (responseJson&&!responseJson.errors) {
      if(this.state.saveDraftWarnSec){
       this.props.navigation.navigate("PropertyDetails")
      }else{this.props.navigation.navigate('StripeAccountDetails')}
     }
  }

  async componentDidMount() {

    const hotelId = await getStorageData("hotelId")
    this.setState({
      hotelID: Number(hotelId)
    })
    this.callApiForstandarddata(hotelId);

    await this.firstFormData();
    await this.secondFormData();
    
    for (let i = 1; i <= 30; i++) {
      this.state.countArry.push(i);
    }

    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData})
  }

  handelSelect = (event: any, type: keyof any) => {
    this.setState({
      [type]: event.target.value,
    } as any);
  };


  handleTextArea = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value,
    } as any);
  };

  handleModelClose = () => {
    this.setState({
      modelOpen: false,
    });
  };

  callApiForstandarddata = (hetelId: number) => {
    const cancelHeader = {
      "Content-Type": "application/json",
    };

    const cancelRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    const apiUrl = configJSON.standardPolicyEndPoint

    this.standarddataId = cancelRequestMessage.messageId;
    cancelRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    cancelRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(cancelHeader)
    );

    cancelRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(cancelRequestMessage.id, cancelRequestMessage);
  };

  handleCheckboxChange = () => {
    this.setState({
      standard: "standard",
      tailored: "",
      cancellationPolicy: false,
    });
  };

  handleTailoredCheckboxChange = () => {
    this.setState({
      tailored: "tailored",
      standard: "",
      cancellationPolicy: false,
    });
  };

  checkValidations = () => {
    let isError = false;
    if (( this.state.untilCancelReservation === "" ||this.state.untilPetOwnerAmend === "" )||
    ( !this.state.percentageOfPrePayment &&this.state.tailored === "tailored")) {
      isError = true;
      console.log("@@123444",this.state,isError)
    }
    
    if (isError) {
      this.setState({
        untilCancelReservationErr: "Please select a value from the dropdown",
        untilPetOwnerAmendErr: "Please select a value from the dropdown",
        percentageOfPrePaymentErr: "Please select a value from the dropdown",
      });
    } else {
      this.handelNext();
    }
  };
  


  handelNext = () => {
    if (this.state.activeStep == 1) {
      if (!this.state.standard && !this.state.tailored) {
        this.setState({
          cancellationPolicy: true,

          activeStep: 1,
        });
      } else {
        this.handelPostApi();
        this.setState({
          activeStep: this.state.activeStep + 1,
        });
      }
    } else if (this.state.activeStep == 2) {
      window.scrollTo(0,0);
      this.handelPutApi();
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  handelPostApi = () => {
    let formdata = new FormData();
    let free_cancellation_days=this.state.standard==="standard"?"":this.state.untilCancelReservation
    let percentageOfPrePayment=this.state.standard==="standard"?"":this.state.percentageOfPrePayment
    let free_refund_days=this.state.standard==="standard"?"":this.state.untilPetOwnerAmend


    formdata.append(
      "cancellation_policy[cancellation_type]",
      this.state.standard || this.state.tailored
    );
    formdata.append(
      "cancellation_policy[free_cancellation_days]",
      free_cancellation_days
    );
    formdata.append(
      "cancellation_policy[free_refund_days]",
      free_refund_days
    );
    formdata.append(
      "cancellation_policy[charge_option]",'pre_payment')

    formdata.append(
      "cancellation_policy[pre_payment_percent]",
    percentageOfPrePayment
    );
    
    formdata.append("hotel_id", `${this.state.hotelID}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancellationPolicyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    this.cancellationPolicyId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

    removeStorageData("cancellationPolicyFirst");
    removeStorageData("cancellationPolicySec");
  };

  saveDraftModelOpen = () => {
    this.setState({
      modelOpen: true,
    });
  };

  saveDraftData = () => {
    if (this.state.activeStep === 1) {
      const cancellationPolicyFirst = {
        standard: this.state.standard,
        tailored: this.state.tailored,
        standarddata: this.state.standarddata,
        prePaymentMethod: this.state.prePaymentMethod,
        untilCancelReservation: this.state.untilCancelReservation,
        untilPetOwnerAmend: this.state.untilPetOwnerAmend,
        percentageOfPrePayment: this.state.percentageOfPrePayment,
      };

      this.setState({
        saveDraftWarn: true,
      },()=>
       { if (!this.state.standard && !this.state.tailored) {
          this.setState({
            cancellationPolicy: true,
  
            activeStep: 1,
          })}else if(this.state.tailored){
            this.checkValidations()
          }else{
            this.handelPostApi()
          }
        }
      );

      setStorageData(
        "cancellationPolicyFirst",
        JSON.stringify(cancellationPolicyFirst)
      );
      this.handleModelClose();
    } else if (this.state.activeStep === 2) {
      const cancellationPolicySec = {
        additionalConditions: this.state.additionalConditions,
        additionalQuestion1: this.state.additionalQuestion1,
        additionalQuestion2: this.state.additionalQuestion2,
        additionalQuestion3: this.state.additionalQuestion3,
      };

      this.setState({
        saveDraftWarnSec: true,
      },()=>this.handelPutApi())

      setStorageData(
        "cancellationPolicySec",
        JSON.stringify(cancellationPolicySec)
      );
      this.handleModelClose();
    } else {
      this.handleModelClose();
    }
  };

  firstFormData = async () => {
    const cancellationPolicyFirst = JSON.parse(
      await getStorageData("cancellationPolicyFirst")
    );
    cancellationPolicyFirst &&
      this.setState({
        standard: cancellationPolicyFirst.standard,
        tailored: cancellationPolicyFirst.tailored,
        standarddata: cancellationPolicyFirst.standarddata,
        prePaymentMethod: cancellationPolicyFirst.prePaymentMethod,
        untilCancelReservation: cancellationPolicyFirst.untilCancelReservation,
        untilPetOwnerAmend: cancellationPolicyFirst.untilPetOwnerAmend,
        holdOnPercentage: cancellationPolicyFirst.holdOnPercentage,
        percentageOfPrePayment: cancellationPolicyFirst.percentageOfPrePayment,
        amount: cancellationPolicyFirst.amount,
        
      });
  };

  secondFormData = async () => {
    const cancellationPolicySec = JSON.parse(
      await getStorageData("cancellationPolicySec")
    );
    cancellationPolicySec &&
      this.setState({
        additionalConditions: cancellationPolicySec.additionalConditions,
        additionalQuestion1: cancellationPolicySec.additionalQuestion1,
        additionalQuestion2: cancellationPolicySec.additionalQuestion2,
        additionalQuestion3: cancellationPolicySec.additionalQuestion3,
       
      });
  };

  handelBack = () => {
    if (this.state.activeStep == 1) {
      handleNavigation("BussinessOwnerDetailsThird", { activeStep: 4, hotelId:this.state.hotelID  }, this.send, this.props)
    } else {
      this.setState({
        activeStep: this.state.activeStep - 1,
      });
    }
  };

  addLiMargin = (htmlString: string): string => {

    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    const liElements = tempElement.querySelectorAll('li');
    liElements.forEach((li: HTMLElement) => {
      li.style.margin = '15px 0px'; 
    });
    return tempElement.innerHTML;
  };

  handelPutApi = () => {
    let formdata = new FormData();
    formdata.append(
      "cancellation_policy[additional_condition]",
      this.state.additionalConditions || ""
       
    );
    formdata.append(
      "cancellation_policy[additional_question_one]",
      this.state.additionalQuestion1 || ""
      
    );
    formdata.append(
      "cancellation_policy[additional_question_two]",
      this.state.additionalQuestion2 || ""
    );
    formdata.append(
      "cancellation_policy[additional_question_three]",
      this.state.additionalQuestion3 || ""
    );


    formdata.append("cancellation_policy_id", `${this.state.policyId}`);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancellationPolicyEndPoint}/${this.state.policyId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), 
      JSON.stringify({})
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    this.updatePolicyId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

    removeStorageData("cancellationPolicySec");
    removeStorageData("cancellationPolicyFirst");
  };


  getCancellation = () => {
    const requestMessageCancel = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getPolicyApiCallId = requestMessageCancel.messageId;

    let urlParams = this.state.hotelID;

    requestMessageCancel.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessageCancel.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancellationPolicyEndPoint}?hotel_id=${urlParams}`
    );

    runEngine.sendMessage(requestMessageCancel.id, requestMessageCancel);
  };
  // Customizable Area End
}
