// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleNavigation } from "../../../components/src/CommonFunctions";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface LinkObject {
  url: string;
  title: string;
}
export interface Item {
  data: any;
  id: string;
  type: string;
  attributes: {
    free_cancellation_period:boolean
    id: string;
    order_status: string;
    total_price: number;
    updated_at: string;
    placed_date_time: string;
    created_at: string;
    reservation_service: {
      id: string;
      price: number;
      created_at: string;
      updated_at: string;
      reservation_date: string;
      city: string;
      full_address: string;
      state: string;
      zip_code: number;
      slot_start_time: string;
      service_name: string;
      booking_status: string;
      slot_end_time: string;
    };
    reservation_service_image: string;
  };
}


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface SProps {
  itemsList: any[];
  sortType: string;
  isSortLoaderVisible: boolean;
  buttonstatus: string;
  viewModal: boolean;
  totalDays: any;
  filterdata: any;
  totalcalculationdays: number,
  totalcalculatedogroom: number,
  totalcalculatecatroom: number,
  totalcalculatedogservice: number,
  totalcalculatecatservice: number,
  finaltotalcalculation: number,
  totalcalculation: any,
  historyLoader:boolean;
  checkInDate:any;
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[];
}

interface SSProps {
  ssId: string;
}

export default class ReservationHistoryController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  uniqueSessionRequesterId: string = "";
  authToken: string = "";
  getReservationHistoryApiCallId: string = "";
  sortTypes: string[] = ["Ascending Order", "Descending Order"];
  unsubscribefocuse?: () => void;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      itemsList: [],
      sortType: "Descending Order",
      isSortLoaderVisible: false,
      buttonstatus: "Accepted",
      viewModal: false,
      totalDays: [],
      filterdata: [],
      totalcalculationdays: 0,
      totalcalculatedogroom: 0,
      totalcalculatecatroom: 0,
      totalcalculatedogservice: 0,
      totalcalculatecatservice: 0,
      finaltotalcalculation: 0,
      totalcalculation: [],
      historyLoader:true,
      checkInDate:[],
      socialLinksData: [],
      poBOLinksData: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {

      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      
        
      if (sessionData) {
        this.pendingbutton()
      }

      return;
    }

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );

      if (requesterId === this.uniqueSessionRequesterId) {
        const sessionToken = message.getData(
          getName(MessageEnum.SessionResponseToken)
        );
        this.authToken = sessionToken;
        this.fetchReservationHistory();
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getReservationHistoryApiCallId) {
        this.setState({ itemsList: responseJson }, () => {
          this.dayDifference();
          this.setState({historyLoader:false})
        });
      }
    }

  }

  requestSessionData() {
    const sessionMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = sessionMsg.messageId;
    this.send(sessionMsg);
    return true;
  }

  async componentDidMount() {
    super.componentDidMount();
    this.AcceptButton()
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
      this.setState({socialLinksData: mySocialLinksData });
      const allLinksData = await getStorageData("BOandPOLinks",true)
      this.setState({poBOLinksData: allLinksData})
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    this.unsubscribefocuse && this.unsubscribefocuse();
  }

  fetchReservationHistory = () => {
    const headers = {
      token: localStorage.getItem("authToken")
    };

    const getReservationHistoryListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReservationHistoryApiCallId =
      getReservationHistoryListMsg.messageId;

    getReservationHistoryListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/order_creations?status=${this.state.buttonstatus}`
    );

    getReservationHistoryListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getReservationHistoryListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReservationHistoryApiMethodType
    );

    runEngine.sendMessage(
      getReservationHistoryListMsg.id,
      getReservationHistoryListMsg
    );

    return true;
  };

  doSortingPressed = (item: string) => {
    this.setState({ sortType: item }, () => {
      this.sortById(item);
    });
  };

  sortById = (item?: string) => {
    this.setState({ isSortLoaderVisible: true });
    let arrayData = [...this.state.itemsList];
    arrayData.sort((item1: Item, item2: Item) => {
      if (item === "Descending Order") {
        return Number(item1.id) < Number(item2.id) ? 1 : -1;
      } else {
        return Number(item1.id) < Number(item2.id) ? -1 : 1;
      }
    });
    this.setState({ itemsList: arrayData }, () => {
      this.setState({ isSortLoaderVisible: false });
    });
  };
  handleViewBooking = (item: Item) => {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ReservationsItem"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseNavigationMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseNavigationMessage.addData(getName(MessageEnum.SessionResponseData), {
      data: item,
      type: "booked",
    });
    navigationMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseNavigationMessage
    );
    this.send(navigationMessage);
  };



  

  dateToString=(date:any)=>{
  
   const dateParts = date.split('-');
   const day = dateParts[2];
   const monthIndex = parseInt(dateParts[1]) - 1;
   const year = dateParts[0];
   const months = [
       "Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.",
       "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
   ];
   return `${day} ${months[monthIndex]} ${year}`;
    
  }






  openViewModal = (id: any) => {

  let filterdata2 = this.state.itemsList.filter((row: any) => row.data.id == id)

   

    this.setState({ filterdata: filterdata2 }, () => {
      this.setState({ viewModal: true });
    });


    const checkInDateStr = filterdata2[0].data.attributes.check_in_date;
    const checkOutDateStr = filterdata2[0].data.attributes.check_out_date;

    const checkInDate: any = new Date(checkInDateStr);
    const checkOutDate: any = new Date(checkOutDateStr);

    const timeDifference = checkOutDate - checkInDate 

    const daysDifference: any = timeDifference / (1000 * 60 * 60 * 24);

    this.setState({ totalcalculationdays: daysDifference })

    const selected_room_dog = filterdata2[0].data.attributes.for_dog?.room_type_for_dog[0]?.price || 0
    const selected_room_cat = filterdata2[0].data.attributes.for_cat?.room_type_for_cat[0]?.price || 0

    const dogcount = (filterdata2[0].data.attributes.number_of_dogs)
    const catcount = (filterdata2[0].data.attributes.number_of_cats)

    const selected_service_dog = filterdata2[0].data.attributes.for_dog?.additional_services_for_dog ||[]
    const selected_service_cat = filterdata2[0].data.attributes.for_cat?.additional_services_for_cat ||[]

    const totalcalculatecatroom = selected_room_cat * catcount * daysDifference
    const totalcalculatedogroom = selected_room_dog * dogcount * daysDifference
    let totalcalculatedogservice = 0
    let totalcalculatecatservice = 0


    selected_service_dog.forEach((value: any) => {

      totalcalculatedogservice = totalcalculatedogservice + value.price * dogcount 

    });

    selected_service_cat.forEach((value: any) => {

      totalcalculatecatservice = totalcalculatecatservice + value.price * catcount 

    });

    this.setState({
      finaltotalcalculation: totalcalculatecatroom + totalcalculatecatservice + totalcalculatedogroom + totalcalculatedogservice
    })
  }


  closeModal = () => {
 

  

    this.setState({

      viewModal: false,
      totalcalculatecatroom: 0,
      totalcalculatecatservice: 0,
      totalcalculatedogroom: 0,
      totalcalculatedogservice: 0,
      totalcalculationdays: 0,
      finaltotalcalculation: 0,


    })
  }

  AcceptButton = () => {
    this.setState({ buttonstatus: "accepted", totalDays: [] ,historyLoader:true}, () => {
      this.fetchReservationHistory();

    });
  };

  pendingbutton = () => {
    this.setState({ buttonstatus: "pending", totalDays: [],historyLoader:true }, () => {
      this.fetchReservationHistory();

    });
  };

  pastbutton = () => {
    this.setState({ buttonstatus: "past", totalDays: [] ,historyLoader:true}, () => {
      this.fetchReservationHistory();
    });
  };

  draftbutton = () => {
    this.setState({ buttonstatus: "draft", totalDays: [],historyLoader:true }, () => {
      this.fetchReservationHistory();
    });
  };

  canceledbutton = () => {
    this.setState({ buttonstatus: "cancelled", totalDays: [] ,historyLoader:true}, () => {
      this.fetchReservationHistory();
    });
  };

  ongoingButton = () => {
    this.setState({ buttonstatus: "ongoing", totalDays: [] ,historyLoader:true}, () => {
      this.fetchReservationHistory();
    });
  };

  noShowButton = () => {
    this.setState({ buttonstatus: "no_show", totalDays: [] ,historyLoader:true}, () => {
      this.fetchReservationHistory();
    });
  };


  getWeeks = (checkInDate: { getDay: () => number; }, totalDays: number, selectedDays: string[]) => {
    const dayList = [0, 0, 0, 0, 0, 0, 0];
    const startDay = checkInDate.getDay();
    const totalDay: number = Math.floor(totalDays);
    let date = 0, getday = startDay;
    while (date < totalDay) {
      dayList[getday] += 1;
      getday = (getday + 1) % 7;
      date += 1;
    }

    const daySet: Set<number> = new Set();
    dayList.forEach((mydayFreq: number, mydayIndex: number) => {
        mydayIndex = mydayIndex > 0 ? mydayIndex -1 : 6;
        if (selectedDays.includes(String(mydayIndex))) {
          daySet.add(mydayFreq);
        }
    });
    return Array.from(daySet)[0];
  }

  dayDifference = () => {
    this.setState((prevState) => {
      const updatedTotalDays = this.state.itemsList.map((row, index) => {
        const checkInDateStr = row.data.attributes.check_in_date;
        const checkOutDateStr = row.data.attributes.check_out_date;
  
        const checkInDate:any = new Date(`${checkInDateStr}T00:00:00`);
        const checkOutDate:any = new Date(`${checkOutDateStr}T00:00:00`);


  
        const timeDifference = checkOutDate - checkInDate ;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        if(row.data.attributes.hotel_information.pet_type === "daycare_services"){
          if(row.data.attributes.day_care_service_type === "One_Time"){
            return row.data.attributes.days.length;
          } else {
            return row.data.attributes.days.length * this.getWeeks(checkInDate, daysDifference, row.data.attributes.days);
          }
        }
        return daysDifference;
      });
  
     
      this.setState({
        totalDays: updatedTotalDays,
      });
    });
   
    
  };


  onReservation=(rowid:any)=>{
    setStorageData("orderId",rowid);
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Reservations'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selecteddataId: rowid})
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  seePoChat=(chat_id:number)=>{
    handleNavigation('Chat',{chatId:chat_id,page:'reservationsPO'},this.send,this.props)
  }

  getPrice = (price: string, stripeAmount: number) =>{
      return "£"+(Number(price?.split("£")[1]) + stripeAmount).toFixed(2);
  };

  getTotalPrice = (price: string, stripeAmount: number) => {
    return "£" + (Number(price.split("£")[1]) + stripeAmount).toFixed(2);
  };

  
}

// Customizable Area End
