import React from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Typography, InputAdornment, TextField, withStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Alert from "@material-ui/lab/Alert/Alert";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
// Customizable Area End

import ForgotPasswordBusinessOwnerController, {
  Props,
} from "./ForgotPasswordBusinessOwnerController.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class ForgotPasswordBusinessOwner extends ForgotPasswordBusinessOwnerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      showYourPassword,
      showPassword,
      setPassword,
      setConfirmPassword,
      forgotPopUp,
    } = this.state;
    const emailId = localStorage.getItem("emailBo");
    const passwordValidationBo = this.validatePasswordBo();
    const getEmailSignForm = () => {
      return (
        <>
        <PetOwnerNavigationMenu  navType="auth" authContent="Bo" navigation={this.props.navigation} id="" />
        <Grid
          item
          lg={3}
          style={formstyle.getEmailFn as React.CSSProperties}
          xs={12}
        >
          <Typography
            variant="h5"
            style={formstyle.head as React.CSSProperties}
          >
            Set New password
          </Typography>
          <Typography style={formstyle.subHeadTypo as React.CSSProperties}>
            Enter a new password for {this.state.email}
          </Typography>
          <Grid>
            <label style={formstyle.labels as React.CSSProperties}>
              New Password
            </label>
            <br />
            <CssTextField
              type={showYourPassword ? "text" : "password"}
              data-test-id="getEmail"
              value={setConfirmPassword}
              placeholder="   Your password"
              variant="outlined"
              onChange={this.getConfirmPassword}
              style={formstyle.inputbox as React.CSSProperties}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={this.handleYourPasswordToggle}
                  >
                    {this.state.showYourPassword ? (
                      <VisibilityOutlinedIcon
                        style={{ cursor: "pointer", color: "#94A3B8" }}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        style={{ color: "#94A3B8", cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <label style={formstyle.labels as React.CSSProperties}>
              Confirm Password
            </label>
            <br />
            <CssTextField
              type={showPassword ? "text" : "password"}
              style={formstyle.inputbox as React.CSSProperties}
              variant="outlined"
              value={setPassword}
              placeholder="   Your password"
              data-test-id="password_change"
              onChange={this.handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={this.handlePasswordToggle}
                    position="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon
                        style={{ cursor: "pointer", color: "#94A3B8" }}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        style={{ color: "#94A3B8", cursor: "pointer" }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
             <div>
                {[
                  {
                    isValid: passwordValidationBo.isLowerCaseLetter,
                    message: "At least one lowercase letter",
                  },
                  {
                    isValid: passwordValidationBo.isCapitalLetter,
                    message: "At least one capital letter",
                  },
                  {
                    isValid: passwordValidationBo.isMinLength,
                    message: "Minimum character length is 8 characters",
                  },
                  {
                    isValid: passwordValidationBo.isNumber,
                    message: "At least one number",
                  },
                  {
                    isValid: passwordValidationBo.isSpecialChracter,
                    message: "At least one special character"
                  }
                  
                ].map((validation, id) => (
                  <Grid
                   key={id}
                    style={{
                      alignItems: "center",
                      marginTop: "7px",
                      display: "flex",
                    }}
          
                  >
                    {validation.isValid ? (
                      <CheckCircleIcon
                        style={{ marginRight: "5px",color: "#34D399"  }}
                      />
                    ) : (
                      <RemoveCircleIcon
                        style={{  marginRight: "5px",color: "#DC2626" }}
                      />
                    )}
                    <span style={formstyle.errorMsgs}>{validation.message}</span>
                  </Grid>
                ))} 
                </div>  
            {this.state.apiErrorLogInForBO && (
              <Typography color="error" style={{ padding: "10px" }}>
                {this.state.apiErrorLogInForBO}
              </Typography>
            )}
            <br />
            <button
              style={{
                ...(formstyle.button as React.CSSProperties),
                backgroundColor: "#EA0C78",
                color: "#ffffff",
              }}
              onClick={this.callApiForBoLogIn}
              data-test-id="handle_continue_click"
            >
              Set New Password
            </button>
          </Grid>
        </Grid>
        </>
      );
    };

    const getCurrecForm = () => {
      return getEmailSignForm();
    };

    return (
      <>
      <CustomizedSnackbars
            message={' Password Updated Sucessfully'}
            toasterType="success"
            duration={20000}
            openToaster={forgotPopUp}
             closeStatus={this.closeFpassBo}
             />

        <div style={{ padding: "30px", paddingTop:"0px",marginTop:'110px'}}>
          <Grid container lg={12} style={formstyle.gridContiners as React.CSSProperties}>
            {getCurrecForm()}
          </Grid>
        </div>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CssTextField = withStyles({
  root: {
    
    '& .MuiInput-underline:after': {
      borderBottomColor: '#54A7C9',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius:"8px"
      },
      
      '&.Mui-focused fieldset': {
        borderColor: '#54A7C9',
        borderWidth:"2px",
        borderRadius:"8px"
      },
    },
  },
})(TextField);
const formstyle = {
  errorMsgs:{
    color:"#1E293B",
    fontFamily: "inter",
    fontSize:"12px",
      },
  inputbox: {
    fontSize: "14px",
    borderRadius: "5px",
    width: "100%",
    fontWeight: 400,
    marginTop: "5px",
    marginBottom: "40px",
    height: "40px",
  },
  button: {
    borderRadius: "5px",
    marginBottom: "15px",
    width: "100%",
    marginTop: "5px",
    height: "40px",
    fontSize: "14px",
    border: "none",
    color: "#64748B",
    fontWeight: 700,
    backgroundColor: "#F1F5F9",
    cursor: "pointer",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  labels: {
    fontWeight: 700,
    color: "#334155",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },

  gridStyles: {
    display: "flex",
    height: "720px",
    justifyContent: "center",
    textAlign: "justify",
    flexDirection: "column",
    flexFlow: "wrap",
    borderRadius: "20px",
  },
  getEmailFn: {
    flexDirection: "column",
    padding: "40px",
    minWidth:"430px",
    backgroundColor: "#ffffff",
    height: "auto",
    borderRadius: " 0 0 15px 0",
  },
  head: {
    marginBottom: "40px",
    fontWeight: 400,
    color: "#334155",
    fontSize: "30px",
    mrginTop: "10px",
    textAlign: "left",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
  gridContiners: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "#B2E4F8",
    marginBottom: "10px",
    height: "auto",
    minHeight:"86vh",
    borderRadius: "20px",
    fontFamily:"inter",
    textTransform:"none"
  },
  subHeadTypo: {
    mrginTop: "10px",
    marginBottom: "40px",
    color: "#334155",
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 400,
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
  },
};
// Customizable Area End
