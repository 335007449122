import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";


interface myProps {
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  loaderStyle: {
    zIndex: 1000 + 1,
    color: theme.palette.info.main,
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default function Loader(props: myProps) {
  const classes = useStyles();

  useEffect(() => {
    if (props.loading) {
      // Disable scrolling when the loader is active
      document.body.classList.add('no-scroll');
    } else {
      // Enable scrolling when the loader is inactive
      document.body.classList.remove('no-scroll');
    }
    // Cleanup function to remove the class
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [props.loading]);
  return props.loading ? (
    <Backdrop className={classes.loaderStyle} open={props?.loading} >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <div />
  );
}
