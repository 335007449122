import React from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {
  Typography,
  InputAdornment,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import Alert from "@material-ui/lab/Alert/Alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
const CssTextField = withStyles({
  root: {
    
    '& .MuiInput-underline:after': {
      borderBottomColor: '#54A7C9',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius:"8px"
      },
      
      '&.Mui-focused fieldset': {
        borderColor: '#54A7C9',
        borderWidth:"2px",
        borderRadius:"8px"
      },
    },
  },
})(TextField);
// Customizable Area End

import ForgotPasswordPetOwnerController, {
  Props
} from "./ForgotPasswordPetOwnerController.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class ForgotPasswordPetOwner extends ForgotPasswordPetOwnerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      showPassword,
      showYourPassword,
      newPassword,
      forgotPopUpOwner,
      passwords
    } = this.state;

    const passwordValidation = this.validatePassword();

   

    const buttonOne = () => {
      const isPassWord = (passwords == newPassword) && passwords.length > 7;
      return (
        <button
          onClick={this.callApiForPoLogIn}
          style={{
            ...styles.BtnSignIn as React.CSSProperties,
            backgroundColor: isPassWord?"#EA0C78":"rgb(234 12 120 / 55%)",
            color: "#ffffff",
          }}
          data-test-id="handle_continue_click"
          disabled={!isPassWord}
        >
           Set New Password
        </button>
      );
    };

    return (
      <>
      <PetOwnerNavigationMenu  navType="auth" navigation={this.props.navigation} id="" />
<CustomizedSnackbars
            message={'Password Updated Sucessfully'}
            toasterType="success"
            duration={20000}
            openToaster={forgotPopUpOwner}
            closeStatus={this.closeStatusForgotPassword}
             />
        <div style={styles.divMain as React.CSSProperties}>

      
          <Grid
            container
            xs={12}
            spacing={2}
            lg={12}
          >
            <Grid item style={styles.headerMainGrid as React.CSSProperties}>
              <Grid style={styles.headerCondtionGrid as React.CSSProperties}>
                <Typography
                  variant="h5"
                  style={styles.headTypoText as React.CSSProperties}
                >
                  Set New password
                </Typography>
                <Typography
                  style={styles.subHeadTypo as React.CSSProperties}
                >
                  
                  {`Enter a new password for ${this.state.email}`}
                </Typography>
              
                <label style={styles.labelsText as React.CSSProperties}>
                  New Password
                </label>
                <br />
                <CssTextField
                  style={styles.inputTypoText as React.CSSProperties}
                  variant="outlined"
                  onChange={this.handlePasswordNewChange}
                  type={showYourPassword ? "text" : "password"}
                  placeholder="   Your password"
                  data-test-id="email-change"
                  value={passwords}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={this.handleYourPasswordToggle}
                        position="end"
                      >
                        {this.state.showYourPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ cursor: "pointer", color: "#94A3B8" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <label style={styles.labelsText as React.CSSProperties}>
                  Confirm Password
                </label>
                <br />
                <Typography variant="h1" color="initial" />
                <CssTextField
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  style={styles.inputTypoText as React.CSSProperties}
                  value={newPassword}
                  placeholder="   Your password"
                  onChange={this.handlePasswordChange}
                  data-test-id="password_change"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        data-test-id="togglePassword"
                        onClick={this.handlePasswordToggle}
                        position="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ cursor: "pointer", color: "#94A3B8" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <div>
                {[
                  {
                    isValid: passwordValidation.isCapitalLetter,
                    message: "At least one capital letter",
                  },
                  {
                    isValid: passwordValidation.isLowerCaseLetter,
                    message: "At least one lowercase letter",
                  },
                  {
                    isValid: passwordValidation.isNumber,
                    message: "At least one number",
                  },
                  {
                    isValid: passwordValidation.isMinLength,
                    message: "Minimum character length is 8 characters",
                  },
                  {
                    isValid: passwordValidation.isSpecialChracter,
                    message: "At least one special character"
                  }
                ].map((validation, index) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "7px",
                    }}
                    key={index}
                  >
                    {validation.isValid ? (
                      <CheckCircleIcon
                        style={{ color: "#34D399", marginRight: "5px" }}
                      />
                    ) : (
                      <RemoveCircleIcon
                        style={{ color: "#DC2626", marginRight: "5px" }}
                      />
                    )}
                    <span style={styles.errorMsges}>{validation.message}</span>
                  </div>
                ))} 
                </div>  
                {this.state.apiErrorLogIn && (
                  <Typography color="error" style={{ padding: "10px" }}>
                    {" "}
                    {this.state.apiErrorLogIn}{" "}
                  </Typography>
                )}
                <br />
                {buttonOne()}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>

      // Customizable Area End
    );
  }
}

const styles = {
  // Customizable Area Start
  errorMsges:{
    fontFamily: "inter",
    color:"#1E293B",
    fontSize:"12px",
      },
  navBarDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  BtnSignIn: {
    height: "40px",
    marginBottom: "15px",
    borderRadius: "5px",
    width: "100%",
    fontSize: "14px",
    marginTop: "5px",
    border: "none",
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    color: "#64748B",
    cursor: "pointer",
    font: "inter",
    textTransform: "none",
    fontFamily:"inter"
  },
  inputTypoText: {
    borderRadius: "5px",
    height: "40px",
    fontWeight: 400,
    width: "100%",
    marginTop: "25px",
    marginBottom: "40px",
    fontSize: "14px",
  },

  divMain: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "auto",
    minHeight:"100vh",
    backgroundColor: "#FFFFFF",
    marginTop:'110px'
  },
  labelsText: {
    color: "#334155",
    fontWeight: 700,
    font: "inter",
    textTransform: "none",
    fontFamily:"inter"
  },

  divAction: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  signInBtn: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #64748B",
    color: "#64748B",
  },
  headerMainGrid: {
    display: "flex",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "90%",
    maxWidth: "480px",
  },
  headerCondtionGrid: {
    padding: "30px",
    backgroundColor: "rgba(255, 255, 255)",
    borderBottomRightRadius: "45px",
    width: "100%",
    boxShadow:'rgb(46 49 51 / 9%) 0px -2px 8px 8px'
  },
  headTypoText: {
    fontWeight: 700,
    mrginTop: "10px",
    marginBottom: "30px",
    fontSize: "30px",
    color: "#334155",
    textAlign: "left",
    font: "inter",
    textTransform: "none",
    fontFamily:"inter"
  },
  subHeadTypo: {
    mrginTop: "10px",
    marginBottom: "40px",
    color: "#334155",
    textAlign: "left",
    fontSize:"16px",
    fontWeight: 400,
    font: "inter",
    textTransform: "none",
    fontFamily:"inter"
  },
  accountactionDiv: {
    textAlign: "center",
    fontSize: "16px",
    marginRight: "10px",
  },
  // Customizable Area End
};
