import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { handleNavigation } from "../../../components/src/CommonFunctions";
import { Color } from "@material-ui/lab";
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
export type SubscriptionListAll = SubscriptionList[]

export interface SubscriptionList {
  errors: Error[];
  id: number
  name?: string
  duration?: string
  duration_type: string
  currency?: string
  price?: number
  created_at: string
  updated_at: string
  description: string
  commission_base: boolean
}
export interface UserSubscriptionResponce {
  user_subscription: UserSubscription;
  subscription: Subscription
  change_subscription: ChangeSubscription
  errors?: Error[]
}

export interface Error {
  message?: string
  stripe?:string
}
export interface UserSubscription {
  id: number
  account_id: number
  subscription_id: number
  start_date: string
  end_date: string
  status: string
  current_status: string
  hotel_id: number
  change_subscription: boolean
  change_subscription_id: number
}

export interface Subscription {
  id: number
  name: string
  duration: string
  duration_type: string
  currency: string
  price: number
  created_at: string
  updated_at: string
  description: string
  commission_base: boolean
  transaction_fee?:number
}

export interface ChangeSubscription {
  id: number
  name: string
  duration: string
  duration_type: string
  currency: string
  price: number
  created_at: string
  updated_at: string
  description: string
  commission_base: boolean;
  status:string;
  start_date: string
  end_date: string;
  transaction_fee?:number
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  subscriptionPopUpType: string
  dailogStatus: boolean;
  subscriptionType: string,
  activePaymentStep: number,
  paymentConfirmationStatus: boolean,
  subscriptionList: SubscriptionList[],
  descriptionDialog: boolean;
  description: string,
  selectedSubscriptionId: number,
  hotelId: number,
  selectedSubscription: SubscriptionList;
  pageLoader: boolean,
  toasterType: Color,
  toasterStatus: boolean;
  toasterMessage: string;
  userSubscription: UserSubscription;
  userSubscriptionDetails: Subscription;
  errorMessage: string | null;
  changeSubscription: ChangeSubscription


  changePlanStatus: boolean;
  pageComingFrom: string;
  termsAndCondiitionsUrl:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetSubscriptionList: string = "";
  apiCallIdCreateSubscription: string = "";
  apiCallIdGetUserSubscriptionDetails: string = "";
  apiCallIdCacelSubscription: string = "";
  apiCallIdChangePlanSubscription: string = "";
  apiCallIdGetStripeTermsAndConditions:string=""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start

      subscriptionPopUpType: "",
      dailogStatus: false,
      subscriptionType: "",
      activePaymentStep: 1,
      paymentConfirmationStatus: false,
      subscriptionList: [],
      descriptionDialog: false,
      description: "",
      selectedSubscriptionId: 0,
      hotelId: 0,
      selectedSubscription: {} as SubscriptionList,
      pageLoader: false,
      toasterType: "success",
      toasterStatus: false,
      toasterMessage: "",
      userSubscription: {} as UserSubscription,
      userSubscriptionDetails: {} as Subscription,
      changePlanStatus: false,
      pageComingFrom: "",
      errorMessage: null,
      changeSubscription: {} as ChangeSubscription,
      termsAndCondiitionsUrl:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.HotelDetails));
      if (sessionData) {
        this.setState({ selectedSubscription: sessionData.data, changePlanStatus: sessionData.changePlanStatus, })
        if (sessionData.pageComingFrom === 'propertyDetails')
          this.setState({ activePaymentStep: sessionData.activeStep, pageComingFrom: sessionData.pageComingFrom, hotelId: sessionData.hotelId })
      }
      return;
    }

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    switch (apiRequestCallId) {
      case this.apiCallIdGetSubscriptionList:
        this.apiResponceGetSubscription(responseJson)
        break;
      case this.apiCallIdCreateSubscription:
        this.apiResponceCreateSubscription(responseJson)
        break;
      case this.apiCallIdGetUserSubscriptionDetails:
        this.apiResponceUserSubscription(responseJson)
        break;
      case this.apiCallIdCacelSubscription:
        this.apiResponceCancelSubscription(responseJson)
        break;
      case this.apiCallIdChangePlanSubscription:
        this.apiResponceChangePlanSubscription(responseJson)
        break;
      case this.apiCallIdGetStripeTermsAndConditions:
        if (responseJson) {
          this.setState({ termsAndCondiitionsUrl:responseJson?.data?.stripe_terms_and_conditions_link })
        }
        break;

    }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const hotel_id = await getStorageData('hotel_id') || await getStorageData('BoHotelId')
    this.setState({ hotelId: hotel_id })
    this.fetchSubscriptionsList();
    this.getStripeTermsAndConditions()
    const url = new URL(window.location.href);
    const searchParams = url.pathname;
    if (searchParams === '/SingleProperty') {
      this.getUserSubscription()

    }

  }

  apiResponceGetSubscription = (responseJson: SubscriptionListAll) => {
    if (responseJson) {
      this.setState({ subscriptionList: responseJson, pageLoader: false })
    }
  }
  apiResponceCreateSubscription = (responseJson: UserSubscriptionResponce) => {

    if (responseJson && !responseJson.errors) {
      this.setState({ pageLoader: false })

      if (this.state.subscriptionType === "commission_base") {
        this.setState({
          toasterStatus: true, toasterMessage: "Subscription added to the hotel",
          toasterType: "success"
        }, () => {
          setTimeout(()=>{
            handleNavigation('PropertyDetails', {}, this.send, this.props)
          },1500)
        })
      }
    } else if (responseJson && responseJson.errors) {
      this.setState({ pageLoader: false })
    }
  }

  apiResponceUserSubscription = (responseJson: UserSubscriptionResponce) => {
    if (responseJson && !responseJson.errors && responseJson.user_subscription) {
      this.setState({
        userSubscription: responseJson.user_subscription,
        userSubscriptionDetails: responseJson.subscription,
        changeSubscription: responseJson.change_subscription,
        pageLoader: false
      })
    } else if (responseJson && responseJson.errors) {
      if (responseJson.errors[0].message === "User subscription not found for this hotel") {
        this.setState({
          pageLoader: false

        })
      }
    }
  }

  apiResponceCancelSubscription = (responseJson: UserSubscriptionResponce) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ dailogStatus: false }, () => {
        this.getUserSubscription()
      })
    }
  }

  apiResponceChangePlanSubscription = (responseJson: UserSubscriptionResponce) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ dailogStatus: false }, () => {
        this.getUserSubscription()
      })
    }
  }

  selectSubscription = (subscription: SubscriptionList) => {
    this.setState({ selectedSubscription: subscription, subscriptionType: subscription.duration_type, selectedSubscriptionId: subscription.id })

  }
  handleconfirmPaymentButton = () => {
    const { subscriptionType } = this.state;

    if (!subscriptionType) {
      this.setState({ errorMessage: "Please select a subscription type." });
    } else {
      this.setState({ errorMessage: null });

      if (subscriptionType === "commission_base") {
        this.setState({ activePaymentStep: 1 }, () => {
          this.createSubscription();
        });
      } else {
        this.setState({ activePaymentStep: 2 });
      }
    }


  }
  getPaymentConfirmation = (status: boolean) => {
    this.setState({ paymentConfirmationStatus: status }, () => {
      if (this.state.paymentConfirmationStatus) {
        this.setState({
          toasterMessage: "Payment successful,Subscription added to this hotel",
          toasterType: 'success',
          toasterStatus: true
        }, () => {

          setTimeout(() => {
            if (this.state.pageComingFrom === 'propertyDetails') {
              handleNavigation('SingleProperty', { filterId: 5 }, this.send, this.props)
            } else {
              handleNavigation('PropertyDetails', {}, this.send, this.props)
            }
          }, 1000)
        })
      } else {
        handleNavigation('SubscriptionDetails', {}, this.send, this.props)
      }


    })
  }

  changePlanPaymentStatus = (status: boolean) => {
    if (status) {
      this.setState({
        toasterMessage: "Payment successful,Subscription Plan changed to this hotel",
        toasterType: 'success',
        toasterStatus: true
      }, () => {
        setTimeout(() => {

          handleNavigation('SingleProperty', { filterId: 5 }, this.send, this.props)
        }, 1000)
      })
    } else {
      this.setState({
        toasterMessage: "Payment failed",
        toasterType: 'error',
        toasterStatus: true
      }, () => {
        setTimeout(() => {
          handleNavigation('SingleProperty', { filterId: 5 }, this.send, this.props)
        }, 1000)
      })
    }
  }
  handleSubscriptions = (type: string) => {
    this.setState({ subscriptionPopUpType: type, dailogStatus: true })

  }
  handleCloseSubscriptionDailog = () => {
    this.setState({ dailogStatus: false })
  }
  handleChangePopupPlan = () => {
    this.setState({ subscriptionPopUpType: 'planSelection', dailogStatus: true })

  }
  backSubcription = () => {
    this.setState({ activePaymentStep: 1 })

  }

  opnePlanDescription = (description: string) => {
    this.setState({ descriptionDialog: true, description: description })
  }
  closePlanDescription = () => {
    this.setState({ descriptionDialog: false })
  }

  fetchSubscriptionsList = async () => {
    const header = {
      token: await getStorageData('authToken'),
      "Content-Type": configJSON.subscriptionApiContentType
    };
    this.setState({ pageLoader: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetSubscriptionList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createSubscription = async () => {

    const header = {

      token: await getStorageData('authToken'),
      "Content-Type": configJSON.subscriptionApiContentType
    };
    this.setState({ pageLoader: true });

    const httpBody = {
      "user_subscription": {
        "subscription_id": this.state.selectedSubscriptionId,
        "hotel_id": this.state.hotelId,

      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdCreateSubscription = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserSubscription = async () => {
    const hotelID = await getStorageData('BoHotelId')
    this.setState({pageLoader:true})
    const header = {
      token: await getStorageData('authToken'),
      "Content-Type": configJSON.subscriptionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetUserSubscriptionDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userSubscription}=${hotelID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  cancelSubscription = async () => {
    const hotelID = await getStorageData('BoHotelId')

    const header = {

      token: await getStorageData('authToken'),
      "Content-Type": configJSON.subscriptionApiContentType
    };
    this.setState({ pageLoader: true });

    const httpBody = {
      "user_subscription": {
        "hotel_id": hotelID
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdCacelSubscription = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  changePlanSubscription = async () => {

    const header = {

      token: await getStorageData('authToken'),
      "Content-Type": configJSON.subscriptionApiContentType
    };
    this.setState({ pageLoader: true });
    const httpBody = {
      "user_subscription": {
        "subscription_id": this.state.selectedSubscription?.id,
        "hotel_id": this.state.hotelId
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdChangePlanSubscription = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePlanSubscriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStripeTermsAndConditions =  () => {

    const header = {
      "Content-Type": configJSON.subscriptionApiContentType
    };
    this.setState({ pageLoader: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetStripeTermsAndConditions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stripeTermsAndConditionsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changePlanPyment = async() => {
    let hotelId = await getStorageData('BoHotelId')
    const { subscriptionType } = this.state;
    if (!subscriptionType) {
      this.setState({ errorMessage: "Please select the subscription" });
    } else {
      this.setState({ errorMessage: null });

      if (subscriptionType === "commission_base") {
        this.changePlanSubscription();
      } else {
        this.setState({ dailogStatus: false, changePlanStatus: true }, () => {
          handleNavigation("SubscriptionDetails", {
            data: this.state.selectedSubscription, pageComingFrom: 'propertyDetails',
            activeStep: 2,
            hotelId: hotelId,
            changePlanStatus: this.state.changePlanStatus
          }, this.send, this.props)
        })
      }
    }
  }
  createPlan = async () => {
    let hotelId = await getStorageData('BoHotelId')
    this.setState({ changePlanStatus: false }, () => {
      handleNavigation("SubscriptionDetails", {
        data: this.state.selectedSubscription, pageComingFrom: 'propertyDetails',
        changePlanStatus: this.state.changePlanStatus,
        hotelId: hotelId,
        activeStep: 1
      }, this.send, this.props)
    })
  }





  // Customizable Area End
}
