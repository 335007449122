import React from "react";

// Customizable Area Start
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  Paper,
  Typography,
  RadioGroup,
  Box,
  Divider,
  Menu,
  MenuItem,
  ClickAwayListener,
} from "@material-ui/core";
import { UplodeFile, uploadPetIcon } from "../src/assets";
import { styled, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AddPetDetailsController, { Props, configJSON } from "./AddPetDetailsController.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "react-datepicker/dist/react-datepicker.css"
import CustomDate from "../../../components/src/CustomizeCalender";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
import CustomsettingModal from "../../../components/src/CustomSettingsmodal";
import { DateObject } from "react-multi-date-picker";
import CountryMenu from "../../../components/src/CountryMenu";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import Loader from "../../../components/src/Loader.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { CustomPopper } from "../../../components/src/CustomizedComponents.web";
import { boxShadows, colors, theme } from "../../../components/src/theme";
// Customizable Area End

// Customizable Area Start
const CustomRadio1 = withStyles((theme) => ({
  checked: {},
  root: {
    color: "default",
    "&$checked": { color: "red", },
  },

}))(({ ...props }) => (
  <Radio
    color="default"

    checkedIcon={
      <span
        style={{
          borderRadius: "50%",
          backgroundColor: "white",
          width: 7,
          border: "6px solid #224350",
          display: "inline-block",
          height: 7,
        }}
      />
    }
    disableRipple
    {...props}
    icon={
      <span
        style={{
          borderRadius: "50%",
          border: "1px solid #224350",
          display: "inline-block",
          height: 16,
          width: 16,
        }}
      />
    }
  />
));
// Customizable Area End
export default class EditPetDetails extends AddPetDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderErrorMessage = (fieldName: string): React.ReactNode | undefined => {
    return this.state.error[fieldName + 'Error'] ? (
      <span style={{ color: "red" }}>
        {this.state.MesErr[fieldName]}
      </span>
    ) : null;
  };

  getValError = (fields: string[]): React.ReactNode | undefined => {
    const invalidField = fields.find(
      (field) => this.state.error[`${field}Error`]
    );

    return invalidField ? this.renderErrorMessage(invalidField) : undefined;
  };

  renderEditGenderPopup = () => {
    return (
      <ClickAwayListener onClickAway={this.closeGenderPopUp}>
        <Box style={Group.outerBox as React.CSSProperties}>
          <Box style={{ padding: "0.5rem" }}>
            <MenuItem value="Male" onClick={() => this.handlepetsexclose("Male")}
              data-test-id="male_item"
              style={{ backgroundColor: this.state.sex === "Male" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "33px" }}
            >Male
            </MenuItem>
            <Divider style={{ margin: "2px", backgroundColor: "##F1F5F9" }} />
            <MenuItem value="Female"
              style={{ backgroundColor: this.state.sex === "Miss" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "33px" }}
              data-test-id="female"
              onClick={() => this.handlepetsexclose("Female")}
            >Female
            </MenuItem>
          </Box>
        </Box>
      </ClickAwayListener>

    )
  }

  renderEditPhotoContent = () => {
    return (
      <>
        {this.state.imageUrl ? <img
          style={
            Group.uplodePetPhoto as React.CSSProperties
          }
          src={this.state.imageUrl}
          alt="Upload dog photo"
        /> :
          <Box style={Group.editNotUplodePetPhoto as React.CSSProperties} >
            <img src={uploadPetIcon} />
            <Typography variant="body1" style={Group.editUploadPhotoText as React.CSSProperties}>Upload photo</Typography>
          </Box>
        }
      </>
    )
  }

  renderDogDetails = () => {
    const filter = createFilterOptions();

    return (
      <>
        <Typography
          variant="h4">
          Dog Details
        </Typography>
        <Grid
          style={Group.uplodePhotoCompt}
        >
          <div>
            <input
              accept="image/*"
              id="contained-button-file"
              onChange={this.handleFileImgChange}
              data-test-id="handleFileImgChange"
              type="file"
              style={Group.style2}
              readOnly={this.state.editValue === "View"}
            />
            <label
              htmlFor="contained-button-file">
              <Button
                component="span"
                style={{
                  boxShadow: "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px",
                  padding: "1px 0px",
                  borderRadius: "15px",
                  marginBottom: "5%",
                }}
                disabled={this.state.editValue === "View"}
              >
                {this.renderEditPhotoContent()}
              </Button>
            </label>
          </div>
          {this.getValError(["imageUrl"])}
          <Typography variant="subtitle2" color="error">{this.state.error.imageUrlSizeError}</Typography>

        </Grid>


        <Grid
          style={Group.style5} container >
          <label
            style={Group.label1 as React.CSSProperties}>
            Dog Name*
          </label>
          <br />
          <input
            value={this.state.petName}
            name="petName"
            style={Group.inputStyle}
            type="text"
            placeholder="Enter dog name"
            data-test-id="formDataHandleChange1"
            onChange={this.handleInputChange}
            readOnly={this.state.editValue === "View"}
          ></input>
          {this.getValError(["petName"])}
        </Grid>
        <Grid container
          style={Group.style5}

        >
          <label style={Group.label1 as React.CSSProperties}>
            Breed*
          </label>
          <br />
          <Autocomplete
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  style={Group.twoinputStyle} {...params.inputProps}
                  placeholder="Select dog breed"
                  readOnly={this.state.editValue === "View"}
                />
              </div>
            )}
            disabled={this.state.editValue === 'View'}

            data-test-id="edit_dog_breed"
            onChange={(event, newValue) => {
              this.breedClose(newValue);
            }}
            filterOptions={(options, state) => {
              const filtered = filter(options, state);
              if (filtered.length === 0) {
                return ['Other'];
              }
              return filtered;
            }}
            options={this.state.DogbreedList}
            fullWidth
            noOptionsText={"No breed found"}
            PopperComponent={CustomPopper}
            value={this.state.petBreed}


          />


          {this.getValError(["petBreed"])}
        </Grid>

        {this.state.isOtherBreed === true ? <Grid container style={Group.style5}>
          <label style={Group.label1 as React.CSSProperties}>
            Breed Name*
          </label>
          <br />
          <input
            type="text"
            name="petBreed"
            value={this.state.selectedBreed}
            placeholder="Enter dog breed name"
            style={Group.inputStyle}
            onChange={this.handleInputChange1}
            data-test-id="formDataHandleChange1"
            readOnly={this.state.editValue === "View"}
          >

          </input>
          {this.getValError(["selectedBreed"])}
        </Grid> : null}

        <Grid container
          spacing={2}>
          <Grid item xs={12} lg={6} style={{ position: 'relative' }}>
            <div style={Group.style5}>
              <label style={Group.label1 as React.CSSProperties} > Sex*
              </label>
              <br />
              <Editdatadiv>
                <div
                  onClick={this.openGenderPopup}
                  data-test-id="open_gender"
                  style={
                    {
                      ...Group.countryPhnameselect,
                      marginTop: "5px", width: "96%",
                      cursor: "pointer", borderRadius: "8px", height: "33px", paddingRight: "10px"
                    } as React.CSSProperties}

                >
                  <span
                    style={{
                      ...Group.countrySpan,
                      textTransform: "none"
                    } as React.CSSProperties}>{this.state.sex || "Select dog sex"}</span>
                  <ExpandMoreIcon /></div>
                {this.state.genderPopup &&
                  <>
                    {this.renderEditGenderPopup()}

                  </>}
              </Editdatadiv>

            </div>
            {!this.state.sex && this.getValError(["sex"])}
          </Grid>
          <Grid lg={6}
            item xs={12} >
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Dog Date of Birth*
              </label>
              <br />
              <Box
                style={{ width: "37.5%", marginTop: "5px" }}
              >
                <CustomCalendar
                  style={{ marginTop: "5px", position: "relative" }}
                >
                  <CustomDate
                    value={new DateObject(this.state.dogbirthdate)}
                    onChange={this.handleBirthChange}
                    data-test-id="dogbirth"
                    readOnly={this.state.editValue === "View"}
                  />
                  <CalendarTodayIcon
                    style={{
                      position: "absolute",
                      top: "17%",
                      color: "#4385A1",
                      zIndex: 2,
                      left: "238%"
                    }}
                  />

                </CustomCalendar>
                {this.getValError(["dogbirthdate"])}
              </Box>
            </div>
          </Grid>
        </Grid>

        <Grid style={{ display: "flex", flexWrap: "nowrap" }} container
          spacing={2}>
          <Grid xs={12}
            lg={6} item>
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Colour
              </label>
              <br />
              <input
                name="petColor"
                type="text"
                onChange={this.handleInputChange}
                style={Group.twoinputStyle}
                value={this.state.petColor}
                placeholder="Enter pet colour"
                data-test-id="formDataHandleChange"
                readOnly={this.state.editValue === "View"}
              ></input>
            </div>

          </Grid>
          <Grid item
            lg={6} xs={12}
          >
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Weight (kg)*
              </label>
              <br />
              <input
                value={this.state.petWeight}
                name="petWeight"
                type="number"
                style={Group.twoinputStyle}
                placeholder="Enter pet weight"
                data-test-id="formDataHandleChange"
                onChange={this.handleInputChange}
                readOnly={this.state.editValue === "View"}
              ></input>
            </div>
            {this.getValError([`petWeight`])}
          </Grid>
        </Grid>

        <Grid
          style={Group.style5 as React.CSSProperties}>
          <label
            style={Group.label1 as React.CSSProperties}>
            Spayed / Neutered*
          </label>
          <br />
          <div
            style={{ marginTop: "1%" }}
          >
            <RadioGroup
              value={this.state.selectedValueSpayed}

              name="selectedValueSpayed"
              data-test-id="formDataHandleChange"
              onChange={this.handleInputChange}
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                display: "flex",
                color: "#224350",
                flexDirection: "row",
              }}


            >
              <FormControlLabel
                control={<CustomRadio1 />}
                value="Spayed"
                disabled={this.state.editValue === "View"}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "Spayed"
                          ? 700
                          : 400,
                    }}
                  >
                    Spayed
                  </span>
                }
              />
              <FormControlLabel
                value="Neutered"
                disabled={this.state.editValue === "View"}
                control={<CustomRadio1 />}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "Neutered"
                          ? 700
                          : 400,
                    }}
                  >
                    Neutered
                  </span>
                }
              />
              <FormControlLabel
                value="ChemicallyCastrated"
                disabled={this.state.editValue === "View"}
                control={<CustomRadio1 />}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "ChemicallyCastrated"
                          ? 700
                          : 400,
                    }}
                  >
                    Chemically Castrated
                  </span>
                }
              />
              <FormControlLabel
                value="none"
                control={<CustomRadio1 />}
                disabled={this.state.editValue === "View"}
                label={
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: "inter",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed === "none" ? 700 : 400,
                    }}
                  >
                    None
                  </span>
                }
              />
            </RadioGroup>
            {this.getValError(["selectedValueSpayed"])}
          </div>
        </Grid>
        {this.state.selectedValueSpayed === "ChemicallyCastrated" ? <div style={Group.style5}>
          <label style={Group.label1 as React.CSSProperties}> Last procedure date </label>
          <br />
          <Box
            style={{ width: "37.5%" }}
          >
            <CustomCalendar
              style={{
                marginTop: "5px",
                position: "relative",
                width: "49%"
              }}
            >
              <CustomDate
                value={new DateObject(this.state.proceduredate)}
                onChange={this.handlproceduredate}
                data-test-id="proceduredate"
                readOnly={this.state.editValue === "View"}
              />
              <CalendarTodayIcon
                style={{
                  top: "17%",
                  position: "absolute",
                  left: "238%",
                  zIndex: 2,
                  color: "#4385A1",
                }}
              />
            </CustomCalendar>
          </Box>
          {!this.state.proceduredate && this.getValError(["proceduredate"])}
          {!this.state.proceduredate && this.state.procedureerror ? <span style={{ color: "red" }}> &nbsp; Procedure date should be greater then birth date</span> : null}
        </div> : null}
      </>
    );
  };


  renderCatDetails = () => {
    const filter = createFilterOptions()
    return (
      <>

        <Typography variant="h4">Cat Details</Typography>
        <Grid
          style={Group.uplodePhotoCompt}
        >
          <div>
            <input
              id="contained-button-file"
              accept="image/*"
              data-test-id="handleFileImgChange"
              style={Group.style2}
              type="file"
              onChange={this.handleFileImgChange}
              readOnly={this.state.editValue === "View"}
            />
            <label
              htmlFor="contained-button-file"
            >
              <Button
                component="span"
                style={{
                  padding: "1px 0px",
                  boxShadow:
                    "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px", marginBottom: "5%", borderRadius: "15px"
                }}
                disabled={this.state.editValue === "View"}
              >
                {this.renderEditPhotoContent()}

              </Button>
            </label>
          </div>
          {this.getValError(["imageUrl"])}
          <Typography variant="subtitle2" color="error">{this.state.error.imageUrlSizeError}</Typography>

        </Grid>


        <Grid
          style={Group.style5}
          container
        >
          <label style={Group.label1 as React.CSSProperties}>
            Cat’s Name*
          </label>
          <br />
          <input
            data-test-id="formDataHandleChange"
            type="text"
            name="petName"
            style={Group.inputStyle}
            placeholder="Enter cat's name"
            onChange={this.handleInputChange}
            value={this.state.petName}
            readOnly={this.state.editValue === "View"}
          ></input>
          {this.getValError(["petName"])}
        </Grid>
        <Grid style={Group.style5} container>
          <label style={Group.label1 as React.CSSProperties}>
            Breed*
          </label>
          <br />
          <Autocomplete
            disabled={this.state.editValue === 'View'}
            value={this.state.petBreed}
            data-test-id="edit_cat_breed"
            onChange={(event, newValue) => {
              this.breedClose(newValue);
            }}
            options={this.state.catBreedList}
            fullWidth
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input placeholder="Select cat breed"
                  style={Group.twoinputStyle} {...params.inputProps}
                  readOnly={this.state.editValue === "View"}
                />
              </div>
            )}
            noOptionsText={""}
            PopperComponent={CustomPopper}
            filterOptions={(options, state) => {
              const filtered = filter(options, state);
              if (filtered.length === 0) {
                return ['Other'];
              }
              return filtered;
            }}
          />




          {this.getValError(["petBreed"])}</Grid>



        {
          this.state.isOtherBreed === true ?
            <Grid container style={Group.style5}>
              <label style={Group.label1 as React.CSSProperties}> Breed Name*</label>
              <br />
              <input
                type="text"
                name="petBreed"
                value={this.state.selectedBreed}
                placeholder="Enter cat breed name"
                style={Group.inputStyle}
                onChange={this.handleInputChange1}
                data-test-id="formDataHandleChange1"
                readOnly={this.state.editValue === "View"}
              >

              </input>
              {this.getValError(["selectedBreed"])}
            </Grid> : null}

        <Grid spacing={2} container >
          <Grid lg={6} xs={12} item style={{ position: 'relative' }}>
            <div style={Group.style5}> <label
              style={Group.label1 as React.CSSProperties}
            >
              Sex*
            </label>
              <br />
              <Editdatadiv> <div
                style={{
                  ...Group.countryPhnameselect,
                  marginTop: "5px", width: "96%", cursor: "pointer",
                  borderRadius: "8px", height: "33px", paddingRight: "10px"
                } as React.CSSProperties
                }
                onClick={this.openGenderPopup}
                data-test-id="open_gender"
              >
                <span style={{
                  ...Group.countrySpan,
                  textTransform: "none"
                } as React.CSSProperties}>
                  {this.state.sex || "Select cat sex"}</span>
                <ExpandMoreIcon
                />
              </div>
                {this.state.genderPopup &&
                  <>
                    {this.renderEditGenderPopup()}
                  </>}
              </Editdatadiv>
            </div> {!this.state.sex && this.getValError(["sex"])}
          </Grid>
          <Grid
            item
            lg={6}
            xs={12}
          >
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Cat's Date of Birth*
              </label>
              <br />
              <Box
                style={{ width: "37.5%" }}
              >

                <CustomCalendar style={{ position: "relative" }}>
                  <CustomDate
                    onChange={this.handlecatBirthChange}
                    value={new DateObject(this.state.catbirthdate)}
                    data-test-id="catbirth"
                    readOnly={this.state.editValue === "View"}
                  />
                  <CalendarTodayIcon
                    style={{
                      position: "absolute",
                      top: "17%",
                      color: "#4385A1",
                      zIndex: 2,
                      left: "238%"
                    }}
                  />

                </CustomCalendar>
              </Box>
              {this.getValError(["catbirthdate"])}



            </div>
          </Grid>
        </Grid>

        <Grid spacing={2}
          container
          style={{ display: "flex", flexWrap: "nowrap" }}
        >
          <Grid item lg={6} xs={12} >
            <div style={Group.style5}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Colour
              </label>
              <br />
              <input
                type="text"
                name="petColor"
                value={this.state.petColor}
                onChange={this.handleInputChange}
                placeholder="Enter pet color"
                data-test-id="formDataHandleChange"
                style={Group.twoinputStyle}
                readOnly={this.state.editValue === "View"}
              ></input>
            </div>
          </Grid>
          <Grid xs={12} item lg={6}>
            <div
              style={Group.style5}
            >
              <label style={Group.label1 as React.CSSProperties}>
                Weight (kg)*
              </label>
              <br />
              <input
                type="number"
                name="petWeight"
                value={this.state.petWeight}
                data-test-id="formDataHandleChange"
                placeholder="Enter pet weight"
                onChange={this.handleInputChange}
                style={Group.twoinputStyle}
                readOnly={this.state.editValue === "View"}
              ></input>
            </div>
            {this.getValError([`petWeight`])}
          </Grid>
        </Grid>

        <Grid
          style={Group.style5 as React.CSSProperties}
        >
          <label
            style={Group.label1 as React.CSSProperties}>
            Spayed / Neutered*
          </label>
          <br />
          <div
            style={{ marginTop: "1%" }}
          >
            <RadioGroup
              value={this.state.selectedValueSpayed}
              data-test-id="formDataHandleChange"
              name="selectedValueSpayed"
              onChange={this.handleInputChange}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#224350",
                flexDirection: "row",
              }}
            >
              <FormControlLabel
                value="Spayed"
                control={<CustomRadio1 />}
                disabled={this.state.editValue === "View"}
                label={
                  <span
                    style={{
                      fontFamily: "inter",
                      fontSize: "16px",
                      color: "#0F172A",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "Spayed"
                          ? 700
                          : 400,
                    }}
                  >
                    Yes
                  </span>
                }
              />
              <FormControlLabel
                value="Neutered"
                control={<CustomRadio1 />}
                disabled={this.state.editValue === "View"}
                label={
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: "inter",
                      fontWeight:
                        this.state.selectedValueSpayed ===
                          "Neutered"
                          ? 700
                          : 400,
                      color: "#0F172A",
                    }}
                  >
                    No
                  </span>
                }
              />


            </RadioGroup>
            {this.getValError(["selectedValueSpayed"])}
          </div>
        </Grid>
      </>
    );
  };


  RenderMedicalTreatment1 = () => (
    <Grid>
      <label style={Group.label1 as React.CSSProperties}>
        Medical Treatment*
      </label>
      <RadioGroup
        style={Group.style5}
        name="medicalTreatment"
        value={this.state.medicalTreatment}
        onChange={this.handleInputChange}
        data-test-id="formDataHandleChange"
      >
        <Grid style={Group.style3}>
          <FormControlLabel
            value="true"
            control={<CustomRadio1 />}
            disabled={this.state.editValue === "View"}
            label={
              <span
                style={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  color: "#0F172A",
                  fontWeight:
                    this.state
                      .medicalTreatment === "true"
                      ? 700
                      : 400,
                }}
              >
                Yes
              </span>
            }
          />
          <FormControlLabel
            control={<CustomRadio1 />}
            disabled={this.state.editValue === "View"}
            value="false"
            label={
              <span
                style={{
                  fontSize: "16px",
                  fontFamily: "inter",
                  fontWeight:
                    this.state
                      .medicalTreatment === "no"
                      ? 700
                      : 400,
                  color: "#0F172A",
                }}
              >
                No
              </span>
            }
          />
        </Grid>
      </RadioGroup>
      {this.getValError(["medicalTreatment"])}
    </Grid>
  );


  renderTreatmentDetails1 = () => {

    return (
      <>
        <Grid
          style={Group.style5}
        >
          <textarea
            placeholder=" Is your pet currently on any medication?"
            style={Group.textArea as React.CSSProperties}
            value={this.state.description}
            name="description"
            rows={4}
            data-test-id="formDataHandleChange"
            onChange={this.handleInputChange}
            readOnly={this.state.editValue === "View"}
          ></textarea>
        </Grid>

        <Grid
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
            style={Group.style5}
            lg={6}
          >
            <label style={Group.label1 as React.CSSProperties}
            >
              Vaccination Date*
            </label>
            <br />
            <Box
              style={{
                justifyContent: "space-between",
                width: "37.5%",
                display: "flex",
              }}>

              <CustomCalendar
                style={{
                  width: "100%",
                  marginTop: "5px",
                  position: "relative"
                }}>
                <CustomDate
                  value={new DateObject(this.state.vaccination_date)}
                  onChange={this.handlevaccinedate}
                  data-test-id="vaccinedate"
                  readOnly={this.state.editValue === "View"}
                />
                <CalendarTodayIcon
                  style={{
                    position: "absolute",
                    top: "17%",
                    color: "#4385A1",
                    zIndex: 2,
                    left: "238%"
                  }}
                />

              </CustomCalendar>
            </Box>
            {this.getValError(["vaccination_date"])}
            {this.state.vaccineerror ?
              <Typography style={{ color: "red" }}>Vaccination date should be greater than birthdate</Typography> : null}
          </Grid>
          <Grid
            xs={12}
            item
            style={Group.style5}
            lg={6}
          >
            <div>
              <label style={Group.label1 as React.CSSProperties}
              >
                Vaccination Certificate*
              </label>
              <div
                style={{
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              >
                <input
                  id={"vacc"}
                  type="file"
                  onChange={this.handleFileChangeVac}
                  data-test-id="handleFileChangeVac"
                  style={{ ...Group.style15, backgroundColor: "E0F4FC" }}
                  disabled={this.state.editValue === "View"}
                />
                <label
                  htmlFor="vacc"
                >
                  <div
                    style={{ ...Group.uplodeButton, backgroundColor: "E0F4FC" }}
                    color="primary"
                  >
                    {this.state.uploadedFileName ? (
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          width: "95%",
                          justifyContent: "space-between",
                        }}
                      >


                        <Typography component={'a'} href={this.state.dogVaccinationUrl} target="_blank"
                          style={Group.vaccinationCertificateName as React.CSSProperties}
                        >
                          {this.state.uploadedFileName}
                        </Typography>
                        <img
                          alt="Uplode"
                          src={UplodeFile}
                        />
                      </div>
                    ) : (
                      <div style={Group.style16}>

                        <span
                          style={{ marginLeft: "5px", color: "#000" }}
                        >
                          Upload Certificate
                        </span>
                        <img src={UplodeFile} alt="Uplode" />
                      </div>
                    )}
                  </div>
                </label>
              </div>
            </div>
            {this.getValError(["uploadedFileName"])}
          </Grid>
        </Grid>

        <Grid
          spacing={2}
          container
        >
          <Grid
            xs={12}
            item
            style={Group.style17}
            lg={6}
          >
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Last Flea Treatment Date*
            </label>
            <br />
            <Box
              style={{ width: "37.5%" }}
            >

              <CustomCalendar
                style={{ marginTop: "5px", position: "relative" }}
              >
                <CustomDate
                  onChange={this.handlefleadate}
                  value={new DateObject(this.state.flea_date)}
                  data-test-id="fleadate"
                  readOnly={this.state.editValue === "View"}
                />
                <CalendarTodayIcon
                  style={{
                    position: "absolute",
                    top: "17%",
                    color: "#4385A1",
                    zIndex: 2,
                    left: "238%"
                  }}
                />


              </CustomCalendar>
            </Box>
            {this.getValError(["flea_date"])}
            {this.state.fleaerror ?
              <Typography style={{ color: "red" }}>flea treatment date should be greater than birthdate</Typography> : null}

          </Grid>
          <Grid
            xs={12}
            style={Group.style5}
            item
            lg={6}
          >
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Last Worming Date*
            </label>
            <br />
            <Box
              style={{ width: "37.5%" }}
            >

              <CustomCalendar
                style={{ marginTop: "5px", position: "relative" }}
              >
                <CustomDate
                  value={new DateObject(this.state.worming_date)}
                  data-test-id="wormingdate"
                  readOnly={this.state.editValue === "View"}
                  onChange={this.handlewormingdate} />
                <CalendarTodayIcon
                  style={{
                    position: "absolute",
                    top: "17%",
                    color: "#4385A1",
                    zIndex: 2,
                    left: "238%"
                  }}
                />

              </CustomCalendar>
            </Box>
            {this.state.wormingError ? <Typography style={{ color: "red" }}>Worming treatment date should be greater than birthdate</Typography> : null}

          </Grid>
        </Grid>
      </>
    )

  }






  RenderMedicalNotes = () => {
    return (
      <>

        <Box style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "20px", justifyContent: "space-between" }}>
          <Typography variant="h4">Medical Notes</Typography>
          <Divider style={{ width: "74%", height: "1px", backgroundColor: "#224351", marginLeft: "2%" }} />
        </Box>


        <Grid
          style={Group.medicalNotesGrid}>
          <Grid
            container>
            <Grid
              item
              lg={12}>

              {this.RenderMedicalTreatment1()}
              {this.state.petType === 'Dog' || this.state.petType === 'dog' ? this.renderVaccineCheckbox() : null}

              {this.state.petType === 'Cat' || this.state.petType === 'cat' && this.renderTreatmentDetails1()}

              <Grid
                style={Group.style5}
              >
                <label style={Group.label1 as React.CSSProperties}>
                  Daietary Requirements*
                </label>
                <textarea
                  name="daietary"
                  value={this.state.daietary}
                  style={
                    Group.textArea as React.CSSProperties
                  }
                  data-test-id="formDataHandleChange"
                  rows={4}
                  placeholder=" Describe here"
                  onChange={this.handleInputChange}
                  readOnly={this.state.editValue === "View"}
                ></textarea>
                {this.getValError(["daietary"])}
              </Grid>
              <Grid
                style={Group.style5}
              >
                <label style={Group.label1 as React.CSSProperties}>
                  Behavioral Notes
                </label>
                <textarea
                  name="behavior"
                  style={Group.textArea as React.CSSProperties}
                  onChange={this.handleInputChange}
                  value={this.state.behavior}
                  rows={4}
                  placeholder="Describe here"
                  data-test-id="formDataHandleChange"
                  readOnly={this.state.editValue === "View"}
                ></textarea>
                {this.getValError(["behavior"])}
              </Grid>
            </Grid>
          </Grid>

        </Grid>



      </>
    )
  }
  vaccinationCheckBox = (
    checkBoxID: string,
    checkboxState: any,
    handleCheckboxChange: any,
    typographyText: any,
    vaccinationDate: any,
    handleVaccinationDateChange: any,
    dateError: boolean,
    fileError: boolean
  ) => {
    return (
      <Box style={{
        width: "100%",
        margin: '10px 0'
      }}>
        <Box display={'flex'} alignItems={'center'}>
          <Checkbox
            data-test-id='vaccCheckBox'
            inputProps={{
              "aria-label": "checkbox with default color",
            }}
            color="default"
            style={{ padding: "0px", marginLeft: "5px" }}
            checked={checkboxState}
            onChange={handleCheckboxChange}
            disabled={this.state.editValue === 'View'}
          />
          <Typography variant="body1">{typographyText}</Typography>
        </Box>

        {checkboxState && <Box>

          <Grid spacing={2} container>
            <Grid
              style={Group.style5}
              xs={12}
              lg={6}
              item
            >
              <label style={Group.label1 as React.CSSProperties}>Vaccination Date*</label>
              <br />
              <Box style={{ width: "37.5%", display: "flex", justifyContent: "space-between" }}>

                <CustomCalendar style={{ marginTop: "5px", width: "100%", position: "relative" }}>
                  <CustomDate data-test-id="vaccinedate"
                    readOnly={this.state.editValue === 'View'}
                    value={vaccinationDate}
                    onChange={handleVaccinationDateChange}

                  />
                  <CalendarTodayIcon
                    style={{
                      top: "17%",
                      color: "#4385A1",
                      position: "absolute",
                      zIndex: 2,
                      left: "238%",
                      cursor: "pointer",
                    }}
                  />
                </CustomCalendar>
              </Box>
              {dateError && <Typography style={{ color: "red" }}>{this.state.dateErrorMessage}</Typography>}

            </Grid>
          </Grid>

        </Box>}
      </Box>
    );
  };

  renderEditDogVaccCert = () => {

    let { vaccinationCertificate, dogVaccinationCertName, editValue } = this.state
    return (
      <>
        <Grid
          item
          xs={12}
          lg={6}
          style={Group.style5}
        >

          <div>
            <label
              style={
                Group.label1 as React.CSSProperties
              }
            >
              Vaccination Certificate*
            </label>
            <div
              style={{
                marginTop: "5px",
                cursor: "pointer",
              }}
            >


              <input
                id={"vacc"}
                type="file"
                onChange={this.handleVaccCertificate}
                data-test-id="handleFileChangeVac"
                style={{ ...Group.style15, backgroundColor: "E0F4FC" }}
                disabled={this.state.editValue === "View"}

              />
              <label htmlFor={"vacc"}>
                <div
                  color="primary"
                  style={{ ...Group.uplodeButton, backgroundColor: "E0F4FC" }}
                >

                  {editValue === 'View' &&
                    <div
                      style={{
                        alignItems: "center",
                        width: "95%",
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >

                      <Typography component={'a'} href={this.state.dogVaccinationUrl} target="_blank"
                        style={Group.vaccinationCertificateName as React.CSSProperties}
                      >
                        {dogVaccinationCertName}

                      </Typography>
                    </div>

                  }
                  {editValue === 'Edit' &&
                    <>
                      {vaccinationCertificate ? (
                        <div
                          style={{
                            alignItems: "center",
                            width: "95%",
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap", width: "90%", marginLeft: "5px", color: "#000",
                              overflow: "hidden",
                            }}
                          >
                            {vaccinationCertificate ? vaccinationCertificate.name : "Upload Certificate"}

                          </span>
                          <img
                            src={UplodeFile}
                            alt="Uplode"
                            style={{ flex: "0 0 auto" }}
                          />
                        </div>
                      ) : (
                        <div style={Group.style16}>

                          <Typography component={'a'} href={this.state.dogVaccinationUrl} target="_blank"
                            style={Group.vaccinationCertificateName as React.CSSProperties}
                          >
                            {dogVaccinationCertName}

                          </Typography>
                          <img
                            src={UplodeFile}
                            alt="Uplode"
                            style={{ flex: "0 0 auto" }}
                          />
                        </div>
                      )}

                    </>
                  }


                </div>
              </label>
            </div>
          </div>

        </Grid>
      </>
    )
  }


  renderVaccineCheckbox = () => {
    return (
      <Box style={{
        // width: "76%",
        marginTop: "10px",
        marginBottom: "10px",
      }}>

        <Typography style={{ fontSize: "14px", fontWeight: 700 }}>Please confirm your dog is vaccinated against:</Typography>
        {this.renderEditDogVaccCert()}

        {this.vaccinationCheckBox('pravo', this.state.canineParvo, this.canineParvo, "Canine Parvovirus (a.k.a. Parvo)", this.state.parvoDate, this.parvoDate, this.state.pravoDateError, this.state.pravoFileError)}
        {this.vaccinationCheckBox('infect', this.state.canineInfect, this.canineInfect, "Canine Infectious Hepatitis", this.state.infectDate, this.infectDate, this.state.infectDateError, this.state.infectFileError)}
        {this.vaccinationCheckBox('distemper', this.state.distemper, this.distemper, "Distemper", this.state.distemperDate, this.distemperDate, this.state.disTemperDateError, this.state.disTemperFileError)}
        {this.vaccinationCheckBox('lepto', this.state.lepto, this.lepto, "Leptospirosis", this.state.leptoDate, this.leptoDate, this.state.leptoDateError, this.state.leptoFileError)}
        {this.vaccinationCheckBox('kennel', this.state.kennel, this.kennel, "Kennel Cough", this.state.kennelDate, this.kennelDate, this.state.kennelDateError, this.state.kennelFileError)}
        {this.state.vaccineerror && <Typography variant="body1" color="error">Please select atleast one vaccination</Typography>}
        <Grid
          spacing={2} container
        >
          <Grid
            xs={12} item
            style={Group.style17} lg={6}
          >
            <label style={Group.label1 as React.CSSProperties} >
              Last Flea Treatment Date*
            </label>
            <br />
            <Box
              style={{ width: "37.5%" }}
            >

              <CustomCalendar
                style={{ marginTop: "5px", position: "relative" }}
              >
                <CustomDate
                  onChange={this.handlefleadate}
                  value={new DateObject(this.state.flea_date)}
                  data-test-id="fleadate"
                  readOnly={this.state.editValue === "View"}
                />
                <CalendarTodayIcon
                  style={{
                    top: "17%",
                    position: "absolute",
                    zIndex: 2,
                    color: "#4385A1",
                    left: "238%",
                  }}
                />


              </CustomCalendar>
            </Box>
            {this.getValError(["flea_date"])}
            {this.state.fleaerror ?
              <Typography style={{ color: "red" }}>flea treatment date should be greater than birthdate</Typography> : null}

          </Grid>
          <Grid
            xs={12}
            style={Group.style5}
            item
            lg={6}
          >
            <label
              style={Group.label1 as React.CSSProperties}
            >
              Last Worming Date*
            </label>
            <br />
            <Box style={{ width: "37.5%" }}>

              <CustomCalendar
                style={{ marginTop: "5px", position: "relative" }}
              >
                <CustomDate
                  data-test-id="wormingdate"
                  value={new DateObject(this.state.worming_date)}
                  onChange={this.handlewormingdate}
                  readOnly={this.state.editValue === "View"}
                />
                <CalendarTodayIcon
                  style={{
                    position: "absolute",
                    color: "#4385A1",
                    top: "17%",
                    left: "238%",
                    zIndex: 2,
                  }}
                />

              </CustomCalendar>
            </Box>
            {this.state.wormingError ? <Typography style={{ color: "red" }}>Worming treatment date should be greater than birthdate</Typography> : null}
            {this.getValError(["worming_date"])}

          </Grid>
        </Grid>
      </Box>
    )
  }



  renderSalutation = () => {
    return (
      <>
        <Menu
          anchorEl={this.state.anchorElgenderemergency as Element | null}
          open={Boolean(this.state.anchorElgenderemergency)}
          onClose={() => this.emergencyGenderClose("")}
          aria-disabled={this.state.editValue === "View"}
          data-test-id="handlegenderchange1"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          PaperProps={{
            style: {
              width: "7%",
              padding: "0px 10px",
              cursor: "pointer",
              maxHeight: "219px",
              overflowY: "auto",
              scrollbarColor: "#5B8188 transparent",
              scrollbarWidth: "thin",
              marginTop: "5px",
              borderRadius: "8px",
              borderBottomRightRadius: "50% !important"
            },
          }}
        >
          <MenuItem value="Mr." onClick={() => this.emergencyGenderClose("Mr.")}
            style={{ backgroundColor: this.state.genderemergency === "Mr." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="mrvalue1"
          >Mr.
          </MenuItem>
          <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
          <MenuItem value="Miss" onClick={() => this.emergencyGenderClose("Miss")}
            style={{ backgroundColor: this.state.genderemergency === "Miss" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="missvalue1"
          >Miss
          </MenuItem>
          <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
          <MenuItem value="Mrs." onClick={() => this.emergencyGenderClose("Mrs.")}
            style={{ backgroundColor: this.state.genderemergency === "Mrs." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="mrsvalue1"
          >Mrs.
          </MenuItem>
          <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
          <MenuItem value="Mx." onClick={() => this.emergencyGenderClose("Mx.")}
            style={{ backgroundColor: this.state.genderemergency === "Mx." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="mxvalue1"
          >Mx.
          </MenuItem>
        </Menu>
      </>
    )
  }

  RenderPetForm = () => {
    return (
      <Grid container style={{ padding: "0% 12%", marginTop: "20px" }}>

        <Box style={{
          width: "100%",
        }}>


          {/* Pet information */}
          {this.state.petType === "dog" && this.renderDogDetails()}
          {this.state.petType === "cat" && this.renderCatDetails()}


          {/* Emergency contact */}
          <Grid item style={{ width: "100%" }}>
            <Box style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "20px", justifyContent: "space-between" }}>
              <Typography variant="h4">Emergency Contact Details</Typography>
              <Divider style={{ width: "55%", height: "1px", backgroundColor: "#224351", marginLeft: "2%" }} />
            </Box>

            <div
              style={{ marginBottom: "5px", marginTop: "30px" }}>
              <label style={Group.label1 as React.CSSProperties}>
                Emergency Contact Name*
              </label></div>
            <div
              style={Group.emergencyfirstdrop}
            >
              <Editdatadiv style={{ width: "100%", display: "flex", height: "41px", backgroundColor: "#E0F4FC" }}>
                <div
                  style={{ ...Group.countryPhnameselect, width: "15%", cursor: "pointer", paddingRight: "10px" } as React.CSSProperties}
                  onClick={this.handleEmergencyopen}
                >
                  <span style={Group.countrySpan as React.CSSProperties}>{this.state.genderemergency}</span>
                  <ExpandMoreIcon

                  />
                </div>



                {this.renderSalutation()}


                <input
                  name="emergency_contact_name"
                  type="text"
                  data-test-id="handleInputtextChange"
                  style={{
                    border: "1px solid #CBD5E1",
                    width: "95%",
                    borderLeft: "none",
                    fontSize: "16px",
                    borderTopRightRadius: "8px",
                    paddingLeft: "15px",
                    borderBottomRightRadius: "8px",
                  }}
                  onChange={this.handleInputtextChange}
                  value={this.state.emergency_contact_name}
                  placeholder="First Name"
                  readOnly={this.state.editValue === "View"}
                ></input>
              </Editdatadiv>
            </div>
            {this.getValError(["emergency_contact_name"])}

            <div style={{ marginTop: "3%" }}>
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Surname*
              </label>
              <input
                type="text"
                name="emergencysurname"
                value={this.state.emergencysurname}
                onChange={this.handleInputtextChange}
                data-test-id="handleInputChange"
                style={Group.inputStyle}
                placeholder="Last Name"
                readOnly={this.state.editValue === "View"}
              />
            </div>
            {this.getValError(["emergencysurname"])}

            <div
              style={{ marginTop: "3%" }}
            >
              <label
                style={Group.label1 as React.CSSProperties}
              >
                Emergency Contact Email*
              </label>
              <input
                type="email"
                name="emergencyemail"
                value={this.state.emergencyemail}
                onChange={this.handleInputtextChange}
                data-test-id="handleInputChange"
                placeholder="kevinbarrett@iamcat.com"
                style={Group.inputStyle}
                readOnly={this.state.editValue === "View"}
              />
            </div>
            {this.getValError(["emergencyemail"])}

            <Grid
              style={{ margin: "20px 0px" } as React.CSSProperties}
            >
              <label
                style={
                  Group.label1 as React.CSSProperties
                }
              >
                Emergency Contact Number*
              </label>
              <Grid
                style={Group.style9 as React.CSSProperties}
              >
                <Editdatadiv style={Group.style9 as React.CSSProperties}>
                  <div
                    style={Group.countryPhnameselect as React.CSSProperties}
                    onClick={this.emergencyCountryOpen}
                  >
                    <span style={Group.countrySpan as React.CSSProperties}>{`${this.state.emergencyContactCountry}`}</span>
                    <ExpandMoreIcon
                    />
                  </div>


                  <CountryMenu anchorEl={this.state.anchorEmergencycountry} onClose={this.handelPhCountryCloses} configJSON={configJSON} listType="countries" user="po" width="18.7%" />


                  <input
                    name="emergency_contact_number"
                    type="number"
                    value={this.state.emergency_contact_number}
                    style={Group.phoneNoInput as React.CSSProperties}
                    onChange={this.handleInputChange}
                    data-test-id="formDataHandleChange"
                    placeholder="Phone Number"
                    readOnly={this.state.editValue === "View"}
                  ></input>
                </Editdatadiv>
              </Grid>
              {this.getValError(["emergency_contact_number"])}
            </Grid>

          </Grid>

          <Box style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
            <Typography variant="h4">Legal Guardian Contacts </Typography>
            <Divider style={{ width: "60%", height: "1px", backgroundColor: "#224351", marginLeft: "2%" }} />
          </Box>

          <Box style={{ display: 'flex', alignItems: 'center', marginLeft: "-12px" }}>
            <Checkbox
              onChange={() => this.emergencyCheckbox()}
              data-test-id={`checkBoxCheck`}
              inputProps={{
                'aria-label': 'checkbox with default color',
              }}
              checked={this.state.guardiancheckbox}
              color="default"
              disabled={this.state.editValue === "View"}
            />

            <Typography
              style={{
                display: 'flex',
                color: this.state.guardiancheckbox ? "black" : "grey",
              }}>
              The legal guardian is the same person as the emergency contact
            </Typography>
          </Box>

          {this.state.guardiancheckbox ? null :
            <Grid style={{ width: "100%" }}>
              <div
                style={{
                  marginTop: "30px",
                  marginBottom: "5px",
                }}>
                <label
                  style={Group.label1 as React.CSSProperties}>
                  Legal Guardian Contact Name*</label>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#E0F4FC",
                  height: "40px",
                }}>
                <Editdatadiv style={{ width: "100%", display: "flex", height: "40px", backgroundColor: "#E0F4FC" }}>
                  <div
                    style={{ ...Group.countryPhnameselect, width: "15%", cursor: "pointer", paddingRight: "10px" } as React.CSSProperties}
                    onClick={this.handleguardianopen}
                  >
                    <span style={Group.countrySpan as React.CSSProperties}>{this.state.genderguardian}</span>
                    <ExpandMoreIcon
                    />
                  </div>

                  <Menu
                    anchorEl={this.state.anchorElgenderguardian as Element | null}
                    open={Boolean(this.state.anchorElgenderguardian)}
                    onClose={() => this.guardianGenderClose("")}
                    data-test-id="handlegenderchange3"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    getContentAnchorEl={null}
                    PaperProps={{
                      style: {
                        width: "7%",
                        padding: "0px 10px",
                        cursor: "pointer",
                        maxHeight: "219px",
                        overflowY: "auto",
                        scrollbarColor: "#5B8188 transparent",
                        scrollbarWidth: "thin",
                        marginTop: "5px",
                        borderRadius: "8px",
                        borderBottomRightRadius: "50% !important"
                      },
                    }}
                  >
                    <MenuItem value="Mr."
                      onClick={() => this.guardianGenderClose("Mr.")}
                      data-test-id="mrvalue3"
                      style={{ backgroundColor: this.state.genderguardian === "Mr." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                    >Mr.
                    </MenuItem>
                    <Divider style={{ margin: "2px", backgroundColor: "#B2E4F8" }} />
                    <MenuItem value="Miss"
                      onClick={() => this.guardianGenderClose("Miss")}
                      data-test-id="missvalue3"
                      style={{ backgroundColor: this.state.genderguardian === "Miss" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                    >Miss
                    </MenuItem>
                    <Divider style={{
                      margin: "2px",
                      backgroundColor: "#B2E4F8"
                    }} />
                    <MenuItem value="Mrs." onClick={() => this.guardianGenderClose("Mrs.")}
                      style={{ backgroundColor: this.state.genderguardian === "Mrs." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                      data-test-id="mrsvalue3"
                    >Mrs.
                    </MenuItem>
                    <Divider style={{
                      margin: "2px",
                      backgroundColor: "#B2E4F8"
                    }} />
                    <MenuItem value="Mx." onClick={() => this.guardianGenderClose("Mx.")}
                      data-test-id="mxvalue3"
                      style={{ backgroundColor: this.state.genderguardian === "Mx." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
                    >Mx.
                    </MenuItem>
                  </Menu>
                  <input
                    name="guardian_name"
                    type="text"
                    onChange={this.handleInputtextChange}
                    style={Group.firstselect}
                    placeholder="First Name"
                    data-test-id="handleInputChange"
                    value={this.state.guardian_name}
                    readOnly={this.state.editValue === "View"}
                  ></input></Editdatadiv>
              </div>
              {this.getValError(["guardian_name"])}
              <div style={{ marginTop: "3%" }}>
                <label
                  style={Group.label1 as React.CSSProperties}
                >
                  Surname*
                </label>
                <input
                  type="text"
                  name="guardiansurname"
                  value={this.state.guardiansurname}
                  onChange={this.handleInputtextChange}
                  data-test-id="handleInputChange"
                  style={Group.inputStyle}
                  placeholder="Last Name"
                  readOnly={this.state.editValue === "View"}
                />
              </div>
              {this.getValError(["guardiansurname"])}
              <div
                style={{ marginTop: "3%" }}>
                <label
                  style={Group.label1 as React.CSSProperties}
                >
                  Legal Guardian Contact Email*
                </label>
                <input
                  name="guardianmail"
                  type="email"
                  onChange={this.handleInputtextChange}
                  value={this.state.guardianmail}
                  style={Group.inputStyle}
                  placeholder="kevinbarrett@iamcat.com"
                  data-test-id="handleInputChange"
                  readOnly={this.state.editValue === "View"}
                />
              </div>
              {this.getValError(["guardianmail"])}

              <Grid style={{ margin: "20px 0px" } as React.CSSProperties}>
                <label
                  style={
                    Group.label1 as React.CSSProperties
                  }
                >
                  Legal Guardian Number*
                </label>
                <Editdatadiv style={Group.style9 as React.CSSProperties}>
                  <div
                    style={Group.countryPhnameselect as React.CSSProperties}
                    onClick={this.guardianCountryOpen}
                  >
                    <span style={Group.countrySpan as React.CSSProperties}>{`${this.state.legalContactCountry}`}</span>
                    <ExpandMoreIcon
                    />
                  </div>


                  <CountryMenu anchorEl={this.state.anchorguardiancountry} onClose={this.handelguardianCountryCloses} configJSON={configJSON} listType="countries" user="po" width="18.7%" />

                  <input
                    name="guardian_contact_number"
                    type="number"
                    style={Group.phoneNoInput as React.CSSProperties}
                    onChange={this.handleInputChange}
                    value={this.state.guardian_contact_number}
                    placeholder="   Phone Number"
                    data-test-id="formDataHandleChange"
                    readOnly={this.state.editValue === "View"}
                  ></input>
                </Editdatadiv>
                {this.getValError(["guardian_contact_number"])}
              </Grid>

            </Grid>}


          {/* Medical notes */}
          {this.RenderMedicalNotes()}


          {/* Vet clinic details */}


          <Box style={{ display: "flex", alignItems: "center", marginTop: "20px", marginBottom: "20px", justifyContent: "space-between" }}>
            <Typography variant="h4">Vet Clinic and Insurance Details</Typography>
            <Divider style={{ width: "50%", height: "1px", backgroundColor: "#224351", marginLeft: "2%" }} />
          </Box>
          <Grid container>
            <Grid
              lg={12}
              item
              xs={12}
              style={Group.Grid14 as React.CSSProperties}>
              <Grid style={Group.style5 as React.CSSProperties}>
                <label
                  style={
                    Group.label1 as React.CSSProperties
                  }
                >
                  Vet Clinic Name*
                </label>
                <br />
                <input
                  name="clinicName"
                  type="text"
                  onChange={this.handleInputChange}
                  style={Group.inputStyle as React.CSSProperties}
                  value={this.state.clinicName}
                  placeholder="Enter Vet clinic name"
                  data-test-id="formDataHandleChange"
                  readOnly={this.state.editValue === "View"}
                ></input>
                {this.getValError(["clinicName"])}
              </Grid>
              <Grid style={Group.style5 as React.CSSProperties}>
                <label
                  style={
                    Group.label1 as React.CSSProperties
                  }
                >
                  Vet Name*
                </label>
                <br />
                <input
                  name="vetName"
                  type="text"
                  onChange={this.handleInputChange}
                  style={Group.inputStyle as React.CSSProperties}
                  value={this.state.vetName}
                  placeholder="Enter Vet name"
                  data-test-id="formDataHandleChange"
                  readOnly={this.state.editValue === "View"}
                ></input>
                {!this.state.vetName &&
                  <Typography variant="subtitle2" color="error">{configJSON.MesErr.vetName}</Typography>}

              </Grid>

              <Grid
                style={Group.style5 as React.CSSProperties}>
                <label
                  style={
                    Group.label1 as React.CSSProperties
                  }
                >
                  Vet Clinic Phone Number*
                </label>
                <Editdatadiv
                  style={Group.style9 as React.CSSProperties}>
                  <div
                    style={Group.countryPhnameselect as React.CSSProperties}
                    onClick={this.clinicCountryOpen}
                  >
                    <span style={Group.countrySpan as React.CSSProperties}>{`${this.state.phoneCountry}`}</span>
                    <ExpandMoreIcon
                    />
                  </div>


                  <CountryMenu anchorEl={this.state.anchorcliniccountry} onClose={this.handelcliniccountryCloses} configJSON={configJSON} listType="countries" user="po" width="18.7%" />

                  <input
                    style={Group.phoneNoInput as React.CSSProperties}
                    name="phoneNumber"
                    type="number"
                    onChange={this.handleInputChange}
                    value={this.state.phoneNumber}
                    data-test-id="formDataHandleChange"
                    placeholder="Phone Number"
                    readOnly={this.state.editValue === "View"}
                  ></input>
                </Editdatadiv>
                {this.getValError(["phoneNumber"])}
              </Grid>

              <Grid
                style={Group.style5 as React.CSSProperties}
              >
                <label
                  style={
                    Group.label1 as React.CSSProperties
                  }
                >
                  Vet Clinic Address*
                </label>
                <br />
                <textarea
                  name="clinicAddress"
                  style={
                    Group.textArea as React.CSSProperties
                  }
                  onChange={this.handleInputChange}
                  rows={4}
                  value={this.state.clinicAddress}
                  placeholder="Enter vet clinic address here"
                  data-test-id="formDataHandleChange"
                  readOnly={this.state.editValue === "View"}
                ></textarea>
                {this.getValError(["clinicAddress"])}
              </Grid>



              <Grid
                style={Group.style5 as React.CSSProperties}
              >
                <label
                  style={
                    Group.label1 as React.CSSProperties
                  }
                >
                  Insurance Provider

                </label>

                <br />

                <input
                  type="text"
                  name="insurenceProvider"
                  value={this.state.insurenceProvider}
                  onChange={this.handleInputChange}
                  data-test-id="formDataHandleChange"
                  placeholder="Enter pet insurance provider"
                  style={Group.inputStyle as React.CSSProperties}
                  readOnly={this.state.editValue === "View"}
                ></input>
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  xs={12}
                  item
                  style={Group.style5 as React.CSSProperties}
                  lg={6}
                >
                  <label
                    style={
                      Group.label1 as React.CSSProperties
                    }
                  >
                    Policy Number

                  </label>

                  <br />
                  <input
                    name="policyNumber"
                    value={this.state.policyNumber}
                    type="text"
                    data-test-id="formDataHandleChange"
                    placeholder="Enter pet Policy number"
                    style={Group.twoinputStyle as React.CSSProperties}
                    onChange={this.handleInputChange}
                    readOnly={this.state.editValue === "View"}
                  ></input>
                </Grid>
                <Grid
                  xs={12}
                  item
                  lg={6}
                  style={Group.style5 as React.CSSProperties
                  }
                >
                  <label
                    style={
                      Group.label1 as React.CSSProperties
                    }
                  >
                    Microchip Number

                  </label>

                  <br />
                  <input
                    name="microchipNumber"
                    type="text"
                    onChange={this.handleInputChange}
                    value={this.state.microchipNumber}
                    style={Group.twoinputStyle as React.CSSProperties}
                    placeholder="Enter Microchip number"
                    data-test-id="formDataHandleChange"
                    readOnly={this.state.editValue === "View"}
                  ></input>
                  {!this.state.microchipNumber && this.getValError(["microchipNumber"])}
                </Grid>

              </Grid>
            </Grid>
          </Grid>


        </Box>

      </Grid>
    );
  };




  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <div
        style={{
          height: "100vh",
          backgroundColor: "E0F4FC",
        }}>
        <div
          style={Group.renderdiv1}
        >
          <PetOwnerNavigationMenu navigation={this.props.navigation} id=""  navType="fullNavbar" />
          <Container
            style={Group.containerdiv as React.CSSProperties}
          >
            <Paper
              style={Group.paperdiv}
            >

              <Grid container style={Group.mainGrid1}>

                {this.state.EditSlackPo && (
                  <CustomizedSnackbars
                    message={'Pet Profile Updated Sucessfully'}
                    toasterType="success"
                    openToaster={this.state.EditSlackPo} duration={20000} />
                )}

                <Grid item lg={12} xs={12}>
                  <div

                  >
                    <Grid
                      style={Group.rendergrid as React.CSSProperties}
                      data-test-id="handleNavigatePrev"
                    >
                      <Box
                        style={{
                          display: "flex",
                          width: "80%",
                          margin: "auto",
                          alignItems: "center",
                        }}>
                        <Button style={{ fontSize: "24px", fontWeight: 700, marginLeft: "-20px", width: "5%" }}
                          data-test-id="goback"
                          onClick={() => { this.props.navigation.goBack() }}
                        ><ArrowBackIosOutlinedIcon /></Button>
                        {this.state.editValue === "View" ?
                          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                            <span style={Group.Grid1}>{"Pet Profiles"}</span>
                            <Typography variant="subtitle2" style={Group.editText} data-test-id="edit_btn" onClick={() => this.editPet("Edit")}>Edit</Typography>
                          </Box>
                          :

                          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                            <span style={Group.Grid1}>
                              {this.state.petType === "dog" && "Edit Dog Information"}
                              {this.state.petType === "cat" && "Edit Cat Information"}
                            </span>
                            <Typography variant="subtitle2" style={Group.editText} data-test-id="cancel_btn" onClick={() => this.editPet("View")}>Cancel</Typography>
                          </Box>

                        }
                      </Box>
                    </Grid>
                  </div>

                  {this.RenderPetForm()}

                  <Box style={{ width: "89%", display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
                    {this.state.editValue === "Edit" ? <Button
                      style={{
                        color: "#EA0c78",
                        fontSize: 18,
                        fontWeight: 700,
                        textTransform: "none"
                      }}
                      data-test-id="SaveBtn"
                      onClick={() => this.setState({ openEditModal: true, EditSlackPo: false })}
                    >Save</Button> : null}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Container>

          <CustomsettingModal
            isVisible={this.state.openEditModal}
            hideModal={() => this.setState({ openEditModal: false })}
            callApi={this.handleApi}
            typography1="Update Pet Profile"
            typography2="Are you sure you want to update this pet's profile?"
            buttontext1="Yes"
            buttontext2="No"
          />
        </div>
        <Footer color={'#E0F4FC'} />

        <Loader loading={this.state.EditPetLoader} />
      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Editdatadiv = styled(Box)({
  '& .MuiInput-underline::before': {
    borderBottom: "none !important",
    borderRadius: "8px !important"
  },
  '& [class^="MuiNativeSelect-outlined-"]': {
    borderRadius: "8px",
  },
  '& .MuiInput-underline>select': {
    borderRadius: "15px",
    height: "15px !important"
  },

})


const CustomCalendar = styled(Box)({

  "& .rmdp-input": {
    position: "relative",
    cursor: "pointer",
    backgroundColor: "#61b0cf26",
    zIndex: 22,
    width: "265%",
    fontSize: "15px",
    height: "34px",
    fontFamily: "inter",

    paddingLeft: "10px",
    border: "solid 1px #B2E4F8",
    borderRadius: "8px",

  },
  "& .rmdp-header": {
    borderRadius: 8,
    backgroundColor: "#CCEDFB",
    display: "flex",
    fontSize: "14px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .rmdp-week-day": {
    color: "#000"
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight) ": {
    color: "#fff",
    backgroundColor: "#EA0C78",
  },
  "& .rmdp-day.rmdp-today span": {
    color: "#000",
    backgroundColor: "#fff",
  },
  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
    color: "#fff",
    borderColor: "#EA0C78",
    backgroundColor: "#EA0C78",
  },
  "& .rmdp-arrow": {
    borderColor: "#EA0C78"
  },

  "& .rmdp-arrow-container.disabled .rmdp-arrow, .rmdp-arrow-container.disabled:hover .rmdp-arrow": {
    borderColor: "#EA0C78"
  }

})




const Group = {

  countryPhnameselect: {
    height: "39px",
    width: "40%",
    paddingLeft: "10px",
    borderRadius: "8px 0px 0px 8px",
    border: "1px solid #B2E4F8",
    position: "relative",
    backgroundColor: "#E0F4FC",
    justifyContent: "space-between",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    paddingRight: "10px"
  },
  breedselect: {
    width: "97%",
    paddingLeft: "10px",
    border: "1px solid #B2E4F8",
    height: "35px",
    backgroundColor: "#E0F4FC",
    borderRadius: "8px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    paddingRight: "10px"
  },
  countrySpan: {
    color: "#000", fontSize: "16px",
    fontWeight: 400, fontFamily: "inter", textTransform: "capitalize"
  },


  emergencyfirstdrop: {
    display: "flex",
    width: "100%",
    backgroundColor: "#E0F4FC",
    height: "40px",
  },
  firstselect: {
    border: "1px solid #CBD5E1",
    width: "95%",
    borderLeft: "none",
    fontSize: "16px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    paddingLeft: "15px",
  },

  firstnamedrop: {
    width: "25%",
    borderRadius: "8px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    backgroundColor: "#E0F4FC",
  },

  renderdiv1: {
    backgroundColor: "E0F4FC",
    fontFamily: "inter",
    padding: "0% 12%",
    marginBottom: "auto"
  },
  containerdiv: {
    textTransform: "none",
    fontFamily: "inter",
    height: "auto",
    minHeight: "78vh",
    marginTop: "92px",
    paddingBottom: "100px",
    backgroundColor: "E0F4FC"
  },
  paperdiv: {
    borderBottomRightRadius: "15px",
    boxShadow: "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px",
    width: "100%"
  },
  rendergrid: {
    display: "flex",
    alignItems: "flex-start",
    padding: " 30px 28px",
    boxShadow: "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  style17: {
    width: "100%",
    margin: "10px 0px ",
  },
  mainGrid1: {
    backgroundColor: "#fff",
  },
  style16: {
    justifyContent: "space-between",
    width: "95%",
    display: "flex",
    alignItems: "center",
  },
  style15: {
    marginTop: "5px",
    display: "none",
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "16px",
  },

  style9: {
    width: "100%",
    display: "flex",
    marginTop: "5px",
  },
  style5: {
    margin: "10px 0px",
  },
  style2: {
    display: "none",
  },

  style3: {
    display: "flex",
  },

  label1: {
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "5px",
    fontFamily: "inter",
    color: "#000",
  },
  style1: {
    padding: "2%",
    width: "100%",
  },


  uplodeButton: {
    border: "1px solid rgba(203, 213, 225, 1)",
    width: "97.5%",
    color: "#757575",
    borderRadius: "7px",
    height: "33px",
    backgroundColor: "#fff",
    alignItems: "center",
    paddingLeft: "7px",
    boxShadow: "none",
    display: "flex",
  },

  selectCountry: {
    height: "37px",
    width: "40%",
    paddingTop: "3px",
    textAlign: "center",
    borderBottomLeftRadius: "7px",
    borderTopLeftRadius: "7px",
    backgroundColor: "#E0F4FC",
  },
  medicalNotesGrid: {
    paddingBottom: "20px",
  },
  phoneNoInput: {
    height: "41px",
    width: "69.9%",
    borderTopRightRadius: "7px",
    border: "1px solid rgba(203, 213, 225, 1)",
    fontFamily: "inter",
    paddingLeft: "5px",
    borderBottomRightRadius: "7px",
    fontWeight: 400,
    fontSize: "16px",
  },
  textArea: {
    paddingLeft: "10px",
    width: "99.8%",
    resize: "none",
    paddingTop: "3px",
    borderRadius: "7px",
    border: "1px solid rgba(203, 213, 225, 1)",
    fontSize: "16px",
    fontFamily: "inter",
    marginTop: "5px",
    fontWeight: 400,
  },
  selectInputStyle: {
    height: "35px",
    width: "99.8%",
    border: "1px solid rgba(203, 213, 225, 1)",
    marginTop: "5px",
    backgroundColor: "#E0F4FC",
    paddingLeft: "7px",
    borderRadius: "7px",
  },
  uplodePhotoCompt: {
    marginTop: "20px",
    minWidth: "200px",
    width: "40%",
  },
  inputStyle: {
    width: "100%",
    marginTop: "5px",
    border: "1px solid rgba(203, 213, 225, 1)",
    height: "39px",
    paddingLeft: "10px",
    fontFamily: "inter",
    borderRadius: "7px",
    fontWeight: 400,
    fontSize: "16px",
  },

  uplodePetPhoto: {
    height: "200px",
    width: "310px",
    margin: "2.5%",
    overFlow: "hidden",
    objectFit: "cover",
    borderRadius: "15px",
  },
  twoinputStyle: {
    marginTop: "5px",
    width: "100%",
    border: "1px solid rgba(203, 213, 225, 1)",
    height: "33px",
    paddingLeft: "10px",
    borderRadius: "7px",
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "16px",

  },




  Grid1: {
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "inter",
    width: "auto",
    color: "#0F172A",
  },



  Grid14: {
    width: "100%",
  },
  editNotUplodePetPhoto: {
    height: "200px",
    width: "310px",
    margin: "2.5%",
    overFlow: "hidden",
    objectFit: "cover",
    borderRadius: "15px",
    background: colors.primaryBlue,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  editUploadPhotoText: {
    marginTop: "5px",
    color: theme.palette.common.black,
    fontWeight: 700,
    textTransform: 'none'
  },
  vaccinationCertificateName: {
    whiteSpace: "nowrap", width: "90%", color: "#000",
    marginLeft: "5px", overflow: "hidden",
    textDecoration: 'none'
  },
  outerBox: {
    position: 'absolute',
    zIndex: 99,
    background: "#fff",
    borderRadius: 8,
    width: '94%',
    boxShadow: boxShadows.whiteShadow
  },
  editText: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'underline'
  }

};
// Customizable Area End
