import React from "react";
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import {
  Checkbox,
  Dialog,
  DialogContent,
  Box,
  Typography,
  FormControlLabel,
  Divider,
  MenuItem,
  Select,
  CheckboxProps,
  TextField,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import { withStyles, styled } from "@material-ui/core/styles";
import SideNav from "../../../components/src/SideNavbar";
import Footer from "../../../components/src/Footer.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
// Customizable Area End

import CancellationPolicyFirstController, {
  Props,
} from "./CancellationPolicyFirstController.web";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
import { theme } from "../../../components/src/theme";
import { CustomCheckboxSecondary } from "../../../components/src/CustomizedComponents.web";
// Customizable Area Start

interface CustomCheckboxProps extends CheckboxProps {
  "data-test-id": string;
}



const CustomSelect = styled(Box)({
  "& .MuiInputBase-root, .MuiOutlinedInput-root": {
    height: "40px",
    width: "150px",
    background: "#CCEDFA",
    display: 'flex',
    alignItems: 'center'
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiSelect-select:focus": {
    background: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10.5px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
});

const CustomMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    height: "45px",
    borderBottom: "1px solid #CCEDFA",
    display: "flex", justifyContent: "center",
    "&:hover": {
      background: "transparent",
    },
    "&.Mui-selected": {
      background: "#B2E4F8",
      "&:hover": {
        background: "#B2E4F8",
      },
    },
    "& .MuiTouchRipple-root": {
      background: "transparent",
    },
    "& .MuiListItem-root .MuiListItem-gutters": {
      paddingLeft: "0px",
    },
  },
}))(MenuItem);

export const AdditionalQuestionsTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      color: theme.palette.common.black,
      backgroundColor: "#ffffff",
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root:hover": {
      borderRadius: 8
    },
    "& .MuiSvgIcon-root": {
      display: 'none'
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0
    },
    "& .MuiFormHelperText-root": {
      marginTop: 5,
      fontSize: 14
    }
  }
})(TextField);
// Customizable Area End

export default class CancellationPolicyFirst extends CancellationPolicyFirstController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  openModel = () => {
    return (
      <>
        <Dialog
          open={this.state.modelOpen}
          onClose={this.handleModelClose}
          data-test-id="handleModelClose"
          maxWidth="lg"
        >
          <DialogContent
            style={{ width: "450px", padding: "30px", borderRadius: "15px" }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
            <div style={{ padding: "5%", margin: "20px 0px" }}>
              <Typography
                style={styles.modelHeadingText as React.CSSProperties}
              >
                Save as Draft?
              </Typography>
              <Typography style={styles.modelBodyText as React.CSSProperties}>
                Draft will be saved for a period 1 month and then deleted from
                the system
              </Typography>
            </div>
            <div style={styles.modelBtnGrid}>
              <Button style={styles.discartBtn as React.CSSProperties}>
                Discard
              </Button>
              <Button
                style={styles.saveDraftBtn as React.CSSProperties}
                onClick={this.saveDraftData}
                data-test-id="saveDraftData"
              >
                Save Draft
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  };

  thirdForm = () => {
    return (
      <>
        <Grid style={{ margin: "30px 10px" }}>
          <Typography
            variant="h5"
            style={{ fontWeight: 700, color: "#334155" }}
          >
            Tailored Cancellation Policy
          </Typography>
          <Grid style={styles.mainContainerOfinput as React.CSSProperties}>
            <Typography style={styles.textStyleForFirstLabel}>
              1. Until when can the pet owner cancel or amend their reservation free of
              charge
            </Typography>
            <div
              style={
                {
                  ...styles.secondStepInputDiv,
                } as React.CSSProperties
              }
            >
              <Typography
                style={
                  {
                    ...styles.threeStepSelectLable,
                  } as React.CSSProperties
                }
              >
                Select amount of days 1-30
              </Typography>
              <CustomSelect>
                <Select

                  variant="outlined"
                  displayEmpty
                  renderValue={() => (
                    <span style={styles.textOfOutPut}>
                      {this.state.untilCancelReservation}
                    </span>
                  )}
                  MenuProps={{
                    style: { maxHeight: "300px" },
                    disableScrollLock: false,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  IconComponent={ExpandMoreIcon}
                  onChange={(e) =>
                    this.handelSelect(e, "untilCancelReservation")
                  }
                  data-test-id="handelSelectFirst"
                >
                  {this.state.countArry.map((index, value) => (
                    <CustomMenuItem key={value} value={index}

                    >
                      {index}
                    </CustomMenuItem>
                  ))}
                </Select>
              </CustomSelect>
            </div>
            {this.state.untilCancelReservation === "" && <Typography style={{ color: "red" }}>{this.state.untilCancelReservationErr}</Typography>}
          </Grid>
          <div style={{display: "none"}}>
          <Grid style={styles.mainContainerOfinput as React.CSSProperties}>
            <Typography style={styles.textStyleForFirstLabel}>
              {`2. Until when can the pet owner amend their reservation free of charge (extend the amount of days or reduce the amount of days).`}
            </Typography>
            <div
              style={
                {
                  ...styles.secondStepInputDiv,
                } as React.CSSProperties
              }
            >
              <Typography
                style={
                  {
                    ...styles.threeStepSelectLable,
                  } as React.CSSProperties
                }
              >
                Select amount of days 1-30
              </Typography>
              <CustomSelect>
                <Select
                  variant="outlined"
                  displayEmpty
                  renderValue={() => (
                    <span style={styles.textOfOutPut}>
                      {this.state.untilPetOwnerAmend}
                    </span>
                  )}
                  MenuProps={{
                    style: { maxHeight: "300px" },
                    disableScrollLock: false,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                  }}
                  IconComponent={ExpandMoreIcon}
                  onChange={(e) => this.handelSelect(e, "untilPetOwnerAmend")}
                  data-test-id="handelSelectSec"
                >
                  {this.state.countArry.map((index, value) => (
                    <CustomMenuItem key={value} value={index}>
                      {index}
                    </CustomMenuItem>
                  ))}
                </Select>
              </CustomSelect>
            </div>
            {this.state.untilPetOwnerAmend === "" && <Typography style={{ color: "red" }}>{this.state.untilPetOwnerAmendErr}</Typography>}
          </Grid>
          </div>
          <Grid style={styles.mainContainerOfinput as React.CSSProperties}>
            <Typography style={styles.textStyleForFirstLabel}>
              {`2. Please select the pre-payment of the booking`}
            </Typography>
            <Grid>
            </Grid>
          </Grid>

          <Grid>
            <Grid>
                  <div
                    style={
                      {
                        ...styles.secondStepInputDiv,
                      } as React.CSSProperties
                    }
                  >
                    <Typography
                      style={
                        {
                          ...styles.threeStepSelectLable,
                        } as React.CSSProperties
                      }
                    >
                      Please select the % of pre-payment
                    </Typography>
                    <CustomSelect>
                      <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={() => (
                          <span style={styles.textOfOutPut}>
                            {this.state.percentageOfPrePayment}
                          </span>
                        )}
                        MenuProps={{
                          style: { maxHeight: "200px", minHeight: "290px", marginTop: 8 },
                          disableScrollLock: false,
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                        }}
                        IconComponent={ExpandMoreIcon}
                        onChange={(e) => this.handelSelect(e, "percentageOfPrePayment")}
                        data-test-id="handelSelectThr"
                      >
                        {["25%", "50%", "75%", "100%"].map((value, index) => (
                          <CustomMenuItem
                            key={index}
                            value={value}
                            style={{
                              background:
                                this.state.holdOnPercentage === value
                                  ? "#B2E4F8"
                                  : "transparent",
                            }}
                          >
                            {value}
                          </CustomMenuItem>
                        ))}
                      </Select>
                    </CustomSelect>
                  </div>
                  {(this.state.percentageOfPrePayment === "" && this.state.prePaymentMethod == "pre_payment") && <Typography style={{ color: "red" }}>{this.state.percentageOfPrePaymentErr}</Typography>}
            
              <div
                style={
                  {
                    ...styles.secondStepInputDiv,
                  } as React.CSSProperties
                }
              >
                <Typography
                  style={
                    {
                      ...styles.threeStepSelectLable,
                    } as React.CSSProperties
                  }
                >
                  If the client does not turn up, you will hold 100% of total booking
                </Typography>
              
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  fourthForm = () => {
    return (
      <>
        <Grid style={styles.textAreaStyleContainer}>
          <Typography style={styles.textStyleForSecLabel}>
            Additional conditions
          </Typography>
          <AdditionalQuestionsTextField
            variant="outlined"
            fullWidth
            multiline
            name="additionalConditions"
            minRows={5}
            maxRows={6}
            onChange={this.handleTextArea}
            data-test-id="handleTextAreaFirst"
            placeholder="Enter additional conditions"
          />
        </Grid>
        {this.state.totelTextArea.map((value, index) => (
          <Grid style={styles.textAreaStyleContainer} key={index}>
            <Typography style={styles.textStyleForSecLabel}>
              {`${value}. Additional question`}
            </Typography>
            <AdditionalQuestionsTextField
              fullWidth
              multiline
              variant="outlined"
              name={`additionalQuestion${value}`}
              minRows={4}
              maxRows={5}
              onChange={this.handleTextArea}
              data-test-id="handleTextAreaSec"
              placeholder="Enter additional questions"
            />
          </Grid>
        ))}
        <Divider style={{ ...styles.dividerStyle, marginTop: "6%" }} />
      </>
    );
  };

  saveDraftWarning = () => {
    return (
      <>
        {((this.state.activeStep === 1 && this.state.saveDraftWarn) ||
          (this.state.activeStep === 2 && this.state.saveDraftWarnSec)) && (
            <div style={styles.saveDraftWarn as React.CSSProperties}>
              <ReportProblemOutlinedIcon style={{ color: "rgb(195 129 0)" }} />
              <Typography style={styles.saveDraftText as React.CSSProperties}>
                Draft will be saved for a period of 1 month and then deleted from
                the system
              </Typography>
            </div>
          )}
      </>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <BussinessOwnerSideBar navigation={this.props.navigation} active={0} />
        <div style={styles.policyLayout as React.CSSProperties}>
          <Grid style={styles.mainContainer as React.CSSProperties}>
            {this.openModel()}
            <Grid style={styles.mainComponentGrid as React.CSSProperties}>
              <Grid>
                <Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={styles.mainComponentText as React.CSSProperties}
                    >
                      2. Let's Set Up Your Place
                    </p>
                    <span
                      style={
                        {
                          textDecoration: "underline",
                          fontSize: "18px",
                          fontWeight: 700,
                          color: "#54A7C9",
                          fontFamily: "inter",
                          cursor: "pointer",
                        } as React.CSSProperties
                      }
                      onClick={this.saveDraftModelOpen}
                      data-test-id="saveDraftModelOpen"
                    >
                      Save Draft
                    </span>
                  </div>
                  <div style={styles.blueBorderBottom} />
                  {this.saveDraftWarning()}
                </Grid>
                {this.state.activeStep == 1 ? (
                  <>
                    <Box style={styles.formbox}>
                      <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                        Please Select Your Cancellation Policy*
                      </Typography>
                      <FormControlLabel
                        style={{
                          ...styles.formcontrolstyle,
                          backgroundColor: this.state.standard
                            ? "#B2E4F8"
                            : "transparent",
                        }}
                        control={
                          <CustomCheckboxSecondary
                            style={{ margin: '0 10px' }}
                            name="standard"
                            data-test-id="standard_checkbox"

                            checked={this.state.standard.includes("standard")}
                            onChange={this.handleCheckboxChange}
                          />
                        }
                        label="Standard Cancellation Policy"
                      />

                      <FormControlLabel
                        style={{
                          ...styles.formcontrolstyle,
                          backgroundColor: this.state.tailored
                            ? "#B2E4F8"
                            : "transparent",
                        }}
                        control={
                          <CustomCheckboxSecondary
                            name="tailored"
                            style={{ margin: '0 10px' }}

                            checked={this.state.tailored.includes("tailored")}
                            onChange={this.handleTailoredCheckboxChange}
                          />
                        }
                        label="Tailored Cancellation Policy"
                      />
                    </Box>
                    {this.state.cancellationPolicy && (
                      <span style={styles.errorTextStyle}>
                        Please select a cancellation Policy
                      </span>
                    )}
                    {this.state.standard ? (
                      <Box style={{ ...styles.formbox }}>
                        <Typography style={{ fontSize: 20, fontWeight: 700 }}>
                          Standard Cancellation Policy
                        </Typography>
                        <Typography style={styles.innerHtmlLayout} variant="subtitle2" gutterBottom component={'div'} dangerouslySetInnerHTML={{
                          __html:
                            this.addLiMargin(this.state.standarddata)
                        }} />

                      </Box>
                    ) : null}

                    {this.state.tailored ? this.thirdForm() : null}

                    <Divider style={styles.dividerStyle} />
                  </>
                ) : (
                  this.fourthForm()
                )}
              </Grid>

              <Grid style={styles.buttonGrid}>
                <Grid style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    size="small"
                    style={styles.backButton as React.CSSProperties}
                    data-test-id="handleBack"
                    onClick={this.handelBack}
                  >
                    <KeyboardArrowLeftIcon style={{ marginRight: 10 }} /> Back
                  </Button>
                </Grid>

                <Grid>
                  <Button
                    size="small"
                    disabled={this.state.activeStep === 3}
                    style={styles.nextBtn as React.CSSProperties}
                    data-test-id="handleNext"
                    onClick={this.state.tailored ? this.checkValidations : this.handelNext}
                  >
                    {" "}
                    Save and Continue <KeyboardArrowRightIcon style={{ marginLeft: 10 }} />{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '10%' }}>
            <Footer color="#E0F4FC" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />

          </Grid>
        </div>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const styles = {
  policyLayout: {
    marginLeft: "140px", // Adjust based on the width of your sidebar
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    overflowY: "auto", // Allow main reservationContent area to scroll
  },
  blueBorderBottom: {
    backgroundColor: "#B2E4F8",
    padding: "2px 0",
    margin: "10px 0",
  },
  errorTextStyle: {
    fontFamily: "inter",
    fontSize: "14px",
    color: "red",
  },
  dividerStyle: {
    backgroundColor: "#B2E4F8",
    margin: '12% 0'
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  textAreaStyleContainer: { margin: "3% 0%" },
  textAreaStyle: {
    all: "unset",
    resize: "both",
    width: "100%",
    border: "1px solid #B2E4F8",
    borderRadius: "8px",
    padding: "10px 5px",
    fontSize: "16px",
    color: "#0F172A",
    fontWeight: 400,
    fontFamily: "inter",
  },
  checkedFont: {
    color: "#1E293B",
    fontFamily: "inter",
    marginLeft: "10px"
  },
  unCheckedFont: {
    color: "#64748B",
    fontFamily: "inter",
    marginLeft: "10px"

  },
  textStyleForFirstLabel: {
    color: "#0F172A",
    fontWeight: 700,
    margin: "25px 0px 15px",
  },
  textStyleForSecLabel: {
    color: "#0F172A",
    fontWeight: 700,
    margin: "25px 0px 10px",
  },
  priceOfDogInput: {
    width: "150px",
    height: "41px",
    borderRadius: "8px",
    border: "1px solid #B2E4F8",
    backgroundColor: "#CCEDFA",
    fontFamily: "inter",
    fontSize: "14px",
    color: "#1E293B",
    fontWeight: 700,
    textAlign: 'center' as const
  },
  secondStepInputDiv: {
    border: "1px solid #B2E4F8",
    height: "40px",
    fontSize: "14px",
    fontWeight: "400",
    color: "#1E293B",
    fontFamily: "inter",
    borderRadius: "8px",
    marginTop: "2%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "97.6%",
    paddingLeft: "1%",
  },
  threeStepSelectLable: {
    fontWeight: 400,
    color: "#334155",
    fontSize: "16px",
    fontFamily: "inter"
  },
  buttonGrid: {
    // marginTop: "12%",
    display: "flex",
    justifyContent: "space-between",
  },
  nextBtn: {
    fontSize: "16px",
    backgroundColor: "#EA0C78",
    color: "white",
    padding: "5px",
    minWidth: "210px",
    textTransform: "none",
    fontFamily: "inter",
    fontWeight: 700,
    borderRadius: "8px",
    paddingLeft: "30px"
  },
  backButton: {
    fontSize: "16px",

    color: "#0F172A",

    textTransform: "none",
    fontFamily: "inter",
    fontWeight: 700,
  },
  mainGrid: {
    width: "100%",
    overflow: "hidden",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    height: "auto",
    minHeight: "92vh",
    backgroundColor: "rgb(224, 244, 252)",
  },
  mainComponentGrid: {
    width: "65%",
    padding: "20px 8% 5%",
    backgroundColor: "#fff",
    borderBottomRightRadius: "30px",
    height: "80%",
    marginBottom: "2%",
  },
  mainComponentText: {
    fontSize: "24px",
    fontWeight: "400",
    color: "#334155",
    fontFamily: "inter",
  },
  modelHeadingText: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 700,
    color: "#224350",
  },
  modelBodyText: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 700,
    color: "#475569",
  },
  modelBtnGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  discartBtn: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#EA0C78",
    fontFamily: "inter",
    textTransform: "none",
    border: "1px solid #EA0C78",
    width: "192px",
    height: "59px",
    borderRadius: "8px",
  },
  saveDraftBtn: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#fff",
    fontFamily: "inter",
    textTransform: "none",
    backgroundColor: "#EA0C78",
    width: "192px",
    height: "59px",
    borderRadius: "8px",
  },
  saveDraftWarn: {
    display: "flex",
    alignItems: "center",
    height: "50px",
    backgroundColor: "#FEF3C7",
    paddingLeft: "10px",
  },
  saveDraftText: {
    fontFamily: "Inter",
    paddingLeft: "10px",
    fontSize: "16px",
    fontWeight: 400,
  },
  formcontrolstyle: {
    width: "100%",
    border: "1px solid #B2E4F8",
    height: "41px",
    borderRadius: "8px",
    marginLeft: "1px",
    marginTop: "10px",
  },
  formbox: {
    width: "100%",
    minHeight: "100px",
    marginTop: "40px",
  },
  textOfOutPut: {
    fontFamily: "inter",
    fontSize: "14px",
    color: "#1E293B",
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center'
  },
  mainContainerOfinput: {
    marginBottom: "4%",
  },
  innerHtmlLayout: {
    margin: '8px 0px',
    lineHeight: '24px',
    fontWeight: 400
  }
};

// Customizable Area End
