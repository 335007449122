import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import {
  setStorageData,
  getStorageData,
} from "../../../framework/src/Utilities";
import React from "react";
import { handleNavigation } from "../../../components/src/CommonFunctions";

interface LinkObject {
  url: string;
  title: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area StartactiveStep: number;
  options: any[];
  activeStep: number;
  menuAnchor: null | EventTarget;
  anchorEl: null | EventTarget;
  selectedOption: null | boolean;
  selectedServices: any[];
  priceInputs: any[];
  titleText: string;
  descriptionText: string;
  imgError: string;
  error: {
    [key: string]: string;
  };
  MesErrFormThird: typeof configJSON.MesErrFormThird;
  selectedImages: any[];
  indexForDelete: number;
  modelOpen: boolean;
  saveDraftWarn: boolean;
  saveDraftWarnSec: boolean,
  saveDraftWarnThird: boolean,
  popup: boolean,
  threeDotsPopupStaus: number,
  hotelId:any
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BussinessOwnerDetailsSecond extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addHotelCallId: string = "";
  additional_serviceDataId: string = "";
  hotelDataGetId: string = "";
  profileId: string = ";"
  fileInputRef: any;
  updateHotelCallId: string = "";
  getHotelCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      options: [],
      activeStep: 1,
      menuAnchor: null,
      anchorEl: null,
      selectedOption: null,
      selectedServices: [],
      priceInputs: [],
      titleText: "",
      imgError: "",
      descriptionText: "",
      error: {},
      MesErrFormThird: configJSON.MesErrFormThird,
      selectedImages: [],
      indexForDelete: 0,
      modelOpen: false,
      saveDraftWarn: false,
      saveDraftWarnSec: false,
      saveDraftWarnThird: false,
      popup: false,
      threeDotsPopupStaus: -1,
      hotelId: "",
      socialLinksData: [],
      poBOLinksData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.HotelDetails));
      if (sessionData) {
        this.setState({ activeStep:sessionData.activeStep,hotelId:sessionData.hotelId}, () => {
          this.getHotel()
          setStorageData("hotelId", sessionData.hotelId)
        })
      }

    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.additional_serviceDataId) {
      this.setState({
        options: responseJson.data,
      });
    }

    if (apiRequestCallId === this.updateHotelCallId) {
      if(responseJson){
       
        if(this.state.saveDraftWarnSec||this.state.saveDraftWarnThird){
            this.props.navigation.navigate("PropertyDetails")
        }
      }
    }


    if (apiRequestCallId === this.getHotelCallId) {
      this.getHotelApiResponse(responseJson)
   }
    // Customizable Area End
  }

  // Customizable Area Start



  getHotelApiResponse=(responseJson:any)=>{
    if (responseJson) {
      const hotelData = responseJson?.data?.attributes

      const selectedImages = hotelData?.images?.map((image: { name: any; url: any; }) => ({
        file: image.name, 
        imageUrl: image.url
      })) || [];


        this.setState({
            titleText: hotelData?.title,
            descriptionText: hotelData?.description,
            selectedImages: selectedImages
          });
    }
}

  async componentDidMount() {




    this.additionalServiceData();

    const hotelId = JSON.parse(await getStorageData("hotelId"));

    this.setState({hotelId: hotelId});
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData})

  }

  allDataCover = async () => {
    const { saveDraftWarnThird, saveDraftWarnSec } = this.state;
    const BussinessOwnerDetailsThirdBack = JSON.parse(await getStorageData('BussinessOwnerDetailsThirdBack'));


    const allDatas = JSON.parse(await getStorageData("allDataBoSecondStep")) || {};

    const selectedImagess = await getStorageData('selectedImagess');

    /* istanbul ignore if */

    if (selectedImagess && saveDraftWarnSec === false) {
      const parsedImages = JSON.parse(selectedImagess);

      const imagePromises = parsedImages.map(async (parsedDoc2: any) => {
        try {
          const blob = await fetch(parsedDoc2.data).then((res) => res.blob());
          const file = new File([blob], parsedDoc2.name, { type: parsedDoc2.type });

          this.setState((prevState) => ({
            selectedImages: [...prevState.selectedImages, { file, imageUrl: URL.createObjectURL(file) }],
          }));
        } catch (error) {
        }
      });

      await Promise.all(imagePromises);
    }
    /* istanbul ignore if */
    if (BussinessOwnerDetailsThirdBack == "true") {
      this.setState({
        activeStep: allDatas.activeStep
      });

      const BussinessOwnerDetailsThirdBack = "";
      setStorageData("BussinessOwnerDetailsThirdBack", JSON.stringify(BussinessOwnerDetailsThirdBack));
    }


    if (saveDraftWarnThird === false) {
      this.updateStateForSaveDraftThird(allDatas);
    }



  };

  updateStateForSaveDraftThird = (allDatas: { titleText: string; descriptionText: string; priceInputs: any[]; selectedServices: any[]; }) => {
    this.setState({
      titleText: allDatas?.titleText || "",
      descriptionText: allDatas?.descriptionText || "",
      priceInputs: allDatas?.priceInputs,
      selectedServices: allDatas?.selectedServices
    });
  };

  handleAdditinalServiceChange = (index: number, newValue: string) => {
    const updatedSelectedServices = [...this.state.selectedServices];
    if (typeof updatedSelectedServices[index] === 'string') {
      updatedSelectedServices[index] = { value: newValue };
    }
    this.setState({ selectedServices: updatedSelectedServices });
  };


  selectedServices = async () => {

    const selectedServices = JSON.parse(await getStorageData("selectedServices"));


    selectedServices && (this.setState({
      titleText: selectedServices.titleText || "",
      descriptionText: selectedServices.descriptionText || "",
      priceInputs: selectedServices.priceInputs,
      selectedServices: selectedServices.selectedServices,
     

    }));
  }
  selectedImagesData = async () => {
    const selectedImagesData = await getStorageData("selectedImages");
    /* istanbul ignore if */
    if (selectedImagesData) {
      const parsedImages = JSON.parse(selectedImagesData);

      const imagePromises = parsedImages.map(async (parsedDoc2: any) => {
        try {
          const blob = await fetch(parsedDoc2.data).then((res) => res.blob());
          const file = new File([blob], parsedDoc2.name, { type: parsedDoc2.type });

          this.setState((prevState) => ({
            selectedImages: [...prevState.selectedImages, { file, imageUrl: URL.createObjectURL(file) }],
           
          }));
        } catch (error) {
        }
      });

      await Promise.all(imagePromises);
    }

  }

  saveAlldata = async () => {
    const allDataBoSecondStep = {
      titleText: this.state.titleText,
      descriptionText: this.state.descriptionText,
      selectedImages: this.state.selectedImages,
      priceInputs: this.state.priceInputs,
      selectedServices: this.state.selectedServices,
      activeStep: this.state.activeStep
    }

    const selectedImages = this.state.selectedImages;
    const promises = selectedImages.map((image) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {

          if (event && event.target) {
            const base64String = event.target.result;
            resolve({
              name: image.file.name,
              type: image.file.type,
              data: base64String,
            });
          } else {
            reject(new Error('Event or event.target is null.'));
          }
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(image.file);
      });
    });

    try {
      const imageDataArray = await Promise.all(promises);
      await setStorageData('selectedImagess', JSON.stringify(imageDataArray));
    } catch (error) {
    }

    setStorageData("allDataBoSecondStep", JSON.stringify(allDataBoSecondStep));
    const BussinessOwnerDetailsThirdBack = ""
    setStorageData("BussinessOwnerDetailsThirdBack", JSON.stringify(BussinessOwnerDetailsThirdBack));

    const BussinessOwnerDetailsFourBack = "true"
    setStorageData("BussinessOwnerDetailsFourBack", JSON.stringify(BussinessOwnerDetailsFourBack));

    this.props.navigation.navigate("BussinessOwnerDetailsThird");
  }

  handelDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const files = event.dataTransfer.files;

    if (files && files.length < 5) {
      this.setState({
        imgError: "You should upload at least 5 images.",
      });

    } else {
      const newSelectedImagesWithUrls = Array.from(files).map((file) => ({
        file,
        imageUrl: URL.createObjectURL(file),
      }));

      this.setState((prevState) => ({
        selectedImages: [...prevState.selectedImages, ...newSelectedImagesWithUrls],
        imgError: "",
        activeStep: prevState.activeStep + 1,
      }));
    }

  };


  handleEditFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newSelectedImages = [...this.state.selectedImages];
      const editImage = Array.from(files).map((file) => ({
        file,
        imageUrl: URL.createObjectURL(file),
      }));

      newSelectedImages.splice(this.state.indexForDelete, 1, ...editImage);

      this.setState({
        selectedImages: newSelectedImages,
        imgError: "",
      });
    }
  }

  saveDraftData = async () => {
    if (this.state.activeStep === 1 || this.state.activeStep === 2) {
      const selectedImage = this.state.selectedImages;
      const promise = selectedImage.map((image) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (events) => {

            if (events && events.target) {
              const baseString = events.target.result;
              resolve({
                name: image.file.name,
                type: image.file.type,
                data: baseString,
              });
            }

            else {
              reject(new Error('Event or events is null.'));
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(image.file);
        });
      });

      try {
        const imageDataArrays = await Promise.all(promise);
        await setStorageData('selectedImages', JSON.stringify(imageDataArrays));
      } catch (error) {
      }

      this.setState({
        saveDraftWarnSec: true
      },()=>{this.updateInitial(2)})
      this.handleModelClose();
    } else if (this.state.activeStep === 3) {
      const selectedServices = {
        titleText: this.state.titleText,
        descriptionText: this.state.descriptionText,
        priceInputs: this.state.priceInputs,
        selectedServices: this.state.selectedServices,
      };

      this.setState({
        saveDraftWarnThird: true
      },()=>{this.updateInitial(3)})

      setStorageData("selectedServices", JSON.stringify(selectedServices));
      this.handleModelClose();
    } else {
      this.handleModelClose();
    }
  };

  saveDraftModelOpen = () => {
    this.setState({
      modelOpen: true,
    });
  };

  handleModelClose = () => {
    this.setState({
      modelOpen: false,
    });
  };

  additionalServiceData = () => {
    const loginRequestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileId = loginRequestMessages.messageId;
    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hotelEndPoint}/additional_services`
    );

    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.additional_serviceDataId = loginRequestMessages.messageId;
    runEngine.sendMessage(loginRequestMessages.id, loginRequestMessages);
  };

  handleMenuClick = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
    this.setState({ menuAnchor: event.currentTarget, indexForDelete: index, threeDotsPopupStaus: index });
  };

  handleMenuClose = () => {
    this.setState({ menuAnchor: null, popup: false, threeDotsPopupStaus: -1 });
  };

  handleMenuItemClick = (action: string) => {
    if (action === "Delete") {
      const newSelectedImages = [...this.state.selectedImages];
      newSelectedImages.splice(this.state.indexForDelete, 1);
      this.setState({ selectedImages: newSelectedImages, menuAnchor: null, threeDotsPopupStaus: 0 }, () => {
        this.handleMenuClose()

      });
    } else if (action === "Edit") {
      if (this.fileInputRef.current) {
        this.fileInputRef.current.click();
        this.handleMenuClose()
      }
      this.setState({ menuAnchor: null, threeDotsPopupStaus: 0 }, () => {
        this.handleMenuClose()
      });
    }
    else if (action === "MoveForward") {
      const newMovedImages = [...this.state.selectedImages];
      newMovedImages.splice(this.state.indexForDelete, 1);
      newMovedImages.splice(this.state.indexForDelete - 1, 0, this.state.selectedImages[this.state.indexForDelete]);
      this.setState({
        selectedImages: newMovedImages, menuAnchor: null, threeDotsPopupStaus: 0
      }, () => {
        this.handleMenuClose();

      })
    }
    else {
      this.handleMenuClose();
    }
  };


  handlePriceChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const price = event.target.value;
    const numericValue = parseFloat(price.replace(/[^\d.]/g, ''));

    this.setState((prevState) => {
      const updatedInputs = [...prevState.priceInputs];
      const existingIndex = updatedInputs.findIndex((item) => item.id === index);

      if (existingIndex !== -1 && existingIndex < updatedInputs.length) {
        const updatedItem = { ...updatedInputs[existingIndex] };

        if (typeof updatedItem === 'object' && updatedItem !== null) {
          updatedItem.value = numericValue;
          updatedInputs[existingIndex] = updatedItem;
        }
      } else {
        updatedInputs.push({ id: index, value: numericValue });
      }

      return {
        anchorEl: null,
        priceInputs: updatedInputs,
      };
    });
  };



  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
  
      // Filter out only valid image file types
      const validFiles = Array.from(files ?? []).filter((file) =>
        ["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
  
      if (validFiles.length !== files?.length) {
        this.setState({
          imgError: "Only .jpg, .jpeg, or .png files are allowed.",
        });
        event.target.value = "";
        return;
      }
  
      // Process and store valid files
      const newSelectedImagesWithUrls = validFiles.map((file) => ({
        file,
        imageUrl: URL.createObjectURL(file),
      }));
  
      this.setState((prevState) => ({
        selectedImages: [...prevState.selectedImages, ...newSelectedImagesWithUrls],
        imgError: "",
        activeStep: prevState.activeStep + 1,
      }));

  };
  

  handleFileChangeAdd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;



    if (files) {
      const newSelectedImages = Array.from(files).map((file) => ({
        file,
        imageUrl: URL.createObjectURL(file),
      }));





      this.setState((prevState) => ({
        selectedImages: [...prevState.selectedImages, ...newSelectedImages],
        imgError: "",
      }));
    }
  };
  handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    let error = "";
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      error: {
        ...prevState.error,
        [`${name}Error`]: error,
      },
    }));
  };

  // handleSelectClose = (id: number | string, valuese: string) => {
  //   if (valuese) {
  //     this.setState((prevState) => {
  //       const existingServiceIndex = prevState.selectedServices.findIndex(service => service.id === id);

  //       if (existingServiceIndex === -1) {
  //         return {
  //           anchorEl: null,
  //           selectedServices: [
  //             ...prevState.selectedServices,
  //             { id: id, value: valuese },
  //           ]
  //         };
  //       } else {
  //         const updatedServicese = [...prevState.selectedServices];
  //         updatedServicese[existingServiceIndex].value = valuese;
  //         return {
  //           anchorEl: null,
  //           selectedServices: updatedServicese,
  //         };
  //       }
  //     });
  //   } else {
  //     this.setState({ anchorEl: null });
  //   }
  // };
  handleClear = (indexs: number) => {
    this.setState((prevState) => {
      const updatedServices = [...prevState.selectedServices];
      updatedServices.splice(indexs, 1);
      return {
        selectedServices: updatedServices,
      };
    });
  };

  checkAllFieldValidate = (fields: (keyof S)[]): string | null => {
    const MesErrFormThird = configJSON.MesErrFormThird;
    const errors: { [key: string]: string } = {};

    fields.forEach((field) => {
      if (!this.state[field]) {
        const errorMessage = MesErrFormThird[field]
          ? MesErrFormThird[field]
          : `${field} is required.`;

        errors[`${field}Error`] = errorMessage;
      } else {
        errors[`${field}Error`] = "";
      }
    });

    this.setState((prevState) => ({
      error: {
        ...prevState.error,
        ...errors,
      },
    }));

    return Object.values(errors).join("\n");
  };

  handleBack = () => {
    if (this.state.activeStep < 3) {
      handleNavigation("BussinessOwnerDetailsFirst", { activeStep: 3, hotelId: this.state.hotelId }, this.send, this.props)
    } else {
      this.setState({ activeStep: this.state.activeStep - 1, imgError: "" });
    }
  };




  handleNext = () => {
    const {
      activeStep,
      selectedImages,
      imgError,
      titleText,
      descriptionText,
      priceInputs,
      selectedServices,
    } = this.state;

    if (imgError || selectedImages.length < 5) {
      this.setState({
        imgError: selectedImages.length > 0 ? "You must upload at least 5 images." : "Please upload images",
        activeStep: this.state.activeStep,
      });
    } else
      if (activeStep === 3) {
        window.scrollTo(0, 0);
        const fields = ["titleText", "descriptionText"];
        const errorMessages = this.checkAllFieldValidate(fields as any);

        if (errorMessages?.length !== undefined && errorMessages?.length > 1) {
          this.setState({
            activeStep: 3
          });


        } else {

          this.saveAlldata();
        }

        const secondFormData = {
          selectedImages: selectedImages,
          // imgError: imgError,
          titleText: titleText,
          descriptionText: descriptionText,
          priceInputs: priceInputs,
          selectedServices: selectedServices,
        };
        sessionStorage.setItem(
          "secondFormData",
          JSON.stringify(secondFormData)
        );

        const BussinessOwnerDetailsFourBack = "";
        setStorageData("BussinessOwnerDetailsFourBack", JSON.stringify(BussinessOwnerDetailsFourBack));

      } else {

        this.setState({ activeStep: activeStep + 1 });
      }

      if (activeStep > 1){this.updateInitial(activeStep)};
     
  };


  
handleFormdata = (activeStep:any) => {
  if (activeStep === 2) {
    return this.steptwoData();
  } else if (activeStep === 3) {
    return this.stepThreeData();
  }
  return null;
};


 


  updateInitial = async (activeStep:any) => {

  

    const headers = {
      token: localStorage.getItem("authToken")
    };

    const hotelId = JSON.parse(await getStorageData("hotelId"));

    const formData = this.handleFormdata(activeStep);

  if (!formData) {return;}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `${configJSON.hotelEndPoint}/${hotelId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    this.updateHotelCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);


  };

  steptwoData = () => {

    

    const formData = new FormData();
    const { selectedImages } = this.state;


    selectedImages.forEach((data: any) => {
      formData.append('hotel[images][]', data.file)
    })

    return formData
  }

  stepThreeData = () => {

    

    const formData = new FormData();

    formData.append("hotel[title]", this.state.titleText);
    formData.append("hotel[description]", this.state.descriptionText);

    return formData
  }


  getHotel =()=>{
    const headers = {
      token: localStorage.getItem("authToken")
    };


    const hotelRequestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    hotelRequestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hotelEndPoint}/${this.state.hotelId}`
    );
    hotelRequestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    hotelRequestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), "GET"
    );
   
    this.getHotelCallId = hotelRequestMessage2.messageId;
    runEngine.sendMessage(hotelRequestMessage2.id, hotelRequestMessage2);
  }

  // Customizable Area End
}
