import React from "react";
// Customizable Area Start
import { Typography, Grid, MenuItem, Divider, ClickAwayListener, Box, InputAdornment, FormControlLabel, Checkbox } from "@material-ui/core";
import clsx from 'clsx';

// Customizable Area End
import { CustomizedTextField } from "./SinglePropertiesDetails.web";
import { arrowDown } from "../../shoppingcart/src/assets";
import { SettingsTextFieldInput } from "../../settings2/src/Settings2.web";
import { StandardData } from "./types";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CancellationPolicyDetailsController, {
    Props, configJSON,
} from "./CancellationPolicyController.web";
import Loader from "../../../components/src/Loader.web";
import { StyledSecondaryCheckbox } from "../../../components/src/CustomizedComponents.web";

export default class CancellationPolicyDetails extends CancellationPolicyDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start


    renderTariloredCancellation = () => {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>

                            <Typography variant="h6" className={classes.labelText}>{configJSON.tailored.heading}</Typography>

                        </Grid>
                        <Grid item xs={12}>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">

                                {this.state.cancellationPolicyEditStatus ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.tailored.point1}</Typography>

                                        </Grid>
                                        <Grid item xs={12} className={classes.position}>
                                            <CustomizedTextField
                                                className={classes.textInput}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Select amount of days 1-31"
                                                InputProps={{ classes: { input: classes['input'] }, readOnly: true, }}
                                            />
                                            <CustomizedTextField
                                                variant="outlined"
                                                className={classes.dropdownInput}
                                                value={`${this.state.tailoredData.freeofcharge}`}
                                                onClick={() => this.handleOpenDaysDropDown('days')}
                                                data-test-id='days'
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img src={arrowDown} />
                                                        </InputAdornment>
                                                    )

                                                }}

                                            />
                                            {this.state.daysDropDownStatus &&
                                                this.renderdays('days')}
                                                <Typography variant="subtitle2" color="error">{this.state.tailoredDataErrors.freeofcharge}</Typography>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.tailored.point1}</Typography>
                                        <Box className={classes.box}>
                                            <Typography variant="body1" className={classes.labelText}>{this.state.tailoredData.freeofcharge}
                                            </Typography>

                                        </Box>
                                    </>}

                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{display: "none"}}>

                            <Grid container direction="row" justifyContent="space-between" alignItems="center">

                                {this.state.cancellationPolicyEditStatus ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.tailored.point2}</Typography>

                                        </Grid>
                                        <Grid item xs={12} className={classes.position}>
                                            <CustomizedTextField
                                                className={classes.textInput}
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Select amount of days 1-31"
                                                InputProps={{ classes: { input: classes['input'] }, readOnly: true, }}
                                            />
                                            <CustomizedTextField
                                                variant="outlined"
                                                className={classes.dropdownInput}
                                                value={this.state.tailoredData.reducetheAmount}
                                                data-test-id='refundDays'
                                                onClick={() => this.handleOpenDaysDropDown('refundDays')}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img src={arrowDown} />
                                                        </InputAdornment>
                                                    )

                                                }}
                                            />
                                            {this.state.refundDaysDropDownStatus &&
                                                this.renderdays('refundDays')}
                                                <Typography variant="subtitle2" color="error">{this.state.tailoredDataErrors.reducetheAmount}</Typography>


                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.tailored.point2}</Typography>
                                        <Box className={classes.box}>
                                            <Typography variant="body1" className={classes.labelText}>{this.state.tailoredData.reducetheAmount}
                                            </Typography>

                                        </Box>
                                    </>}

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>

                            <Grid container direction="row" spacing={1} >
                                <Grid item xs={12}>
                                    <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.tailored.point3}</Typography>
                                </Grid>

                                    <Grid item xs={12}>
                                        <Grid container>
                                            {this.state.cancellationPolicyEditStatus ?
                                                <Grid item xs={12} className={classes.position}>
                                                    <CustomizedTextField
                                                        className={classes.textInput}
                                                        variant="outlined"
                                                        fullWidth

                                                        placeholder={configJSON.tailored.placeHolder3}
                                                        InputProps={{ classes: { input: classes['input'] }, readOnly: true, }}
                                                    />
                                                    <CustomizedTextField
                                                        variant="outlined"
                                                        className={classes.dropdownInput}
                                                        value={this.state.tailoredData.prepayment}
                                                        data-test-id="prePayment"
                                                        onClick={() => this.handleOpenPercentageDropDown('prePayment')}
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <img src={arrowDown} />
                                                                </InputAdornment>
                                                            )

                                                        }}
                                                    />
                                                    {this.state.percentageDropDownStatus &&
                                                        this.renderPercentage('prePayment')}
                                                <Typography variant="subtitle2" color="error">{this.state.tailoredDataErrors.prepayment}</Typography>

                                                </Grid> :
                                                <Grid item xs={12} >
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Typography variant="subtitle2" gutterBottom className={classes.licienceText}>{configJSON.tailored.placeHolder3}</Typography>
                                                        <Box className={classes.box}>
                                                            <Typography variant="subtitle2" className={classes.labelText}>{this.state.tailoredData.prepayment}</Typography>

                                                        </Box>
                                                    </Grid>
                                                </Grid>}


                                        </Grid>

                                    </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        {this.state.cancellationPolicyEditStatus ?
                                            <Grid item xs={12} className={classes.position} >
                                                <CustomizedTextField
                                                    className={classes.textInput}
                                                    variant="outlined"
                                                    fullWidth

                                                    placeholder={configJSON.tailored.placeHolder2}
                                                    InputProps={{ classes: { input: classes['input'] }, readOnly: true, }}
                                                />
                                            </Grid> :
                                            <Grid item xs={12} >
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Typography variant="subtitle2" gutterBottom className={classes.licienceText}>{configJSON.tailored.placeHolder2}</Typography>
                                                </Grid>
                                            </Grid>}


                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </>
        )
    }
    renderPercentage = (key: string) => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={() => this.handleClosePercentageDropDown(key)}>
                    <Box className={classes.inner}>
                        <Box className={classes.outer}>
                            {
                                ["25%", "50%", "75%", "100%"].map((item: string, index: number) => {
                                    return (
                                        <>
                                            <MenuItem
                                                className={classes.valuesText}
                                                data-test-id='percentageHoldSelect'
                                                onClick={() => { this.selectPecentage(item, key) }} key={index} value={item}>{item}</MenuItem>
                                            <Divider />
                                        </>
                                    )

                                })

                            }
                        </Box>

                    </Box>

                </ClickAwayListener>
            </>
        )
    }
    renderdays = (select: string) => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={() => this.handleCloseDaysDropDown(select)}>
                    <Box className={classes.daysInner}>
                        <Box className={classes.outer}>
                            {
                                this.state.daysCount.map((item: string, index: number) => {
                                    return (
                                        <>
                                            <MenuItem key={index}
                                                className={classes.valuesText}
                                                data-test-id='select_days'
                                                onClick={() => this.selectDays(item, select)}
                                                value={item}>{item}</MenuItem>
                                            <Divider />
                                        </>
                                    )

                                })

                            }
                        </Box>

                    </Box>

                </ClickAwayListener>
            </>
        )
    }
    renderAdditionalQuestions=()=>{
        const {classes}=this.props;
        return(<>
            {this.state.cancellationPolicydropDownStatus && <Grid item xs={12}>
            <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.conditions}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.cancellationPolicyEditStatus ?
                                <SettingsTextFieldInput
                                    data-test-id="input_condition"
                                    placeholder={configJSON.propertyText.placeHolderConditions}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    className={classes.conditionsInput}
                                    minRows={3}
                                    value={this.state.policyQuestions.conditions}
                                    name="conditions"
                                    onChange={this.handleQuestionsEditChange}

                                />
                                :
                                <Typography variant="subtitle2" className={classes.tabText}>{this.state.policyQuestions.conditions}</Typography>
                            }
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>1.{configJSON.propertyText.question1}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.cancellationPolicyEditStatus ?
                                <SettingsTextFieldInput
                                    placeholder={configJSON.propertyText.placeHolderQuestion}
                                    multiline
                                    minRows={1}
                                    variant="outlined"
                                    className={classes.conditionsInput}
                                    fullWidth
                                    value={this.state.policyQuestions.question1}
                                    name="question1"
                                    onChange={this.handleQuestionsEditChange}

                                />
                                :
                                <Typography variant="subtitle2" className={classes.tabText}>{this.state.policyQuestions.question1}</Typography>
                            }
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>2.{configJSON.propertyText.question2}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.cancellationPolicyEditStatus ?
                                <SettingsTextFieldInput
                                    placeholder={configJSON.propertyText.placeHolderQuestion}
                                    variant="outlined"
                                    multiline
                                    minRows={1}
                                    className={classes.conditionsInput}
                                    fullWidth
                                    value={this.state.policyQuestions.question2}
                                    name="question2"
                                    onChange={this.handleQuestionsEditChange}

                                />
                                : <>
                                        {this.state.policyQuestions.question2 !== "undefined" && <Typography variant="subtitle2" className={classes.tabText}>{this.state.policyQuestions.question2}</Typography>}
                              </>

                            }
                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>3.{configJSON.propertyText.question3}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.cancellationPolicyEditStatus ?
                                <SettingsTextFieldInput
                                    placeholder={configJSON.propertyText.placeHolderQuestion}
                                    variant="outlined"
                                    className={classes.conditionsInput}
                                    multiline
                                    minRows={1}
                                    fullWidth
                                    value={this.state.policyQuestions.question3}
                                    name="question3"
                                    onChange={this.handleQuestionsEditChange}

                                />
                                    : <>
                                        {this.state.policyQuestions.question3 !== "undefined" && <Typography variant="subtitle2" className={classes.tabText}>{this.state.policyQuestions.question3}</Typography>}
                                    </>
                            }
                        </Grid>
                    </Grid>

                </Grid>

            </Grid>

        </Grid>}
        </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { previousSelectedPolicy, cancellationPolicyEditStatus } = this.state

        return (
            <>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.cancellation}</Typography>

                        </Grid>
                        <Grid item>
                            <Typography data-test-id='edit_policy'
                                onClick={cancellationPolicyEditStatus ? this.validateCancellationPolicy : this.handleEditPolicy}
                                variant="body1" className={classes.editText}>
                                {this.state.cancellationPolicyEditStatus ? configJSON.propertyText.save : configJSON.propertyText.edit}
                            </Typography>

                        </Grid>

                    </Grid>
                </Grid>
                <Divider className={classes.cancellationDivider} />

                <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                        {this.state.cancellationPolicyEditStatus &&
                            <>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} className={previousSelectedPolicy != 'standard' ? classes.borerCheckBox : ""}>
                                            <FormControlLabel
                                                className={previousSelectedPolicy === 'standard' ? classes.checkBoxBackground : classes.checkboxLabel}
                                                data-test-id='select'
                                                control={
                                                    <StyledSecondaryCheckbox
                                                        name="standard"
                                                        className="checkbox"
                                                        checked={previousSelectedPolicy === 'standard'}
                                                        onChange={() => this.handleCheckBoxChange("standard")}
                                                        checkedIcon={<span className={clsx(classes.checkedBoxIcon, classes.tickIcon)} />}
                                                        icon={<span className={classes.checkedBoxIcon} />}
                                                        inputProps={{ 'aria-label': 'decorative checkbox' }}

                                                    />

                                                }
                                                label="Standard Cancellation Policy"
                                            />

                                        </Grid>
                                        <Grid item xs={12} className={previousSelectedPolicy != 'tailored' ? classes.borerCheckBox : ""}>

                                            <FormControlLabel
                                                className={previousSelectedPolicy === 'tailored' ? classes.checkBoxBackground : classes.checkboxLabel}

                                                control={
                                                    <StyledSecondaryCheckbox
                                                        name="tailored"
                                                        className="checkbox"
                                                        checked={previousSelectedPolicy === 'tailored'}
                                                        onChange={() => this.handleCheckBoxChange("tailored")}
                                                        checkedIcon={<span className={clsx(classes.checkedBoxIcon, classes.tickIcon)} />}
                                                        icon={<span className={classes.checkedBoxIcon} />}
                                                        inputProps={{ 'aria-label': 'decorative checkbox' }}

                                                    />

                                                }
                                                label="Tailored Cancellation Policy"

                                            />

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.menuText}>{configJSON.propertyText.anyTime}</Typography>

                                </Grid>

                            </>

                        }

                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                                {this.state.previousSelectedPolicy === 'standard' &&
                                    <> {this.state.policyDetails?.attributes?.cancellation_type === 'standard' && <>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" className={classes.labelText}>{configJSON.propertyText.standard}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.standardDetails && this.state.standardDetails.map((details: StandardData) => {
                                                return (
                                                    <Typography style={{ margin: '8px 0' }} variant="subtitle2" gutterBottom component={'div'} dangerouslySetInnerHTML={{ __html: details.attributes.description }} />
                                                )

                                            })}

                                        </Grid>
                                    </>
                                    }
                                    </>}

                                {
                                    this.state.previousSelectedPolicy != 'standard' &&
                                    <>
                                        {this.renderTariloredCancellation()}
                                    </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={this.state.cancellationPolicydropDownStatus ? classes.openAccorditionLayout : classes.nonOpenAccorditionLayout}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h6" className={classes.labelText}>{configJSON.propertyText.dropDown}</Typography>

                                </Grid>
                                <Grid item data-test-id="accordition_click" className={classes.cursorPointer} onClick={this.handleDropDown}>
                                    {this.state.cancellationPolicydropDownStatus ?  <ExpandLessIcon />:<KeyboardArrowDown />}

                                </Grid>

                            </Grid>
                        </Grid>
                        {this.renderAdditionalQuestions()}
                    </Grid>
                </Grid>
                <Loader loading={this.state.cancellationLoader} />


            </>

        )
        // Customizable Area End
    }
}

// Customizable Area Start

// Customizable Area End
