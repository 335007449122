import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";

interface Hotelreviews {
    data: Datum[];
}
export interface Datum {
    id?: string;
    type?: string;
    attributes?: RatingAttributes;
}
export interface RatingAttributes {
    id?: number;
    rating?: number;
    comment?: string;
    hotel_id: number;
    created_at: string;
    updated_at: string;
    review_date: string
    account_id: Accountid;
}
interface Accountid {
    id: number;
    full_name: any;
    photo: any;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    open: boolean;
    handleClose?: () => void
    selectedRoomId?: string | number
    reviewsList?: any,
    accountId?: string | number | null
    hotelId?: string | number | null

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    previewReviewsModal: boolean;
    reviewsList: any;
    ratingValue: number | null;
    ratingDescription: string;
    addreviewPopupStatus: boolean;
    reviewcreateStatus: boolean;
    reviewValidationError: string
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ReviewsPoBoController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    reviewsPoListApiCallId: string = ""
    createReviewApiCallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            previewReviewsModal: false,
            reviewsList: {},
            ratingValue: 0,
            ratingDescription: "",
            addreviewPopupStatus: true,
            reviewcreateStatus: false,
            reviewValidationError: ""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getPoReviewsList()
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId === this.reviewsPoListApiCallId) {
            if (responseJson && !responseJson.errors) {
                this.setState({
                    reviewsList: responseJson
                })
            }
        }
        if (apiRequestCallId === this.createReviewApiCallId) {
            if (responseJson && !responseJson.errors) {
                this.setState({
                    reviewcreateStatus: true,
                    ratingValue: 0,
                    ratingDescription: ""
                }, () => {
                    setTimeout(() => {
                        this.props.navigation.navigate('LandingPage')
                    }, 1000)
                })
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    openPreviewReviewsPopup = () => {

        if (this.state.reviewsList?.hotel_reviews?.data?.length) {
            this.setState({ previewReviewsModal: true })

        }
    }
    handleRatingsDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ ratingDescription: event.target.value })

    }
    closeAddReviewModal = () => {
        this.setState({ addreviewPopupStatus: false }, () => {
            this.props.navigation.navigate('LandingPage')
        })
    }
    closePreviewReviewsPopup = () => {
        this.setState({ previewReviewsModal: false })
    }

    getPoReviewsList = async () => {
        const header = {
            "Content-Type": configJSON.ReviewsApiContentType,
        };
        const selectHotelID = await getStorageData('selectedHotelId')
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewsPoListApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getPoReviewsEndPoint + selectHotelID
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    createReview = () => {
        const isValid = this.validateReview();
        if (!isValid) {
            return;
        }
        const header = {
            "Content-Type": configJSON.ReviewsApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const httpBody = {

            "hotel_review": {
                "rating": this.state.ratingValue,
                "comment": this.state.ratingDescription
            },
            "account_id": this.props.accountId,
            "hotel_id": this.props.hotelId

        };

        this.createReviewApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createReviewEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    validateReview = () => {
        const { ratingValue, ratingDescription } = this.state;

        if (!ratingValue && ratingDescription.trim() === "") {
            this.setState({ reviewValidationError: "Please provide a rating and a comment." });
            return false;
        }
        if (!ratingValue) {
            this.setState({ reviewValidationError: "Please provide a rating." });
            return false;
        }
        if (ratingDescription.trim()==="") {
            this.setState({ reviewValidationError: "Please provide a comment." });
            return false;
        }
        this.setState({ reviewValidationError: "" });
        return true;
    };

    // Customizable Area End
}
