// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,

} from "@material-ui/core";

import { styled, ThemeProvider } from "@material-ui/core/styles";

import Loader from "../../../components/src/Loader.web";

import FaqsController, {
  FaqsItem,
  Props,
} from "./FaqsController.web";

import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import Footer from "../../../components/src/Footer.web";
import { theme } from "../../../components/src/theme";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';




export default class Faqs extends FaqsController {
  constructor(props: Props) {
    super(props);
  }


  customAccordion =()=> {
    const sortedFAQs = this.state.faqList.sort((item1:FaqsItem, item2:FaqsItem) => parseInt(item1.id) - parseInt(item2.id));
    return(
        <Box  style={webStyle.accordianBox}>
        
       {sortedFAQs.map((item: FaqsItem) => {
          return (
            <Customaccordion key={item.id} 
             style={{boxShadow:"0px 0px 0px",border:"2px solid #B2E4F8",marginTop:"5px",borderRadius:"5px"}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography style={{fontSize:"16px",fontWeight:700,fontFamily:"inter"}}>{item.attributes.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{fontSize:"15px",fontWeight:400,fontFamily:"inter"}}> {this.faqsAnswer(item.attributes.answer)}</Typography>
              </AccordionDetails>
            </Customaccordion>
          );
        })
       }
         
        </Box>
    )
  }


  faqsAnswer = (answer:string) => {

    return (
      <>
        {answer&&answer.split(/\r?\n/).map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </>
    )
  }

  render() {


    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ width: "100%", padding: "0px",backgroundColor:"#CCEDFB" }}>
          <PetOwnerNavigationMenu  navType="fullNavbar"
            activeLink={5}
            navigation={this.props.navigation} id=""
          />
          <Box sx={webStyle.mainContainer}>
            <Box style={webStyle.contentbox as React.CSSProperties}>
               <Typography variant="h3" style={{fontWeight:700,marginTop:"30px"}}>Frequently Asked Questions</Typography>
               
               {this.customAccordion()}
                  
              

            </Box>


          


          </Box>
        <Footer color="#CCEDFB" footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData}/>
        </Container>
        <Loader loading={this.state.faqLoader} />
      </ThemeProvider>
    );
  }
}

const Customaccordion:any = styled(Accordion)({
  '&.MuiAccordion-root-516::before':{
       backgroundColor:"transparent",
  }
})



const webStyle = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#CCEDFB",
    minHeight: "100vh",
  },
  contentbox: {
    backgroundColor: "#fff",
    width: "80%",
    // height:"300px",
    borderRadius: "20px",
    marginTop: "95px",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom:"50px"
  },
  accordianBox:{
     width:"80%",
     
     marginTop: "50px",
  }
  
};

// Customizable Area End
