import React from "react";
import { Box, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles"


interface myProps {
    open: boolean;
    handleClose: () => void;
    dailogTitle: any;
    typeOfModel:string
}

const useStyles = makeStyles(() => ({
    contentLayout: {
        maxWidth: "574px",
        width: "100%",
        backgroundColor: "#E0F4FC",
        height: "auto",
        maxHeight: "292px",
        borderRadius: 15,
        margin: 'auto',
        overflowY: 'scroll'
    },
    closeIconLayout: {
        padding: 10,
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'center'
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        padding: "8px",
        gap: "10px"
    }
}));

const styles = {
    dialog: {
        width: "670px",
        minHeight: "500px",
        borderRadius: "15px"
    }
}

export default function CustomizedDailog(props: myProps) {
    const classes = useStyles();
    return (
        <>
            {props.typeOfModel === "dogs" || props.typeOfModel === "cats" ?
                <Dialog open={props.open} maxWidth={'md'}
                    onClose={props.handleClose} aria-labelledby="form-dialog-title"
                    PaperProps={{style: styles.dialog}}
                >
                    <Box className={classes.dialogTitle}>
                        <IconButton aria-label="close" data-test-id="close_dailog" onClick={props.handleClose} >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {props?.dailogTitle()}
                </Dialog>
            :
            <Dialog open={props.open} maxWidth={'md'}
                onClose={props.handleClose} aria-labelledby="form-dialog-title"
                PaperProps={
                    {

                        style: {
                            width: props.typeOfModel === "dateService" ? "550px": props.typeOfModel === "addPet"? "450px" : '670px',
                            height: props.typeOfModel === "dateService" ? "47%":props.typeOfModel === "addPet"? "400px": 
                            props.typeOfModel ==="dogAdditional" || props.typeOfModel ==="catAdditional"
                            ?
                            'auto': "70%",
                            maxHeight: props.typeOfModel === "dateService" ? "47%":props.typeOfModel === "addPet"? "400px": 
                            props.typeOfModel ==="dogAdditional" || props.typeOfModel ==="catAdditional"
                            ?
                            '450px': "70%",
                            minHeight: props.typeOfModel === "dateService" ? "47%":props.typeOfModel === "addPet"? "400px": 
                            props.typeOfModel ==="dogAdditional" || props.typeOfModel ==="catAdditional"
                            ?
                            '200px': "70%",
                            borderRadius: 15
                        }
                    }
                }
            >
                <DialogTitle className={classes.closeIconLayout}>{(props.typeOfModel === "dateService" || props.typeOfModel === "addPet" )?<></>: <IconButton aria-label="close" data-test-id="close_dailog" onClick={props.handleClose} >
                        <CloseIcon />
                    </IconButton>}
                    
                </DialogTitle>
                {props?.dailogTitle()}
            </Dialog>
            }
        </>


    )
}
