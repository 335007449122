import React from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import {
  Typography,
  Checkbox,
  InputAdornment,
  withStyles,
  styled,
  Box
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// Customizable Area End

import LogInBusinessOwnerController, {
  Props
} from "./LogInBusinessOwnerController.web";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class LogInBusinessOwner extends LogInBusinessOwnerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      isCheckboxChecked,
      showPassword,
      password,
      flashBo,
      flashSlack,
      flashEmailForBusiness
    } = this.state;

    const emailSignForm = () => {
      return (
        <>

          <CustomizedSnackbars duration={20000} openToaster={flashEmailForBusiness} message={"Please check your email to activate your account to get started."} toasterType={"success"} />
          <CustomizedSnackbars duration={20000} openToaster={flashBo} message={"Congratulations! Your account has been successfully created"} toasterType={"success"} />
          <CustomizedSnackbars duration={20000} openToaster={flashSlack} message={"Sign in successful"} toasterType={"success"} />

          <Grid
            item
            lg={3}
            style={formstyle.getEmailFn as React.CSSProperties}
            xs={12}
          >
            <Typography
              variant="h5"
              style={formstyle.typographyFn as React.CSSProperties}
            >
              Welcome to <span style={{...formstyle.typographyFn,color:"#EA0B77"} as React.CSSProperties}>PETHOTEL</span> 
            </Typography>
            <Typography
              variant="h6"
              style={{ ...formstyle.typographyFn, fontSize: "20px", marginBottom: "10px" } as React.CSSProperties}
            >
              Business Owner Login
            </Typography>
            <form onSubmit={this.callApiForBoLogIn}>
              <Grid>
                <label style={formstyle.labels as React.CSSProperties}>
                  Email
                </label>
                <br />
                <CssTextField
                  type="email"
                  name="email"
                  data-test-id="getEmail"
                  value={this.state.email}
                  placeholder="Your email"
                  variant="outlined"
                  onChange={this.getEmail}
                  style={formstyle.inputbox}
                />
                <label style={formstyle.labels as React.CSSProperties}>
                  Password
                </label>
                <br />
                <CssTextField
                  type={showPassword ? "text" : "password"}
                  style={formstyle.inputbox}
                  name="password"
                  variant="outlined"
                  value={password}
                  placeholder="Your password"
                  data-test-id="password_change"
                  onChange={this.handlePasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={this.handlePasswordToggle}
                        position="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon
                            style={{ color: "#94A3B8", cursor: "pointer" }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ cursor: "pointer", color: "#94A3B8" }}
                          />
                        )}
                      </InputAdornment>
                    )
                  }}
                />
                {this.state.apiErrorLogInForBO && (
                  <Typography color="error" style={{ padding: "10px" }}>
                    {this.state.apiErrorLogInForBO}
                  </Typography>
                )}
                <br />
                <div style={formstyle.divCssStyle as React.CSSProperties}>
                  <div style={{marginLeft:"-10px"}}>
                    <Checkbox
                      color="default"
                      checked={isCheckboxChecked}
                      data-test-id="checkBoxCheck"
                      inputProps={{
                        "aria-label": "checkbox with default color"
                      }}
                      onChange={this.handleCheckboxChange}
                      icon={<CustomIcon />}
                      checkedIcon={<CustomCheckedIcon />}
                    />
                    <label
                      style={{
                        fontSize: "14px",
                        color: "#1E293B",
                        font: "inter",
                        textTransform: "none",
                        fontFamily: "inter"
                      }}
                    >
                      {" "}
                      Remember Me
                    </label>
                  </div>
                  <Link
                    to="/ResetPasswordBo"
                    style={{ textDecoration: "none" }}
                  >
                    <label
                      style={formstyle.fogotPassword as React.CSSProperties}
                    >
                      {" "}
                      Forgot Password?
                    </label>
                  </Link>
                </div>
                <button
                  style={{
                    ...formstyle.button,
                    color: "#ffffff",
                    backgroundColor: "#EA0C78",
                    marginTop: "20px",
                    font: "inter",
                    textTransform: "none",
                    fontFamily: "inter"
                  }}
                  data-test-id="handle_continue_click"
                  onClick={this.callApiForBoLogIn}
                >
                  Sign In
                </button>
                <div style={formstyle.alreadyAccount as React.CSSProperties}>
                  <label style={formstyle.one15 as React.CSSProperties}>
                    Need an account?{" "}
                    <Link to="/EmailAccountRegistrationBusinessOwner" style={{textDecorationLine:"none"}}>
                      <span style={formstyle.signIn as React.CSSProperties}>
                        Register
                      </span>{" "}
                    </Link>{" "}
                  </label>
                </div>
              </Grid>
            </form>
          </Grid>
        </>
      );
    };

    const getForm = () => {
      return emailSignForm();
    };
    return (
      <>
        <PetOwnerNavigationMenu  navType="auth" authContent="Bo"  navigation={this.props.navigation} id="" />
        <div style={formstyle.myMainDiv}
       
        >
          <Grid
            container
            lg={12}
            style={formstyle.gridContiners as React.CSSProperties}
          >
            {getForm()}
          </Grid>
        </div>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomIcon = styled(Box)(({ theme }) => ({
  border: '1px solid #326478',
  borderRadius: 6,
  width: 20,
  height: 20,
  outline: 'none',
  backgroundColor: "#fff",
  marginLeft:5
}));

const CustomCheckedIcon = styled(CheckBoxIcon)(({ theme }) => ({
  color:"#334155",
  width: 28,
  height: 28,
  backgroundColor: "#fff",
  borderRadius:6
}));


const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#54A7C9"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#CBD5E1",
        borderRadius: "8px"
      },
      "&:hover fieldset": {
        borderColor: "#54A7C9"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#54A7C9",
        borderWidth: "2px",
        borderRadius: "8px"
      }
    }
  }
})(TextField);
const formstyle = {
  myMainDiv: {
    padding: "30px",
    paddingTop: "0px",
    marginTop: "112px"
  },
  button: {
    width: "100%",
    height: "40px",
    borderRadius: "5px",
    marginBottom: "15px",
    border: "none",
    marginTop: "5px",
    color: "#64748B",
    fontWeight: 700,
    fontSize: "14px",
    backgroundColor: "#F1F5F9",
    cursor: "pointer"
  },
  inputbox: {
    width: "100%",
    height: "40px",
    marginTop: "10px",
    fontSize: "14px",
    marginBottom: "30px",
    fontWeight: 400,
    borderRadius: "5px"
  },
  labels: {
    fontWeight: 700,
    color: "#334155",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },

  gridStyles: {
    display: "flex",
    height: "720px",
    justifyContent: "center",
    textAlign: "justify",
    flexDirection: "column",
    flexFlow: "wrap",
    borderRadius: "20px"
  },
  getEmailFn: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "30px",
    minWidth: "450px",
    backgroundColor: "#ffffff",
    height: "auto",
    borderRadius: " 0 0 15px 0",
    marginBottom: "39px"
  },
  typographyFn: {
    color: "#334155",
    fontFamiliy: "inter",
    marginBottom: "0px",
    fontWeight: 700,
    fontSize: "30px",
    font: "inter",
    textTransform: "none"
  },
  divCssStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  gridContiners: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "#B2E4F8",
    marginBottom: "10px",
    height: "auto",
    minHeight: "85vh",
    borderRadius: "20px",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  alreadyAccount: {
    marginTop: "40px",
    textAlign: "center"
  },
  one15: {
    font: "inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E293B",
    textTransform: "none",
    fontFamily: "inter"
  },
  signIn: {
    color: "#1E293B",
    font: "inter",
    fontWeight: 700,
    fontSize: "18px",
    cursor: "pointer",
    textTransform: "none",
    fontFamily: "inter",
    textDecorationLine:"underline"
  },
  fogotPassword: {
    fontSize: "14px",
    color: "#1E293B",
    cursor: "pointer",
    fontWeight: 400,
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
    textDecorationLine:"underline",
  }
};
// Customizable Area End
