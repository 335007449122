Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.hotesListApiEndPoint="bx_block_custom_form/hotels";
exports.url="https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=SX5K85uAbJBHcmznSrkR"; 
exports.daily = "Daily";
exports.dayCareText = "Doggy Daycare";
exports.userPetsApiEndPoint = "bx_block_catalogue/pets/pet_details";
exports.dayCareEndpoint = "bx_block_custom_form/hotel_day_care_services?hotel_id={hotelId}";
exports.addRoom = "Add Room";
exports.additionalService = "Additional services";
exports.addAdditionalService = "Add additional services";
exports.continueBtnText = "Continue";
exports.perDay = "per day";
exports.roomPriceText = "The room price for";
exports.locationText = "Indianapolis, United States";
exports.hotelName = "Pet Hotel Name will be here";
exports.price = "18";
exports.price = "18";
exports.available = "available";
exports.stay = "30 night stay";
exports.roomId = "roomId";
exports.avalabilityApiEndPoint = "bx_block_calendar/check_available_room";
exports.availabilityRoomsEndPoint="bx_block_custom_form/hotels/availability_rooms?id"
exports.hotelRoomsEndpoint="bx_block_custom_form/rooms?hotel_id={hotelId}";
exports.noRoomsAvailable="No Rooms Available"
exports.representative="Representative Details";
exports.serviceText="The payment page allows you to assign services to each pet individually";
// Customizable Area End