import React from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import { Snackbar, Typography, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert/Alert";
const CssTextField = withStyles({
  root: {
    
    '& .MuiInput-underline:after': {
      borderBottomColor: '#54A7C9',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius:"8px"
      },
      
      '&.Mui-focused fieldset': {
        borderColor: '#54A7C9',
        borderWidth:"2px",
        borderRadius:"8px"
      },
    },
  },
})(TextField);
// Customizable Area End

import ResetPasswordPoController, {
  Props
} from "./ResetPasswordPoController.web";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class ResetPasswordPo extends ResetPasswordPoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const buttonOnes = () => {
      return (
        <button
          onClick={this.callApiForPoLogIn}
          data-test-id="handle_continue_click"
          style={{
            ...styles.button,
            color: "#ffffff",
            backgroundColor: "#EA0C78",
          }}
        >
          Send a recovery link
        </button>
      );
    };

    return (
      <>
        <PetOwnerNavigationMenu  navType="auth"  navigation={this.props.navigation} id="" />
        <CustomizedSnackbars
            message={'Link has been sent to the registered mail'}
            toasterType="success"
            duration={20000}
            openToaster={this.state.resetpoPopUp}
            closeStatus={this.closeStatusForgotPassword}
            />
        <div style={styles.mainDiv as React.CSSProperties}>
          <Grid container xs={12} spacing={2} lg={12}>
            <Grid item style={styles.headMaingrid as React.CSSProperties}>
              <Grid style={styles.headSecondgrid as React.CSSProperties}>
                <Typography
                  style={styles.head as React.CSSProperties}
                  variant="h5"
                >
                  {" "}
                  <b>Password Recovery</b>
                </Typography>
                <div>
                  <span style={styles.secComptHead as React.CSSProperties}>Forgotten Your Password?</span>
                  <br />
                  <div style={styles.secComptText as React.CSSProperties}>
                  No problem, we've got you covered! Simply click the link below to reset your password:
                  </div>
                </div>
                <div style={styles.labels as React.CSSProperties}>Email</div>
                <br />
                <CssTextField
                  style={styles.inputbox as React.CSSProperties}
                  placeholder="Your email"
                  type="email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  data-test-id="email-change"
                  variant="outlined"
                />
                {this.state.resetPasswordSucessPet && (
                  <Typography
                    style={{ padding: "10px", color: "rgb(46 125 50)" }}
                  >
                    {this.state.resetPasswordSucessPet}
                  </Typography>
                )}
                {this.state.apiErrorLogIn && (
                  <Typography color="error" style={{ padding: "10px" }}>
                    {this.state.apiErrorLogIn}
                  </Typography>
                )}

                {buttonOnes()}
                <div style={styles.alreadyAccount as React.CSSProperties}>
                  <label style={styles.one15 as React.CSSProperties}>
                    Already have an account?{" "}
                    <Link to="/LogInPetOwner" style={{textDecorationLine:"none"}}>
                      <span style={styles.signIn as React.CSSProperties}>Sign In</span>{" "}
                    </Link>{" "}
                  </label>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>

      // Customizable Area End
    );
  }
}

const styles = {
  // Customizable Area Start
  inputbox: {
    marginBottom: "40px",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "5px",
    marginTop: "10px",
    height: "40px",
    width: "100%",
  },
  button: {
    height: "40px",
    width: "100%",
    borderRadius: "5px",
    border: "none",
    marginTop: "5px",
    backgroundColor: "#F1F5F9",
    fontSize: "14px",
    cursor: "pointer",
    color: "#64748B",
    marginBottom: "15px",
    fontWeight: 700,
  },
  labels: {
    color: "#334155",
    fontWeight: 700,
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  mainDiv: {
    backgroundPosition: "center",
    height: "auto",
    minHeight: "88vh",
    backgroundColor: "rgb(248, 250, 252)",
    backgroundSize: "cover",
    fontFamily: "inter",
    marginTop:'110px'
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    color: "#334155",
  },
  navDivCss: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionDivCss: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  signBtnsCss: {
    color: "#64748B",
    backgroundColor: "#FFFFFF",
    border: "1px solid #64748B",
  },
  headMaingrid: {
    display: "flex",
    justifyContent: "center",
    height: "88vh",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    width: "90%",
    maxWidth: "480px",
  },
  headSecondgrid: {
    padding: "30px",
    borderBottomRightRadius: "45px",
    boxShadow: "rgba(67, 134, 161, 0.03) 0px 8px 32px 0px, rgba(67, 134, 161, 0.03) 0px 4px 8px 0px",
    backgroundColor: "rgba(255, 255, 255)",
    width: "100%",
  },
  head: {
    marginTop: "10px",
    marginBottom: "30px",
    fontWeight: 700,
    color: "#334155",
    textAlign: "left",
    fontSize: "30px",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  accountactionCss: {
    textAlign: "center",
    marginRight: "10px",
    fontSize: "16px",
  },

  secComptHead: {
    fontSize: "14px",
    fontWeight: 700,
    color: "rgba(51, 65, 85, 1)",
    fontFamily: "inter",
    font: "inter",
    textTransform: "none",
  },
  secComptText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "rgba(100, 116, 139, 1)",
    margin: "25px 0px 25px 0px",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  alreadyAccount: {
    marginTop: "60px",
    textAlign: "center",
  },
  one15: {
    font: "inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E293B",
    textTransform: "none",
    fontFamily: "inter"
  },
  signIn: {
    color: "#1E293B",
    font: "inter",
    fontWeight: 700,
    fontSize: "18px",
    cursor: "pointer",
    textTransform: "none",
    fontFamily: "inter",
    textDecorationLine:"underline",
  },
  // Customizable Area End
};
