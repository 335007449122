import React from "react";
// Customizable Area Start
import ReservationsBusinessOwnerController, { Props } from "./ReservationBusinessOwnerController.web";
import { Box, Button, ClickAwayListener, Container, Divider, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography, createStyles, withStyles } from "@material-ui/core";
import { boxShadows, colors } from "../../../components/src/theme";
import { FilterButtons, Pet, ReservationsData, RoomType, Roomtypeforcat } from "./types";
import { cancelIcon, emailIcon, greenArrow, redArrow, searchIcon, setingsIcon } from "./assets";
import FooterWeb from "../../../components/src/Footer.web";
import { arrowDown, arrowUp } from "../../settings2/src/assets";
import ReservationDailog from "./ReservationDailog.web";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
import Loader from "../../../components/src/Loader";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import { configJSON } from "./ReservationsItemController";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import DropdownLoader from "../../../components/src/DropdownLoader";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

// Customizable Area Start
export const BoSearchTextField = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiInputBase-root": {
                borderRadius: 8,
                height: 44,
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.common.black,
                borderColor: theme.palette.info.main,
                background: theme.palette.common.white

            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.info.main
            },
            "& .MuiOutlinedInput-notchedOutline:hover": {
                borderColor: theme.palette.info.main,

            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.info.main,
                borderWidth: "2px"
            },
            "& .MuiOutlinedInput-root:hover": {
                borderColor: theme.palette.info.main,

            },
            "& .MuiSelect-select:focus": {
                borderColor: theme.palette.info.main,
                backgroundColor: "white"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "25px"
            },
        }
    }))(TextField);



const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: '1px solid #CBD5E1'
        },
        head: {
            backgroundColor: colors.primaryBlue,
            color: colors.darkBlack,
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14
        },
        body: {
            fontSize: 14,
            color: theme.palette.common.black,
            lineHeight: "22px"
        },
    }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.white,

        },
    }),
)(TableRow);

// Customizable Area End

export class ReservationsBusinessOwner extends ReservationsBusinessOwnerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    renderStatusDropDowns = (id: string | number, reservation: ReservationsData, index: number, check_in_date: string) => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={this.closeStatusDropDown} >
                    <Box className={classes.statusOuter}>
                        <Box className={classes.statusInner}>
                            <MenuItem className={classes.approveText} data-test-id="approve_menu"
                                onClick={() => this.openCheckInPopup(reservation, index, id, "Approve")}
                            >APPROVE</MenuItem>
                            <Divider className={classes.statusDivider} />
                            <MenuItem className={classes.rejectText} data-test-id="reject_menu"
                                onClick={() => this.openCheckInPopup(reservation, index, id, "Reject")}
                        
                            >REJECT</MenuItem>
                        </Box>
                    </Box>

                </ClickAwayListener>

            </>
        )
    }
    renderStatusUpcomingDropDowns = (id: string | number, reservation: ReservationsData, index: number, check_in_date: string) => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={this.closeStatusDropDown} >
                    <Box className={classes.statusOuter}>
                        <Box className={classes.statusInner}>
                            <MenuItem className={classes.approveText} data-test-id="checked_in"
                                onClick={() => this.openCheckInPopup(reservation, index, id, "Check-In")}
                            >CHECK IN</MenuItem>
                            <Divider className={classes.statusDivider} />
                            <MenuItem className={classes.rejectText} data-test-id="cancelled" 
                            onClick={() => this.openCheckInPopup(reservation, index, id, "Cancelled")}
                            
                            >CANCELLED</MenuItem>
                            <Divider className={classes.statusDivider} />
                            <MenuItem className={classes.checkOutText} style={{ marginTop: "10px" }} data-test-id="no_show" onClick={() => this.openCheckInPopup(reservation, index, id, "No Show")}>NO SHOW</MenuItem>
                        </Box>
                    </Box>

                </ClickAwayListener>

            </>
        )
    }

    renderStatusCheckInDropDowns = (id: string | number, reservation: ReservationsData, index: number) => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={this.closeStatusDropDown} >
                    <Box className={classes.statusOuter}>
                        <Box className={classes.statusInner}>
                            <MenuItem className={classes.checkOutText} data-test-id="checked_out_status"
                                onClick={() => this.openCheckInPopup(reservation, index, id, "Check-Out")}
                            >CHECK OUT</MenuItem>
                        </Box>
                    </Box>

                </ClickAwayListener>

            </>
        )
    }
    renderPropertiesDropDowns = () => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={this.closePropertiesDropdown} >
                    <Box className={classes.propertiesOuter}>
                        <Box className={classes.statusInner}>
                            <MenuItem

                                className={this.state.selectedProperty === 'All properties' ?
                                    classes.menuDropDownText : classes.notSelectedDropDownText}
                                data-test-id="select_property"
                                onClick={() => this.selectProperty('All properties')}>All properties</MenuItem>
                            {this.state.propertiesList.length === 0 && <DropdownLoader loading={this.state.dropdownLoader} />}
                            {this.state.propertiesList
                                .filter((list: any) => list.attributes.name != null)
                                .map((list: any) => (
                                    <MenuItem className={this.state.selectedProperty === list.attributes.name ?
                                        classes.menuDropDownText : classes.notSelectedDropDownText}
                                        data-test-id='api_select_property'
                                        key={list.id}
                                        onClick={() => this.selectProperty(list.attributes.name)}>
                                        {list.attributes.name ?? ""}</MenuItem>
                                ))
                            }
                        </Box>
                    </Box>

                </ClickAwayListener>

            </>
        )
    }

    renderStatus = (id: any, reservation: ReservationsData, index: number) => {
        const { classes } = this.props;
        return (
            <>
                {this.state.selectedFilter === 'Checked in' &&
                    <Box
                        className={
                            reservation?.StatusTest === 'CHECKED IN' ?
                                classes.approveStatusBox : classes.checkedOutStatusBox
                        }

                    >
                        <Box className={classes.statusDropDownBoxContent}>
                            <Typography variant="body1"
                                className={
                                    reservation?.StatusTest === 'CHECKED IN' ?
                                        classes.activeTextStatus : classes.checkedOutTextStatus
                                }>
                                {reservation?.StatusTest}</Typography>
                            <img src={reservation?.StatusTest === 'CHECKED IN' ? greenArrow : redArrow} />
                        </Box>

                        {this.state.statusDropDownValues == id &&
                            <>
                                {this.state.selectedFilter === "Checked in" && this.renderStatusCheckInDropDowns(id, reservation, index)}

                            </>

                        }
                    </Box>
                }
                {this.state.selectedFilter != 'Cancelled' && this.state.selectedFilter != 'Completed' && this.state.selectedFilter != 'Checked in' &&
                    this.statusDropDownBox(reservation, id, index)
                }
                {this.state.selectedFilter === 'Cancelled' && <Box width={'227px'}>
                    <Box>
                        <Typography variant="subtitle2">
                            {reservation.attributes.reject_message}
                        </Typography>
                    </Box>
                </Box>}
            </>
        )
    }
    renderSearchIcon = () => {
        const { classes } = this.props;
        const { searchStatus } = this.state;
        if (searchStatus) {
            return (
                <img src={cancelIcon}
                    data-test-id='hide_search_icon'
                    className={classes.cursor}
                    onClick={this.hideSearchBar}
                />
            );
        } else {
            return (
                <img src={searchIcon}
                    className={classes.cursor}
                    onClick={this.showSearchBar}
                    data-test-id='show_search_icon'
                />
            );
        }
    };
    renderContent = () => {
        const { classes } = this.props;
        const { FilterButtons, selectedFilter, searchStatus, searchValue } = this.state;

        if (searchStatus) {
            return (
                <BoSearchTextField
                    fullWidth
                    data-test-id="searchFileld"
                    onChange={this.changeSearchValue}
                    variant="outlined"
                    placeholder="Search pet owner or pet names..."
                    value={searchValue}
                />
            );
        } else {
            return (
                <Grid container spacing={1} alignItems="center">
                    {FilterButtons.map((button: FilterButtons) => {
                        return (
                            <>
                                <Grid item key={button.filterId}>
                                    <Button variant="outlined"
                                        data-test-id='select_filter_buttons'
                                        onClick={() => this.selectFilter(button)}
                                        className={selectedFilter === button.filterName ? classes.selectedFilterButtons : classes.filerButtons}
                                    >{button.filterName}</Button>
                                </Grid>
                            </>
                        )
                    })}
                    <Grid item >
                        <Button
                            variant="outlined"
                            data-test-id='event-login'
                            className={classes.filerButtons}
                            onClick={this.getMicrosoftRedirection}
                        >
                            Calendar Sync
                        </Button>
                    </Grid>
                </Grid>
            );
        }
    };


    statusDropDownBox = (reservation: ReservationsData, id: number, index: number) => {
        const { classes } = this.props;
        const status = reservation.StatusTest;

        const getBoxClass = (status: string) => {
            if (status === 'PENDING...') return classes.statusDropDownBox;
            if (status === 'APPROVE' || status === 'CHECKED IN') return classes.approveStatusBox;
            if (status === 'NO SHOW') return classes.checkedOutStatusBox;
            return classes.rejectStatusBox;
        };

        const getTypographyClass = (status: string) => {
            if (status === 'PENDING...') return classes.pendingText;
            if (status === 'APPROVE' || status === 'CHECKED IN') return classes.activeTextStatus;
            if (status === 'NO SHOW') return classes.checkedOutTextStatus;
            return classes.rejectTextStatus;
        };

        const getImgSrc = (status: string) => {
            if (status === 'PENDING...') return arrowDown;
            if (status === 'APPROVE' || status === 'CHECKED IN') return greenArrow;
            return redArrow;
        };

        const boxClass = getBoxClass(status);
        const typographyClass = getTypographyClass(status);
        const imgSrc = getImgSrc(status);


        return (
            <>
                <Box className={boxClass}>
                    <Box className={classes.statusDropDownBoxContent}>
                        <Typography variant="body1" className={typographyClass}>
                            {status}
                        </Typography>
                        <img src={imgSrc} />
                    </Box>

                    {this.state.statusDropDownValues === id &&
                        <>
                            {this.state.selectedFilter === 'Pending' && this.renderStatusDropDowns(id, reservation, index, reservation.attributes.check_in_date)}
                            {this.state.selectedFilter === "Upcoming" && this.renderStatusUpcomingDropDowns(id, reservation, index, reservation.attributes.check_in_date)}
                        </>
                    }
                </Box>
            </>
        );
    };

    renderExportButton = () => {
        const { classes } = this.props;

        return (
            (this.state.selectedFilter === "Completed" || this.state.selectedFilter === "Cancelled" || this.state.selectedFilter === "No Show") &&
            <Button
                variant="outlined"
                data-test-id="exportButtonId"
                onClick={this.selectExport}
                className={classes.exportButton}
            >
                <CloudDownloadOutlinedIcon
                />
            </Button>
        );
    };

    tableCells = () => {
        const { classes } = this.props;

        return (
            <>
                {this.state.selectedFilter != 'Cancelled' && <StyledTableCell className={classes.priceTableHead}>Price</StyledTableCell>}
                {this.state.selectedFilter != 'Completed' && this.state.selectedFilter !== 'No Show' && <StyledTableCell
                    align={this.state.selectedFilter != 'Completed' && this.state.selectedFilter != 'Cancelled' ? "center" : 'left'}
                    className={this.state.selectedFilter === 'Cancelled' ? classes.reasonTableHead : classes.statusTableHead}
                >

                    {this.state.selectedFilter === 'Cancelled' ? "Reason" : "Status"}
                </StyledTableCell>}
            </>
        )
    }

    filterProperties = () => {
        const {classes}=this.props;
        return (
            <>
                <Box className={classes.dropDownBox}
                    data-test-id="open_properties"
                    onClick={this.openPropertiesDropdown}>
                    <Box className={classes.dropDownBoxContent}>
                        <Typography variant="subtitle2" className={classes.pendingText}>{this.state.selectedProperty}</Typography>
                        <img src={this.state.propertiesDropDownStatus ? arrowUp : arrowDown} />
                    </Box>
                    {this.state.propertiesDropDownStatus && this.renderPropertiesDropDowns()}
                </Box>
            </>

        )
    }
    // Customizable Area End


    render() {
        // Customizable Area Start
        const ResultsPerPage = 9;
        const { classes } = this.props;
        const { toasterMessage, toasterStatus, toasterType, isError, errorMsg } = this.state
        return (
            <>
                <NavigationMenu active={2} navigation={this.props.navigation} id="" />
                <CustomizedSnackbars openToaster={toasterStatus} message={toasterMessage} toasterType={toasterType} duration={5000} closeStatus={this.closeToaster} />
                <CustomizedSnackbars openToaster={isError} message={errorMsg} toasterType={"error"} duration={5000} closeStatus={this.closeToaster} />
                <div className={classes.reservationContent}>
                    <Container maxWidth={false}>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3} >
                                <Grid item xs={12} style={{ margin: "20px 0px 0px 0px" }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item className={classes.searchLayout} >
                                            {this.renderContent()}
                                        </Grid>


                                        <Grid item className={classes.filtersLayout}>
                                            <Grid container direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                                                {this.renderExportButton()}
                                                <Grid item>
                                                    {this.renderSearchIcon()}
                                                </Grid>
                                                <Grid item>
                                                    {this.filterProperties()}

                                                </Grid>
                                                <Grid item
                                                    data-test-id='settingsFilters'
                                                    onClick={() => this.openCommonDailog('settingsFilters', "")}>
                                                    <img src={setingsIcon} className={classes.cursor} />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper} className={classes.container}>
                                        <Table className={classes.table} stickyHeader aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell className={classes.petOwnerTableHead}>Pet owner name</StyledTableCell>
                                                    <StyledTableCell className={classes.dateTableHead}>Date</StyledTableCell>
                                                    <StyledTableCell className={classes.propertyNameTableHead}>Property name</StyledTableCell>
                                                    <StyledTableCell className={classes.petTypeTableHead}>Pet type</StyledTableCell>
                                                    <StyledTableCell className={classes.roomTypeTableHead}>Room type</StyledTableCell>
                                                    {this.tableCells()}
                                                    <StyledTableCell className={classes.messageTableHead}>Message</StyledTableCell>
                                                </TableRow>

                                            </TableHead>
                                            <TableBody>
                                                {this.state.reservationList.map((reservation: ReservationsData, index: number) => {
                                                   
                                                    return (
                                                        <StyledTableRow key={reservation.id}>
                                                            <StyledTableCell component="th" scope="row" className={classes.petOwnerTableHead}>
                                                                {reservation.attributes.pet_owner_name}
                                                            </StyledTableCell>
                                                            <StyledTableCell className={classes.dateTableHead}>
                                                                <Typography>

                                                                    {moment(reservation?.attributes?.check_in_date).format('MMM DD, YYYY')}-
                                                                </Typography>
                                                                <Typography>
                                                                    {moment(reservation?.attributes?.check_out_date).format('MMM DD, YYYY')}
                                                                </Typography></StyledTableCell>
                                                            <StyledTableCell className={classes.propertyNameTableHead} >{reservation?.attributes?.hotel_information.name}</StyledTableCell>
                                                            <StyledTableCell className={classes.petTypeTableHead}>
                                                                <Grid item xs={12}>
                                                                    <Grid item container direction="column" spacing={1}>
                                                                        {reservation.attributes.pets.data.map((pet: Pet) => {
                                                                            return (
                                                                                <>
                                                                                    <Grid item xs={12} className={classes.petLayout} data-test-id='selectedPet' onClick={() => this.selectedPet(pet)}>
                                                                                        <Typography variant="subtitle2" className={classes.petText}>{pet.attributes.name}&nbsp;({pet.attributes.pet_type})</Typography>

                                                                                    </Grid>

                                                                                </>
                                                                            )
                                                                        })}

                                                                    </Grid>

                                                                </Grid>

                                                            </StyledTableCell>
                                                            <StyledTableCell className={classes.roomTypeTableHead}>
                                                                <Grid item xs={12}>
                                                                    <Grid item container direction="column" spacing={1}>
                                                                        {reservation.attributes.room_for_cats && reservation.attributes.room_for_cats.map((list: { id: number, pets: string[], price: number, type: string }) => {
                                                                            return (
                                                                                <>
                                                                                    <Grid item xs={12} >
                                                                                        <Typography variant="subtitle2" className={classes.petText}>{list.type}&nbsp;(for&nbsp;{"Cat"})</Typography>

                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        {reservation.attributes.room_for_dogs && reservation.attributes.room_for_dogs.map((list: { id: number, pets: string[], price: number, type: string }) => {
                                                                            return (
                                                                                <>
                                                                                    <Grid item xs={12} >
                                                                                        <Typography variant="subtitle2" className={classes.petText}>{list.type}&nbsp;(for&nbsp;{"Dog"})</Typography>

                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                            {this.state.selectedFilter != 'Cancelled' && <StyledTableCell >

                                                                <Typography variant="body1"
                                                                    className={classes.disbaledPriceText}
                                                                > {this.getRoomPrice(reservation.price, reservation.attributes.total_charges)}</Typography>


                                                            </StyledTableCell>}
                                                            {this.state.selectedFilter != 'Completed' && this.state.selectedFilter != 'No Show' && <StyledTableCell
                                                                className={this.state.selectedFilter === 'Cancelled' ? classes.reasonTableHead : classes.statusTableHead}
                                                                data-test-id="statusDailog"
                                                                onClick={() => this.openStatusDropDown(reservation.id)}
                                                            >
                                                                {this.renderStatus(reservation.id, reservation, index)}
                                                            </StyledTableCell>}
                                                            {this.state.selectedFilter != "Blocked" && <StyledTableCell  >
                                                                <img src={emailIcon} className={classes.cursor} data-test-id="chat_icon"
                                                                    onClick={() => this.seeChat(reservation.attributes.chat_id[0])}
                                                                />
                                                            </StyledTableCell>}

                                                        </StyledTableRow>
                                                    )
                                                })}
                                                {this.state.reservationList.length === 0 && (
                                                    <StyledTableRow>
                                                        <TableCell colSpan={8} align="center" style={{ height: 400, borderBottom: 'none' }}>
                                                            <Typography variant="h5" className={classes.notFoundText}>No Reservations Found</Typography>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )}

                                            </TableBody>


                                        </Table>

                                    </TableContainer>
                                    <Grid item xs={12} className={classes.paginationLayout}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ padding: '25px' }}>
                                            <Grid item xs={4}>

                                            </Grid>
                                            <Grid item xs={4} container justifyContent="center">
                                                <Pagination data-test-id='change_page' count={this.state.totalPageCount} page={this.state.page} onChange={this.handlePagination} />

                                            </Grid>
                                            <Grid item xs={4} container justifyContent="flex-end">
                                                <Typography variant="body2" color="textSecondary">
                                                    {(this.state.page - 1) * ResultsPerPage + 1}-
                                                    {this.getRecordPerPage(ResultsPerPage) - 1} of {this.state.totalCount} results
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>

                    </Container>
                    <Loader loading={this.state.reservationPageLoader} />
                    <FooterWeb footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
                    {this.state.dailogStatus && <ReservationDailog
                        data-test-id="reservation-dailog"
                        reservationDetails={this.state.reservationDetails}
                        listOfRooms={this.state.roomList}
                        getFilterParams={this.getFilterParams}
                        dailogType={this.state.popUpType}
                        open={this.state.dailogStatus}
                        getDailogStatus={this.closeCommonDailogStatus}
                        getRejectAction={this.getRejectAction}
                        getRejectMessage={this.getRejectMessage}
                        upcomingStatusAction={this.upcomingStatusAction}
                        petDetails={this.state.orderDetails}
                        upcomingIndex={this.state.upcomingIndex}
                        saveOrderId={this.state.saveOrderId}
                        selectedRoomType={this.state.selectedRoomType}
                        openRoomTypes={this.state.openRoomTypes}
                        openRoomType={this.openRoomType}
                        closeRoomTypes={this.closeRoomTypes}
                        roomListBo={this.state.roomListBo}
                        params={this.state.params}
                        selectedRoomCount={this.state.selectedRoomCount}
                        selectRoomType={this.selectRoomType}
                        handleRoomSelect={this.handleRoomSelect}
                        openCalenderStatus={this.state.openCalenderStatus}
                        inputFieldDate={this.state.inputFieldDate}
                        closeSelectDate={this.closeSelectDate}
                        clearDates={this.clearDates}
                        openSelectDate={this.openSelectDate}
                        handleDateChange={this.handleDateChange}
                        selectedRange={this.state.selectedRange}
                        numberOfPets={this.state.numberOfPets}
                        addPets={this.addPets}
                        minusPets={this.minusPets}
                        handlePriceInput={this.handlePriceInput}
                        price={this.state.price}
                        handleClear={this.handleClear}
                        closeDate={this.closeDate}
                        navigation={this.props.navigation} id={""}
                        paymentDetails={this.state.paymentDetails} popUpBtnText={this.state.popUpBtnText}
                        openCommonDailog={this.openCommonDailog} />
                    }
                    <CustomizedSnackbars
                        message={this.state.syncCompleted}
                        toasterType="success"
                        duration={4000}
                        openToaster={this.state.openToasterStatus} />
                    <CustomizedSnackbars
                        message={this.state.syncCompletedError}
                        toasterType="error"
                        duration={4000}
                        openToaster={this.state.openToasterErrorStatus} />
                </div>
            </>
        );
        // Customizable Area En
    }
}
// Customizable Area Start
export const ReservationBoStyles = (theme: Theme) =>
    createStyles({
        reservationContent: {
            marginLeft: "140px", // Adjust based on the width of your sidebar
            flexGrow: 1,
            backgroundColor: theme.palette.primary.main,
            overflowY: "auto", // Allow main reservationContent area to scroll
        },
        filerButtons: {
            borderRadius: 30,
            background: theme.palette.common.white,
            border: `1.5px solid ${theme.palette.info.main}`,
            textTransform: "none",
            fontSize: 16,
            color: colors.darkBlack,
            '&:hover': {
                background: theme.palette.common.white,

            }
        },
        exportButton: {
            borderRadius: 10,
            background: theme.palette.common.white,
            border: `1.5px solid ${theme.palette.info.main}`,
            textTransform: "none",
            fontSize: 16,
            height: "44px",
            color: colors.darkBlack,
            '&:hover': {
                background: theme.palette.common.white,

            }
        },
        btnContainer: {
            display: "flex",
            justifyContent: "space-between"
        },
        selectedFilterButtons: {
            borderRadius: 30,
            background: theme.palette.primary.light,
            textTransform: "none",
            fontSize: 16,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                background: theme.palette.primary.light,

            }
        },
        dropDownBox: {
            border: `1px solid ${theme.palette.info.main}`,
            height: 44,
            minWidth: 175,
            borderRadius: 8,
            backgroundColor: theme.palette.common.white,
            cursor: 'pointer',
            position: 'relative'
        },
        dropDownBoxContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            padding: '0 15px'
        },
        cursor: {
            cursor: 'pointer'
        },
        container: {
            minHeight: 440,
            boxShadow: boxShadows.whiteShadow
        },
        statusDropDownBox: {
            height: 44,
            minWidth: 134,
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            position: 'relative'
        },
        checkedOutStatusBox: {
            height: 44,
            minWidth: 134,
            borderRadius: 8,
            backgroundColor: '#FEF3C7',
            cursor: 'pointer',
            position: 'relative'
        },


        statusDropDownBoxContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            padding: "0 10px"
        },
        notFoundText: {
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.common.black
        },
        pendingText: {
            color: theme.palette.text.secondary,
            lineHeight: "22px",
            fontWeight: theme.typography.fontWeightBold,
        },
        checkedOutTextStatus: {
            color: "#D97706",
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: "22px"

        },
        propertiesOuter: {
            position: 'absolute',
            zIndex: 99,
            width: '100%',
            boxShadow: boxShadows.whiteShadow,
            backgroundColor: theme.palette.common.white,
            borderRadius: 8,
            height: 250,
            overflowY: 'auto'
        },
        statusOuter: {
            position: 'absolute',
            zIndex: 99,
            width: '100%',
            height: 'auto',
            boxShadow: boxShadows.whiteShadow,
            backgroundColor: theme.palette.common.white,
            borderRadius: 8,

        }, statusInner: {
            padding: '0.5rem'
        },
        approveText: {
            color: theme.palette.success.main,
            background: "#D1FAE5",
            padding: "10px 20px 10px 16px",
            fontWeight: theme.typography.fontWeightBold,
            borderRadius: 8,
            fontSize: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
                background: "#D1FAE5",

            }


        },
        rejectText: {
            marginTop: 10,
            color: theme.palette.error.main,
            background: "#FEE2E2",
            padding: "10px 20px 10px 16px",
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
                background: "#FEE2E2",

            }
        },
        checkOutText: {
            color: '#D97706',
            background: "#FEF3C7",
            padding: "10px 20px 10px 16px",
            fontWeight: theme.typography.fontWeightBold,
            fontSize: 14,
            borderRadius: 8,
            display: 'flex',
            lineHeight: '22px',
            justifyContent: 'center',
            alignItems: 'center',
            "&:hover": {
                background: "#FEF3C7",

            }
        },
        approveStatusBox: {
            height: 44,
            minWidth: 134,
            borderRadius: 8,
            background: "#D1FAE5",
            cursor: 'pointer',
            position: 'relative'
        },
        rejectStatusBox: {
            height: 44,
            minWidth: 134,
            borderRadius: 8,
            background: "#FEE2E2",
            cursor: 'pointer',
            position: 'relative'
        },
        activeTextStatus: {
            color: theme.palette.success.main,
            fontSize: 14,
            fontWeight: 700,


        }, rejectTextStatus: {
            color: theme.palette.error.main,
            fontSize: 14,
            fontWeight: 700,

        },
        menuDropDownText: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: "22px",
            color: theme.palette.common.black,
            backgroundColor: colors.primaryBlue,
            borderRadius: 8,
            margin: '5px 0'
        },
        notSelectedDropDownText: {

            fontSize: 14,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: "22px",
            color: theme.palette.common.black,
            margin: '5px 0',
            "&:hover": {
                backgroundColor: colors.primaryBlue,
                borderRadius: 8,
                height: 36
            }
        },
        disbaledPriceText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold

        },
        petText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '22px'

        },
        petLayout: {
            border: `1px solid${theme.palette.primary.contrastText}`,
            borderRadius: 4,
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            marginBottom: '6px',
            width: 'max-content'
        },
        tableul: {
            justifyContent: 'center',
            margin: 'revert'
        },
        paginationLayout: {
            borderTop: '1px solid #CBD5E1',
            backgroundColor: theme.palette.common.white,
        },
        messageTableHead: {
            width: '95px'
        },
        statusTableHead: {
            width: '150px'
        },
        reasonTableHead: {
            width: '241px'
        },
        priceTableHead: {
            width: "90px"
        },
        petOwnerTableHead: {
            width: "210px"
        },
        dateTableHead: {
            width: '140px'
        },
        propertyNameTableHead: {
            width: '200px'
        }, petTypeTableHead: {
            width: "170px"
        },
        roomTypeTableHead: {
            width: "200px"
        },
        searchLayout: {
            width: '71%',
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.up(1440)]: {
                width: "80%"
            },
            [theme.breakpoints.up(1380)]: {
                width: "73%"
            },
            [theme.breakpoints.down('sm')]: {
                width: "45%"
            }
        },
        filtersLayout: {
            width: "29%",
            [theme.breakpoints.up(1440)]: {
                width: "20%"
            },
            [theme.breakpoints.up(1380)]: {
                width: "27%"
            },
            [theme.breakpoints.down('sm')]: {
                width: "55%"
            }
        },
        statusDivider: {
            marginTop: 10,
            height: '1.2px',
            backgroundColor: "#CBD5E1"
        }

    })


export default withStyles(ReservationBoStyles)(ReservationsBusinessOwner)

// Customizable Area End
